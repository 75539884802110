import React from 'react';

export const LinkedInField = (props) => {

    const icon = (props.icon)?props.icon:<span className="pi pi-linkedin"></span>;

    function getLink(){
        if(props.value?.indexOf("linkedin.com") > -1 ){
            return props.value;
        }
        else{
            return "https://linkedin.com" + props.value;
        }
    }

    return( 
        <span className={props.className}>
            {icon} <a rel="noreferrer" href={getLink()} target="_blank">LinkedIn Profile</a> <span className='pi pi-pi-external-link'></span>
        </span>
    );
}