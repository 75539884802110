import { Tag } from 'primereact/tag';
import React from 'react';
import { Link } from 'react-router-dom';
import { PlayTag } from './PlayTag';

export const PlaysSelector = (props) => {

    const shouldRender = props.playbook?.plays?.length > 1;

    function getCountMatchingEntities(play){
        return props.dataSourceList?.data?.filter(e => (!e.PRESCALE__C_assigned_play && play.isDefault) || (e.PRESCALE__C_assigned_play && e.PRESCALE__C_assigned_play[props.playbook.id] === play.id)).length;
    }

    function handleOnPlayClicked(play){
        if(props.onPlaySelected){
            props.onPlaySelected(play);
        }
    }

    if(shouldRender){
        return <div className='play-selector-container'>
                Assigned plays:
                {
                    props.playbook.plays.map((play, index) => {
                        const isFiltered = props.filteredPlays?.findIndex(p=> p === play.id) > -1;
                        return <PlayTag checked={!isFiltered} onClick={handleOnPlayClicked} key={`play-tag-${play.id}`} play={play} playIndex={index} allowSelect={true} count={getCountMatchingEntities(play)} />;
                    })
                }
                <Link to={`/playbook/${props.playbook.id}`} >
                    <Tag value="New play" icon="pi pi-fw pi-plus" style={{"backgroundColor": "transparent", "borderWidth": "1px", "borderStyle": "solid", "color": "darkgray", "borderColor": "darkgray"}} />
                </Link>
            </div>;
    }
    return <></>;
}