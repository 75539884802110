import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { classNames } from 'primereact/utils';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { OpportunityUtils } from '../../../Utils/OpportunityUtils';
import { Tooltip } from 'primereact/tooltip';

export const OutcomesPopupEditor = (props) => {

    const [currentOutcome, setCurrentOutcome] = useState(null);
    const [outcomeDescription, setOutcomeDescription] = useState("");
    const [outcomeAnswer, setOutcomeAnswer] = useState("");
    const [dataItem, setDataItem] = useState(null);
    const [opportunityPlaybookOutcomes, setOpportunityPlaybookOutcomes] = useState(null);

    useEffect(()=>{
        if(currentOutcome == null){
            updateCurrentOutcome(getDefaultOutcome())
        }

    }, [opportunityPlaybookOutcomes]);

    useEffect(()=>{
        setDataItem({...props.dataItem});
    }, [props.dataItem]);

    useEffect(()=>{
        if(dataItem != null){
            setOpportunityPlaybookOutcomes(OpportunityUtils.getOutcomesToComplete(props.playbook, dataItem));
        }
    }, [dataItem])

    //////////////////////////////////////////////
    //// PRIVATE METHODS
    //////////////////////////////////////////////

    function updateCurrentOutcome(outcome){
        setCurrentOutcome(outcome);
        setOutcomeAnswer(outcome != null && outcome.answer != null ? outcome.answer.value : "");
        setOutcomeDescription(outcome != null && outcome.answer != null ? outcome.answer.description : "");
    }

    function getDefaultOutcome(){
        let outcome = null;
        if(opportunityPlaybookOutcomes && opportunityPlaybookOutcomes.length > 0){
            outcome = opportunityPlaybookOutcomes.find(outcome => outcome.isCurrent);
            if(!outcome){
                outcome = opportunityPlaybookOutcomes[0];
            }
        }
        return outcome;
    }

    function getOutcomeIcon(outcome){
        const isCurrent = (currentOutcome != null && outcome.outcome.id === currentOutcome.outcome.id);

        if(!isCurrent && outcome.answer == null){
            return 'pi ' + (outcome.isRequired ? 'pi-exclamation-circle' : 'pi-circle');
        }
        else{
            const value = isCurrent ? outcomeAnswer : outcome.answer?.value;
            switch(value){
                case "yes":
                    return 'pi pi-check-circle';
                case "no":
                    return 'pi pi-times-circle';
                case "partial":
                    return 'pi pi-minus-circle';
                default:
                    return outcome.isRequired && !isCurrent ? 'pi pi-exclamation-circle' : 'pi pi-circle';
            }
        }
    }

    function getPrevOutcome(){
        if(currentOutcome != null){
            const currentIndex = opportunityPlaybookOutcomes.findIndex(outcome => outcome.outcome.id === currentOutcome.outcome.id);
            if(currentIndex > 0){
                return opportunityPlaybookOutcomes[currentIndex-1];
            }
        }
        return null;
    }

    function getNextOutcome(){
        if(currentOutcome != null){
            const currentIndex = opportunityPlaybookOutcomes.findIndex(outcome => outcome.outcome.id === currentOutcome.outcome.id);
            if(currentIndex < (opportunityPlaybookOutcomes.length -1)){
                return opportunityPlaybookOutcomes[currentIndex+1];
            }
        }
        return null;
    }

    function isNextRequired(){
        const next = getNextOutcome();
        return next != null && next.isRequired;
    }

    function saveCurrentOutcomeAnswer(riseOnSave=false){
        
        const answer = {
            value: outcomeAnswer,
            description: outcomeDescription
        }

        const upadtedOpp = OpportunityUtils.updateOpportunityOutcome(dataItem, currentOutcome.outcome, answer);
        setDataItem(upadtedOpp);
        if(riseOnSave && props.onSave){
            props.onSave(upadtedOpp);
        }
    }

    //////////////////////////////////////////////
    //// HANDLER METHODS
    //////////////////////////////////////////////

    function handleOnDoneClick(){
        saveCurrentOutcomeAnswer(true);
        updateCurrentOutcome(null);
    }

    function onHide(){
        if(props.onHide){
            saveCurrentOutcomeAnswer();
            updateCurrentOutcome(null);
            props.onHide();
        }
    }

    function handleMoveNext(){
        const next = getNextOutcome();
        handleMoveToSpecificOutcome(next);
    }

    function handleMovePrev(){
        const prev = getPrevOutcome();
        handleMoveToSpecificOutcome(prev);
    }

    function handleMoveToSpecificOutcome(outcome){
        saveCurrentOutcomeAnswer();
        if(outcome != null){
            updateCurrentOutcome(outcome);
        }
    }

    function handleOnAnswerChanged(answer){
        setOutcomeAnswer(answer);
    }

    //////////////////////////////////////////////
    //// RENDER
    //////////////////////////////////////////////
    function renderFooter(){
        return  <div>
                    <Button disabled={getPrevOutcome() == null} className={classNames("p-button-outlined")} value="Previous" icon="pi pi-angle-left" onClick={handleMovePrev} />
                    <Button label="Done" className={classNames(isNextRequired()?"p-button-outlined":"")} icon="pi pi-check" onClick={handleOnDoneClick}/>
                    <Button disabled={getNextOutcome() == null} className={classNames(isNextRequired()?"":"p-button-outlined")} value="Next" icon="pi pi-angle-right" onClick={handleMoveNext} />
                </div>;
    }

    function renderMarkers(){
        const markers = [];

        if(opportunityPlaybookOutcomes){
            opportunityPlaybookOutcomes.forEach(outcome => {
                
                markers.push(
                    {
                        outcome: outcome,
                        className: classNames(
                                                outcome.outcome.id,
                                                "outcome-marker", 
                                                getOutcomeIcon(outcome), 
                                                (currentOutcome != null && outcome.outcome.id === currentOutcome.outcome.id)?"active ": "",
                                                outcome.isRequired && !outcome.isCompleted ? "required" : "",
                                                outcome.isCompleted ? "completed" : ""
                                            )
                    }
                )
            });
        }

        return <Timeline className="outcome-markers" toolip="Example" layout="horizontal" value={markers} marker={(item) => <i data-pr-tooltip={item.outcome.outcome.label} onClick={()=>{handleMoveToSpecificOutcome(item.outcome)}} className={item.className}></i>} />
    }

    function renderOutcomeForm(){
        if(currentOutcome){
            const answers = [
                {value: '', percentage: 0, label: <span><span className="pi pi-circle"></span> Not set</span>},
                {value: 'yes', percentage: 1, label: <span><span className="pi pi-check-circle"></span> Yes</span>},
                {value: 'partial', percentage: 0.5, label: <span><span className="pi pi-minus-circle"></span> Partial</span>},
                {value: 'no', percentage: 0, label: <span><span className="pi pi-times-circle"></span> No</span>},
            ];
            return <div className="outcome-form">
                        <h4>
                            {currentOutcome.outcome.label}
                        </h4>
                        <p>
                            <i>{currentOutcome.outcome.description}</i>
                        </p>
                        <div>Answer: <span className="pi pi-question-circle"></span></div>
                        <SelectButton value={outcomeAnswer} options={answers} onChange={(e) => handleOnAnswerChanged(e.value)}></SelectButton>
                        <br/>
                        <div>Comments: <span className="pi pi-question-circle"></span></div>
                        <InputTextarea rows={5} style={{width: "100%"}} value={outcomeDescription} onChange={(e) => setOutcomeDescription(e.target.value)} />
                    </div>
        }
        return null;
    }
    
    return (
        <Dialog className="outcomes-editor" onHide={onHide} header="Validate the outcomes" visible={true} footer={renderFooter()} style={{ width: '40vw' }}>
            {renderMarkers()}
            {renderOutcomeForm()}
            <Tooltip target=".outcome-marker" />
        </Dialog>
    )

}