import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { Menu } from 'primereact/menu';
import { ProgressBar } from 'primereact/progressbar';
const overviewChartData1 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
            borderColor: [
                '#4DD0E1',
            ],
            backgroundColor: [
                'rgba(77, 208, 225, 0.8)',
            ],
            borderWidth: 2,
            fill: true,
            tension: .4
        }
    ]
};
const overviewChartData2 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [11, 30, 52, 35, 39, 20, 14, 18, 29],
            borderColor: [
                '#4DD0E1',
            ],
            backgroundColor: [
                'rgba(77, 208, 225, 0.8)',
            ],
            borderWidth: 2,
            fill: true,
            tension: .4
        }
    ]
};
const overviewChartData3 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [20, 29, 39, 36, 45, 24, 28, 20, 15],
            borderColor: [
                '#4DD0E1',
            ],
            backgroundColor: [
                'rgba(77, 208, 225, 0.8)',
            ],
            borderWidth: 2,
            fill: true,
            tension: .4
        }
    ]
};
const overviewChartData4 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [30, 39, 50, 21, 33, 18, 10, 24, 20],
            borderColor: [
                '#4DD0E1',
            ],
            backgroundColor: [
                'rgba(77, 208, 225, 0.8)',
            ],
            borderWidth: 2,
            fill: true,
            tension: .4
        }
    ]
};
const overviewChartOptions = {
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            display: false
        },
        x: {
            display: false
        }
    },
    tooltips: {
        enabled: false
    },
    elements: {
        point: {
            radius: 0
        }
    }
};

const getColors = (colorMode) => {
    const isLight = colorMode === 'light';
    return {
        pinkColor: isLight ? '#EC407A' : '#F48FB1',
        purpleColor: isLight ? '#AB47BC' : '#CE93D8',
        deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
        indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
        blueColor: isLight ? '#42A5F5' : '#90CAF9',
        lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
        cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
        tealColor: isLight ? '#26A69A' : '#80CBC4',
        greenColor: isLight ? '#66BB6A' : '#A5D6A7',
        lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
        limeColor: isLight ? '#D4E157' : '#E6EE9C',
        yellowColor: isLight ? 'FFEE58' : '#FFF59D',
        amberColor: isLight ? '#FFCA28' : '#FFE082',
        orangeColor: isLight ? '#FFA726' : '#FFCC80',
        deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
        brownColor: isLight ? '#8D6E63' : '#BCAAA4'
    }
}

const getStagesDoughnutData = () => {
    const { blueColor, lightblueColor, cyanColor, tealColor, greenColor,
        lightgreenColor, orangeColor } = getColors('light');
    const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

    return {
        labels: ['Discovery', 'Solution Positioning', 'Evaluation Planning', 'Evaluation Execution', 'Post Evaluation', 'Waiting for Close'],
        datasets: [
            {
                data: [11, 29, 71, 33, 28, 95, 6],
                backgroundColor: [blueColor, lightblueColor, cyanColor, tealColor, greenColor, lightgreenColor, orangeColor],
                borderColor
            }
        ]
    };
}

const getHealthDoughnutData = () => {
    // const { blueColor, lightblueColor, cyanColor, tealColor, greenColor,
    //     lightgreenColor, orangeColor } = getColors('light');
    const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

    return {
        labels: ['N/A', 'Low', 'Medium', 'High'],
        datasets: [
            {
                data: [11, 29, 71, 33],
                backgroundColor: ["#e0e0e0", "#D32F2F", "#FBC02D", "#689F38"],
                borderColor
            }
        ]
    };
}

const getDoughnutOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {

        plugins: {
            title: {
                display: false,
            },
            legend: {
                position: 'bottom',
                labels: {
                    fontFamily,
                    fontColor: textColor
                }
            }
        },
        cutout: '50%'

        // legend: {
        //     position: 'bottom',
        //     labels: {
        //         fontFamily,
        //         fontColor: textColor
        //     }
        // },
        // cutout: '50%'
    };
}

const getChartData = (colorMode) => {
    // const { limeColor, amberColor, orangeColor, blueColor, lightblueColor,
    //     cyanColor, tealColor, greenColor, lightgreenColor } = getColors(colorMode);

    const { blueColor, lightblueColor, cyanColor, tealColor } = getColors(colorMode);

    return {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Prescale Play',
                data: [6, 25, 97, 12, 7, 70, 42],
                borderColor: blueColor,
                backgroundColor: blueColor,
                borderWidth: 2,
                fill: true
            },
            {
                label: 'Mulesoft Play - V1',
                data: [81, 3, 5, 11, 59, 47, 99],
                borderColor: lightblueColor,
                backgroundColor: lightblueColor,
                borderWidth: 2,
                fill: true
            },
            {
                label: 'Mulesoft Play - V2',
                data: [68, 47, 46, 46, 61, 70, 94],
                borderColor: cyanColor,
                backgroundColor: cyanColor,
                borderWidth: 2,
                fill: true
            },
            {
                label: 'Banking Play',
                data: [31, 9, 18, 76, 6, 11, 79],
                borderColor: tealColor,
                backgroundColor: tealColor,
                borderWidth: 2,
                fill: true
            }
        ]
    };
}

const getChartOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                display: true,
                labels: {
                    fontFamily,
                    color: textColor
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            x: {
                categoryPercentage: .9,
                barPercentage: .8,
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    }
}

export const DashboardPage = () => {
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const menu3 = useRef(null);
    const menu4 = useRef(null);
    const menu11 = useRef(null);
    const menu12 = useRef(null);
    const chart1 = useRef(null);
    const [stagesDoughnutData] = useState(getStagesDoughnutData());
    const [healthDoughnutData] = useState(getHealthDoughnutData());
    const [doughnutOptions] = useState(getDoughnutOptions());
    const chartMonthlyData = getChartData();
    const chartMonthlyOptions = getChartOptions();

    return  <div className="p-grid dashboard">
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-shopping-cart"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Orders</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu1.current.toggle(event)}></Button>
                                <Menu ref={menu1} popup model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column" style={{ width: '80px' }}>
                                <span className="p-mb-1 fs-xlarge">640</span>
                                <span className="overview-status p-p-1 teal-bgcolor fs-small">1420 Completed</span>
                            </div>
                            <div className="p-d-flex p-ai-end">
                                <Chart ref={chart1} type="line" data={overviewChartData1} options={overviewChartOptions} height="60px" width="160px"></Chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-dollar"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')}>Revenue</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu2.current.toggle(event)}></Button>
                                <Menu ref={menu2} popup model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column" style={{ width: '80px' }}>
                                <span className="p-mb-1 fs-xlarge">$57K</span>
                                <span className="overview-status p-p-1 teal-bgcolor fs-small">$9,640 Income</span>
                            </div>
                            <div className="p-d-flex p-ai-end">
                                <Chart type="line" data={overviewChartData2} options={overviewChartOptions} height="60px" width="160px"></Chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-users"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')}>Customers</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu3.current.toggle(event)}></Button>
                                <Menu ref={menu3} popup model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column" style={{ width: '80px' }}>
                                <span className="p-mb-1 fs-xlarge">8572</span>
                                <span className="overview-status p-p-1 pink-bgcolor fs-small">25402 Registered</span>
                            </div>
                            <div className="p-d-flex p-ai-end">
                                <Chart type="line" data={overviewChartData3} options={overviewChartOptions} height="60px" width="160px"></Chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-comments"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')}>Comments</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu4.current.toggle(event)}></Button>
                                <Menu ref={menu4} popup model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column" style={{ width: '80px' }}>
                                <span className="p-mb-1 fs-xlarge">805</span>
                                <span className="overview-status p-p-1 teal-bgcolor fs-small">85 Responded</span>
                            </div>
                            <div className="p-d-flex p-ai-end">
                                <Chart type="line" data={overviewChartData4} options={overviewChartOptions} height="60px" width="160px"></Chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card widget-social">
                        <div className="p-d-flex p-jc-between p-ai-center p-p-3">
                            <div className="social-icon">
                                <i className="pi pi-clock blue-color fs-xxlarge"></i>
                            </div>
                            <div className="info p-d-flex p-flex-column">
                                <span className="value">44</span>
                                <span className="subtext p-mt-2">Avg Hours</span>
                            </div>
                        </div>

                        <div className="stats p-d-flex p-jc-between p-mt-3">
                            <div className="left p-d-flex p-flex-column ">
                                <span className="title">Won</span>
                                <span className="value p-mb-2">100hs</span>
                                <ProgressBar value="50" showValue={false}></ProgressBar>
                            </div>
                            <div className="right p-d-flex p-flex-column">
                                <span className="title">Lost</span>
                                <span className="value p-mb-2">50hs</span>
                                <ProgressBar value="24" showValue={false}></ProgressBar>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card widget-social">
                        <div className="p-d-flex p-jc-between p-ai-center p-p-3">
                            <div className="social-icon">
                                <i className="pi pi-dollar indigo-color fs-xxlarge"></i>
                            </div>
                            <div className="info p-d-flex p-flex-column">
                                <span className="value">75%</span>
                                <span className="subtext p-mt-2">Win rate</span>
                            </div>
                        </div>

                        <div className="stats p-d-flex p-jc-between p-mt-3">
                            <div className="left p-d-flex p-flex-column ">
                                <span className="title">Won</span>
                                <span className="value p-mb-2">100</span>
                                <ProgressBar value="23" showValue={false}></ProgressBar>
                            </div>
                            <div className="right p-d-flex p-flex-column">
                                <span className="title">Lost</span>
                                <span className="value p-mb-2">50</span>
                                <ProgressBar value="38" showValue={false}></ProgressBar>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card widget-social">
                        <div className="p-d-flex p-jc-between p-ai-center p-p-3">
                            <div className="social-icon">
                                <i className="pi pi-github text-color fs-xxlarge"></i>
                            </div>
                            <div className="info p-d-flex p-flex-column">
                                <span className="value">81.002</span>
                                <span className="subtext p-mt-2">Star</span>
                            </div>
                        </div>

                        <div className="stats p-d-flex p-jc-between p-mt-3">
                            <div className="left p-d-flex p-flex-column ">
                                <span className="title">Target</span>
                                <span className="value p-mb-2">10.000</span>
                                <ProgressBar value="62" showValue={false}></ProgressBar>
                            </div>
                            <div className="right p-d-flex p-flex-column">
                                <span className="title">All Time Record</span>
                                <span className="value p-mb-2">162.550</span>
                                <ProgressBar value="14" showValue={false}></ProgressBar>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card height-100 widget-alerts">
                        <div className="card-header p-mb-2">
                            <h5>
                                <i className="pi pi-bell"></i> Alerts
                            </h5>
                            <div>
                                <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu11.current.toggle(event)}></Button>
                                <Menu ref={menu11} popup={true} model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <ul className="layout-topbar-action-panel">
                            <li className="layout-topbar-action-item">
                                <div className="p-d-flex p-flex-row p-ai-center">
                                    <div className={classNames('p-d-flex p-flex-column')} style={{ flexGrow: '1' }}>
                                        <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                            <span className="fs-small p-text-bold">Opportunity Updated</span>
                                            <small>6 hs ago</small>
                                        </div>
                                        <span className="fs-small">
                                            <i>Greenfelder-Kohler</i> has been moved to <i>Stage 4</i>
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="layout-topbar-action-item">
                                <div className="p-d-flex p-flex-row p-ai-center">
                                    <div className={classNames('p-d-flex p-flex-column')} style={{ flexGrow: '1' }}>
                                        <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                            <span className="fs-small p-text-bold">New Opportunity Assigned</span>
                                            <small>1 day ago</small>
                                        </div>
                                        <span className="fs-small">
                                            <i>Trantow-Koepp</i> has been assigned to you
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="layout-topbar-action-item">
                                <div className="p-d-flex p-flex-row p-ai-center">
                                    <div className={classNames('p-d-flex p-flex-column')} style={{ flexGrow: '1' }}>
                                        <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                            <span className="fs-small p-text-bold">Schedule reminder</span>
                                            <small>1 day ago</small>
                                        </div>
                                        <span className="fs-small">You have <b>1</b> opportunity with no actions scheduled</span>
                                    </div>
                                </div>
                            </li>
                            <li className="layout-topbar-action-item">
                                <div className="p-d-flex p-flex-row p-ai-center">
                                    <div className={classNames('p-d-flex p-flex-column')} style={{ flexGrow: '1' }}>
                                        <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                            <span className="fs-small p-text-bold">Opportunity Won!</span>
                                            <small>4 days ago</small>
                                        </div>
                                        <span className="fs-small">
                                            <i>Rutherford and Sons</i> is won! Add it to your portfolio.
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card widget-insights height-100">
                        <div className="card-header p-mb-2">
                            <h5>Opportunities in the play</h5>
                            <div>
                                <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu11.current.toggle(event)}></Button>
                                <Menu ref={menu11} popup={true} model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-center">
                            <Chart type="pie" data={stagesDoughnutData} options={doughnutOptions}></Chart>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card height-100 widget-topsearchs">
                        <div className="card-header">
                            <h5>Recent Opportunities</h5>
                            <div>
                                <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu12.current.toggle(event)}></Button>
                                <Menu ref={menu12} popup={true} model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between item">
                            <span>Olson-Abernathy</span>
                            <span className="value type-green">82% CONV RATE</span>
                        </div>
                        <div className="p-d-flex p-jc-between item">
                            <span>Schaden LLC</span>
                            <span className="value type-green">78% CONV RATE</span>
                        </div>
                        <div className="p-d-flex p-jc-between item">
                            <span>Raynor Group</span>
                            <span className="value type-green">61% CONV RATE</span>
                        </div>
                        <div className="p-d-flex p-jc-between item">
                            <span>Feeney LLC</span>
                            <span className="value type-yellow">48 CONV RATE</span>
                        </div>
                        <div className="p-d-flex p-jc-between item">
                            <span>Davis-Schaden</span>
                            <span className="value type-yellow">34% CONV RATE</span>
                        </div>
                        <div className="p-d-flex p-jc-between item">
                            <span>Rutherfors and Sons</span>
                            <span className="value type-pink">11% CONV RATE</span>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-4">
                    <div className="card widget-insights height-100">
                        <div className="card-header p-mb-2">
                            <h5><i className="pi pi-heart"></i> Pipeline Health</h5>
                            <div>
                                <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu11.current.toggle(event)}></Button>
                                <Menu ref={menu11} popup={true} model={[{ label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' }]}></Menu>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-center">
                            <Chart type="pie" data={healthDoughnutData} options={doughnutOptions}></Chart>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-8">
                    <div className="card height-100">
                        <div className="card-header">
                            <h5>Plays Win Rate Comparison</h5>
                        </div>

                        <Chart type="bar" data={chartMonthlyData} options={chartMonthlyOptions} height="400px"></Chart>
                    </div>
                </div>
            </div>
}