import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { ActionUtils } from '../../../Utils/ActionUtils';
import { PlaybookActionSettingsForm } from './PlaybookActionSettingsForm';


export const PlaybookAction = (props) => {
    
    const [actionName, setActionName] = useState(props.isNew?"":props.action.label);
    const [editing, setEditing] = useState(props.isNew);
    const optionsMenu = useRef(null);
    const [showSettingsPopup, setShowSettingsPopup] = useState(false);

    function cancelEdit(){
        if(!props.isNew){
            setEditing(false);
        }
        setActionName(props.isNew?"":props.action.label);
    }

    function getLabelRender(){
        let label = props.action?.label;
        if(props.playAction && props.playAction.label){
            label = props.playAction.label;
        }
        return `${label} ${ props.showCount ? " (" + props.actionCount + ")" : "" }`;
    }

    function getActionOptions(){
        return  [
                    { label: 'Configure', icon: 'pi pi-fw pi-cog', command: ()=>{ setShowSettingsPopup(true) } },
                    { label: 'Remove', icon: 'pi pi-fw pi-trash', command: handleCloseClicked }, 
                ]
    }

    function isActionInSequence(){
        let inSequence = false;
        if(props.sequence && props.playAction){
            const found = props.sequence?.playbookActions.findIndex(a => a.actionId === props.playAction.id);
            inSequence = found >= 0;
        }
        return inSequence;
    }

    function getDefaultDurationHsToRender(){
        let defaultDurationHs = props.action?.defaultDurationHs;
        if(props.playAction && props.playAction.defaultDurationHs){
            defaultDurationHs = props.playAction.defaultDurationHs;
        }
        return defaultDurationHs;
    }

    function getDefaultTypeToRender(){
        let defaultType = props.action?.defaultType;
        if(props.playAction && props.playAction.defaultType){
            defaultType = props.playAction.defaultType;
        }
        return defaultType;
    }

    function getTemplateIdToRender(){
        let templateId = props.action?.templateId;
        if(props.playAction && props.playAction.templateId){
            templateId = props.playAction.templateId;
        }
        return templateId;
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnBlur(){

        cancelEdit();
    }
    
    function handleCloseClicked(){
        if(props.onRemoveAction){
            props.onRemoveAction(props.action.id);
        }
    }

    function handleOnLabelClicked(){
        if(props.allowRename){
            setEditing(true);
        }
    }

    function handleOnKeyDown(e){
        if(e.key === 'Escape'){
            cancelEdit();
        }
        if(e.key === 'Enter'){
            if(props.isNew){
                if(props.onAddAction){
                    props.onAddAction(actionName);
                    setActionName("");
                }
            }
            else{
                if(props.onEditAction){
                    props.onEditAction(actionName);
                }
                setEditing(false)
            }
        }
    }
    
    function handleOnClick(){
        if(props.onClick){
            props.onClick(props.playAction);
        }
    }

    function handleOnShowSetting(){
        setShowSettingsPopup(true);
    }

    function handleOnHideSettings(){
        setShowSettingsPopup(false);
    }

    function handleOnNewTemplateAdded(template){
        if(props.onNewTemplate){
            props.onNewTemplate(template);
        }
    }

    function onActionChanged(action, playAction){
        if(props.onChange){
            props.onChange(action, playAction);
        }
        setShowSettingsPopup(false);
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderMenu(){
        if(!props.showMenu || editing)
            return null;
        return <div className="config" onClick={(event) => optionsMenu.current.toggle(event)}>
                    <span className="p-col-1 pi-ellipsis-v pi"></span>
                    <Menu ref={optionsMenu} popup={true} model={getActionOptions()}></Menu>
                </div>
    }

    function renderActionProps(){
        const items = [];
        if(!props.isNew && props.allowEdit){

            let hasDefaultTiming = false;
            let actionTimingLabel = "Set default..."
            const defaultDurationHs = getDefaultDurationHsToRender();
            if(defaultDurationHs){
                hasDefaultTiming = true;
                actionTimingLabel = `${ActionUtils.convertHsToDurationString(defaultDurationHs)}`;
            }
            
            if(hasDefaultTiming){
                items.push(<li key="action-duration"><span className="pi pi-clock"></span> {actionTimingLabel}</li>);
            }
            else{
                items.push(<li key="action-duration"><a onClick={handleOnShowSetting}><span className="pi pi-clock"></span> {actionTimingLabel}</a></li>);
            }

            const template = props.playbook?.templates.find(t => t.id === getTemplateIdToRender());
            if(template != null){
                items.push(<li key="action-template"><a className='link' href={template.value} target="_blank" rel="noreferrer"><span className="pi pi-file"></span> {template.label} <span className="pi pi-external-link"></span></a></li>)
            }
            else{
                items.push(<li key="action-template"><a onClick={handleOnShowSetting}><span className="pi pi-file"></span> Add template...</a></li>)
            }
            
        }
        if(items.length > 0){
            return  <div className="content">
                        <ul>
                            {items}
                        </ul>
                    </div>
        }
        else{
            return null;
        }
    }

    function renderSettingsPopup(){
        if(showSettingsPopup){
            return <PlaybookActionSettingsForm action={props.action} playAction={props.playAction} play={props.play} playbook={props.playbook} visible={showSettingsPopup} onCancel={handleOnHideSettings} onActionChange={onActionChanged} onNewTemplate={handleOnNewTemplateAdded} />
        }
        return null;
    }

    return <div className={classNames("card", (editing || !props.showMenu)?"":"draggable", "action", {"editing": editing}, {"not-in-sequence": (!isActionInSequence())}, {"in-sequence": (isActionInSequence())}, props.className)} onClick={handleOnClick}>
                <div className="title">
                    <label onDoubleClick={handleOnLabelClicked}>
                    {
                        editing ? 
                        <InputText  autoFocus={!props.isNew} value={actionName} onChange={(e)=>setActionName(e.target.value)} onKeyDown={handleOnKeyDown} placeholder={props.placeholder} /> : 
                        (getLabelRender())
                    }
                    </label>
                    {renderMenu()}
                </div>
                {renderActionProps()}
                {renderSettingsPopup()}
            </div>;

}