import React, { useState, useRef } from 'react';
import { ACVField } from '../../../Components/Fields/ACVField';
import { Menu } from 'primereact/menu';
import { HealthField } from '../../../Components/Fields/HealthField';
import { OpportunityUtils } from '../../../Utils/OpportunityUtils';

export const MotionHeader = (props) => {
    const [summaryTypes] = useState([
        {
            key: "acv",
            label: "ACV",
            icon: "pi pi-dollar",
            command:(value)=>{
                setSummaryType(value.item);
                // setSummaryType(SUMMARY_TYPES[1]);
            },
            render: (dataList) => {
                return <ACVField value={OpportunityUtils.calculateACV(dataList)}/>
            }
        },
        {
            key: "count",
            label: "Count",
            icon: "pi pi-plus-circle",
            command:(value)=>{
                setSummaryType(value.item);
            },
            render: (dataList) => {
                return <span>Total: {dataList.length}</span>
            }
        },
        {
            key: "health_percentage",
            label: "Health",
            icon: "pi pi-heart",
            command:(value)=>{
                setSummaryType(value.item);
            },
            render: (dataList) => {
                return <HealthField value={dataList.length > 0? (OpportunityUtils.calculateHealth(dataList)) : "-"} />
            }
        }
    ]);
    const [summaryType, setSummaryType] = useState(summaryTypes.find(i=>i.key === props.config.totalizerKey));
    const menu = useRef(null);

    const menuOptions = [
        {
            label: "Summary",
            items: summaryTypes
        },
        {
            separator: true
        },
        {
            label: "Configuration",
            icon: "pi pi-cog"
        }
    ];

    const toggleMenu = (event) => {
        menu.current.toggle(event);
    };
    

    
    return (<div className="pop-motion-item-header">
                <Menu ref={menu} model={menuOptions} popup />
                <div className="title">
                    {props.config.label}
                    <div className="summary">
                        {summaryType.render(props.data)}
                    </div>
                </div>
                <div className="conf" onClick={toggleMenu}><span className="pi pi-ellipsis-v"></span></div>
            </div>);
}