import React, { useState } from 'react';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { AutoComplete } from 'primereact/autocomplete';

export const PlaybookNewActionRoleInput = (props) => {

    const [filteredActions, setFilteredActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [active, setActive] = useState(false);

    function resetValues(){
        setActive(false);
        setFilteredActions([]);
        setSelectedAction(null);
    }

    function searchActions(e){
        const filter = e.query.toLowerCase();

        setFilteredActions(props.playbook.actions.filter(action => action.label.toLowerCase().includes(filter)));
    }

    function handleOnValueChanged(e){
        setSelectedAction(e.value);
    }

    function handleOnBlur(e){
        if(selectedAction == null || selectedAction === ""){
            resetValues();
        }
    }

    function handleOnSelect(e){
        if(props.onExistingActionSelected){
            props.onExistingActionSelected({action: e.value, roleId: props.roleId, stageId: props.stageId});
        }
        setSelectedAction(null);
    }

    function handleOnKeyPress(e){
        if(e.key === 'Enter'){
            let existingAction = null;
            if( typeof selectedAction === 'string'){
                existingAction = props.playbook.actions.find(action => action.label.toLowerCase().includes(selectedAction.toLowerCase()));
            }
            if(existingAction != null){
                if(props.onExistingActionSelected){
                    props.onExistingActionSelected({action: existingAction, roleId: props.roleId, stageId: props.stageId})
                }
            }
            else if(props.onNewActionAdded){
                props.onNewActionAdded({label: selectedAction, roleId: props.roleId, stageId: props.stageId});
            }
            setSelectedAction(null);
        }
    }

    return <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                <InplaceDisplay>
                    Add action
                </InplaceDisplay>
                <InplaceContent>
                    <span className="p-input-icon-right">
                        <AutoComplete value={selectedAction} onKeyPress={handleOnKeyPress} onSelect={handleOnSelect} onBlur={handleOnBlur} onChange={handleOnValueChanged} autoFocus suggestions={filteredActions} completeMethod={searchActions} field="label" />
                        <i className="pi pi-times" onClick={()=>{resetValues()}} />
                    </span>
                </InplaceContent>
            </Inplace>

}