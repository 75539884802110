import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timegrid from '@fullcalendar/timegrid';
import { HitListOpportunities } from './HitListOpportunities';
import { Menu } from 'primereact/menu';
import { ActivitySchedulePopup } from './ActivitySchedulePopup';
import { ActionService } from '../../../services/ActionService';
import { OpportunityUtils } from '../../../Utils/OpportunityUtils';

//import CalendarView from './CalendarView';

export const HitListCalendar = (props) => {

    const [calendarEvents, setCalendarEvents]= useState([]);
    const [selectedGrouping, setSelectedGrouping] = useState({label: 'Group by Owner', field: 'owner_full_name'});
    const [showActivitySchedule, setShowActivitySchedule] = useState(false);
    const [currentDraggedDataItem, setCurrentDraggedDataItem] = useState(null);
    const [currentDraggedDate, setCurrentDraggedDate] = useState(null);
    const [currentDraggedEndDate, setCurrentDraggedEndDate] = useState(null);
    const [currentDraggedIsAllDay,setCurrentDraggedIsAllDay] = useState(null);
    const [currentOverlappingEvents, setCurrentOverlapingEvents] = useState([]);
    const [draggableDefined, setDraggableDefined] = useState(false);
    const calendarRef = useRef(null);
    const groupingMenu = useRef(null);
    const [groupingMenuOptions] = useState([
        {
            label: 'Group by Owner',
            field: 'owner_full_name',
            command: (event)=>{ setSelectedGrouping(event.item) }
        },
        {
            label: 'Group by Stage',
            field: 'sales_stage',
            command: (event)=>{ setSelectedGrouping(event.item) }
        }
    ]);
    useEffect(()=>{
        if(!draggableDefined){
            let draggableEl = document.getElementById("hitlist-opportunities");
            new Draggable(draggableEl, {
                itemSelector: ".fc-event",
                eventData: function (eventEl) {
                let id = eventEl.dataset.id;
                let title = eventEl.getAttribute("title");
                let color = eventEl.dataset.color;
                let custom = eventEl.dataset.custom;
                let duration = eventEl.dataset.duration;
        
                return {
                    id: id,
                    title: title,
                    color: color,
                    custom: custom,
                    create: true,
                    duration: duration
                };
                }
            });
            setDraggableDefined(true);
        }
    }, [setDraggableDefined]);
    useEffect(()=>{
        loadActions();
    }, []);
    useEffect(()=>{
        loadActions();
    }, [selectedGrouping]);
    //////////////////////////////////////////////
    //// PRIVATE METHODS
    //////////////////////////////////////////////
    // function getCalendarAPI(){
    //     const { current: calendarDom } = calendarRef;

    //     return calendarDom ? calendarDom.getApi() : null;
    // }

    function loadActions(){
        const service = new ActionService();
        const colorMappings = OpportunityUtils.getColorMappings();
        service.getActions().then(res=>{
            const newEvents = res.data;
            newEvents.forEach(event=>{
                const opportunity = props.dataSourceList.data.find(opp=>opp.id===event.opportunityId);
                if(opportunity != null){
                    const colorSettings = colorMappings[selectedGrouping.field][opportunity[selectedGrouping.field]];
                    event.color = colorSettings ? colorSettings.background : null;
                }
            });
            setCalendarEvents(newEvents);
        });
    }

    function findOverlapingOrphanEvents(startDate, allDay=false){
        const events = calendarEvents.filter(event => !event.opportunityId && event.allDay === allDay && ((new Date(event.start)) <= startDate) && ((new Date(event.end)) > startDate) );
        return events;
    }
    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////
    function handleActivityScheduled(activity){
        setCurrentDraggedDate(null);
        setCurrentOverlapingEvents(null);
        setCurrentDraggedEndDate(null);
        setCurrentDraggedDataItem(null);
        setCurrentDraggedIsAllDay(false);
        if(activity != null && activity.calendarized){
            loadActions();
        }
    }
    function handleEventReceive(eventInfo){
        const dataItem = props.dataSourceList.data.find(item=> item.id === eventInfo.draggedEl.getAttribute("data-id"));
        if(dataItem != null){
            setCurrentDraggedDataItem(dataItem);
        }
        setCurrentDraggedIsAllDay(eventInfo.event.allDay);
        setCurrentDraggedDate(eventInfo.event.start);
        setCurrentOverlapingEvents(findOverlapingOrphanEvents(eventInfo.event.start, eventInfo.event.allDay));
        setShowActivitySchedule(true);
        eventInfo.event.remove();
    }
    function handleEventChange(changeInfo){
        const found = calendarEvents.find(event => event.id === changeInfo.event._def.publicId);
        if(found != null){
            found.start = changeInfo.event.start;
            found.end = changeInfo.event.end;
            found.allDay = changeInfo.event.allDay;
            const service = new ActionService();
            service.saveAction(found);
        }
    }
    function handleOnColorMappingChanged(){
        loadActions();
    }
    function handleOnSelect(info){
        setCurrentDraggedDate(info.start);
        setCurrentDraggedEndDate(info.end);
        setShowActivitySchedule(true);
    }
    function handleOnActivityPopupHidden(){
        setCurrentOverlapingEvents(null);
        setCurrentDraggedDataItem(null);
        setCurrentDraggedDate(null);
        setCurrentDraggedEndDate(null);
        setCurrentDraggedIsAllDay(false);
        setShowActivitySchedule(false);
    }
    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////
    const toggleGroupingMenu = (event) => {
        groupingMenu.current.toggle(event);
    }
    return (
        <div className="pop-hitlist p-grid p-align-stretch vertical-container">
            <ActivitySchedulePopup 
                fromCalendar={true} 
                visible={showActivitySchedule} 
                playbook={props.playbook} 
                opportunity={currentDraggedDataItem} 
                startDate={currentDraggedDate} 
                endDate={currentDraggedEndDate}
                isAllDay={currentDraggedIsAllDay} 
                overlapingEvents={currentOverlappingEvents}
                onActivityScheduled={handleActivityScheduled} 
                onHide={handleOnActivityPopupHidden} />
            <div id="hitlist-opportunities" className="p-col-2">
                <div className="p-col-12 list-header">
                    <div className="p-toolbar p-component" role="toolbar">
                        {selectedGrouping.label} <span onClick={toggleGroupingMenu} className="pi pi-angle-down"></span>
                        <Menu ref={groupingMenu} model={groupingMenuOptions} popup />
                    </div>
                </div>
                <div className="p-col-12 list">
                    <HitListOpportunities groupBy={selectedGrouping.field} playbook={props.playbook} opps={props.dataSourceList} onColorMappingChanged={handleOnColorMappingChanged} onActivityScheduled={handleActivityScheduled} />
                </div>
            </div>
            <div className="p-col-10">
                <FullCalendar
                    headerToolbar={{
                        // left: "prev,next today",
                        // center: "title",
                        // right: "dayGridMonth,timeGridWeek,timeGridDay"
                        left: "",
                        center: "",
                        right: ""
                    }}
                    ref={calendarRef}
                    plugins={[ dayGridPlugin, interactionPlugin, timegrid ]}
                    initialView="timeGridWeek"
                    weekends={false}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    droppable={true}
                    eventReceive={handleEventReceive}
                    eventChange={handleEventChange}
                    select={handleOnSelect}
                    events={calendarEvents}
                    height="100%"
                />
            </div>
        </div>
    )
}