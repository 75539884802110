import React from 'react';
import { Tag } from 'primereact/tag';
import { PlaybookUtils } from '../../../Utils/PlaybookUtils';

export const PlayTag = (props) => {

    function handleOnTagClick(){
        if(props.onClick){
            props.onClick(props.play);
        }
    }

    let colorMapping = PlaybookUtils.getPlaysColorMapping();
    if(!colorMapping){
        colorMapping = {};
    }
    if(!colorMapping[props.play.id]){
        colorMapping[props.play.id] = PlaybookUtils.getPlayColors()[props.playIndex];
        PlaybookUtils.savePlaysColorMapping(colorMapping);
    }
    const playColor = colorMapping[props.play.id];

    const label = props.play.label + ( props.count ? (` (${props.count})`) : "" );
    const icon = props.allowSelect ? ( props.checked ? "pi pi-fw pi-check-square": "pi pi-fw pi-stop") : "";
    const backgroundColor = props.allowSelect ? ( props.checked ? playColor.background: "transparent") : playColor.background;
    const color = props.allowSelect ? (props.checked ? playColor.font: playColor.background) : playColor.font;
    const borderColor = props.allowSelect ? (props.checked ? "transparent": playColor.background) : "transparent";
    return <span onClick={handleOnTagClick}><Tag value={label} icon={icon} style={{"backgroundColor": backgroundColor, "color": color, "borderColor": borderColor, "borderWidth": "1px", "borderStyle": "solid"}}></Tag></span>


}