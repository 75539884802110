import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SequenceForm } from './SequenceForm';
import { Button } from 'primereact/button';

export const SequencesMenu = (props) => {

    const [editingSequence, setEditingSequence] = useState(false);
    const [showingSequenceId, setShowingSequenceId] = useState(props.showingSequenceId);
    const [editingSequenceId, setEditingSequenceId] = useState("");

    useEffect(()=>{
        setShowingSequenceId(props.showingSequenceId);
    }, [props.showingSequenceId]);

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function raiseOnEditingSequenceChanged(sequence){
        setShowingSequenceId(sequence?.id);
        if(props.onEditingSequenceChanged){
            props.onEditingSequenceChanged(sequence);
        }
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////
    
    function handleOnNewSequenceClick(){
        setEditingSequence(true);
    }

    function handleOnSequenceSelected(sequence){
        //setEditingSequence(true);
        if(sequence.id === showingSequenceId){
            raiseOnEditingSequenceChanged(null);
        }
        else{
            raiseOnEditingSequenceChanged(sequence);
        }
    }

    function handleOnEditingSequenceChanged(sequence){
        raiseOnEditingSequenceChanged(sequence);
    }

    function handleOnEditingSequenceCancelled(){
        setEditingSequence(false);
        setEditingSequenceId("");
        if(props.onEditingSequenceCancelled){
            props.onEditingSequenceCancelled();
        }
    }

    function handleOnSequenceSave(sequence){
        if(props.onSequenceSaved){
            props.onSequenceSaved(sequence);
        }
        setEditingSequence(false);
    }

    function handleOnEditSequenceClick(sequence){
        setEditingSequence(true);
        setEditingSequenceId(sequence.id);
    }

    function handleOnDeleteSequence(sequence){
        setEditingSequence(false);
        if(props.onDeleteSequence){
            props.onDeleteSequence(sequence);
        }
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderSequenceItem(sequence){
        const isSequenceSelected = showingSequenceId === sequence.id;
        return <li key={`sequence-${sequence.id}`} className="sidebar-options-item" onClick={()=>{handleOnSequenceSelected(sequence)}}>
                    <div className="p-d-flex p-flex-row p-ai-center">
                        {
                            isSequenceSelected && 
                            <span className="pi pi-fw pi-check" style={{"marginLeft": "-18px", "paddingLeft": "10px"}}></span>
                        }
                        <div className={classNames('p-d-flex p-flex-column', 'p-ml-3')} style={{ flexGrow: '1' }}>
                            <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                <span className="fs-normal p-text-bold">{sequence.name}</span>
                            </div>
                            <span className="fs-small">Actions: {sequence.playbookActions.length} - Outcomes: {sequence.playbookOutcomes.length}</span>
                        </div>
                        <div>
                            <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-plain" onClick={()=>{handleOnEditSequenceClick(sequence)}}></Button>
                        </div>
                    </div>
                </li>;
    }

    function renderSequenceForm(sequence){
        if(!editingSequence) return null;
        return <li className="sidebar-options-item form">
                    <SequenceForm play={props.play} sequence={sequence} onEditingSequenceChanged={handleOnEditingSequenceChanged} onSave={handleOnSequenceSave} onCancel={handleOnEditingSequenceCancelled} onDelete={handleOnDeleteSequence} />
                </li>
    }

    function renderNewButton(){
        if((editingSequence && editingSequenceId === "") || !props.allowEdit) return null;
        
        return <li className="sidebar-options-item" onClick={handleOnNewSequenceClick}>
                    <div className="p-d-flex p-flex-row p-ai-center">
                        <div className={classNames('p-d-flex p-flex-column', 'p-ml-3')} style={{ flexGrow: '1' }}>
                            <span className="fs-normal">
                                <i className="pi pi-plus" /> Create new play
                            </span>
                        </div>
                    </div>
                </li>
    }

    return  <>
            <ul className="sidebar-options">
                {renderNewButton()}
                { (!editingSequenceId || editingSequenceId === "" ) && renderSequenceForm(null)}
                {
                    props.play.sequences?.map(sequence => {
                        if(editingSequence && editingSequenceId === sequence.id){
                            return renderSequenceForm(sequence);
                        }
                        else{
                            return renderSequenceItem(sequence);
                        }
                    })
                }
                {
                    (!props.allowEdit) && ((props.play.sequences && props.play.sequences.length === 0) || ! props.play.sequences) && 
                    <li className="sidebar-options-not-found">
                        No sequences found
                    </li>
                }
            </ul>
            </>
}