import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import App from "./App";
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'primereact/button';

const AppWrapper = (props) => {
	let location = useLocation();

	const {
		isLoading,
		isAuthenticated,
		error,
		loginWithRedirect,
	  } = useAuth0();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	if (isLoading) {
		return <div>Loading user...</div>;
	}
	if (error) {
		return <div>Oops... {error.message}</div>;
	}
	if (isAuthenticated) {
		switch (props.location.pathname) {
			default:
				return <App />;
		}
	}
	else {
		return <div className="grid grid-nogutter surface-section text-800">
					<div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
						<section>
							<span className="block text-6xl font-bold mb-1">Playbuilt Demo</span>
							<div className="text-6xl text-primary font-bold mb-3">Nail it before scale it</div>
							<p className="mt-0 mb-4 text-700 line-height-3">
								This is a private site. If you have given access, click on login.
							</p>
							<p className="mt-0 mb-4 text-700 line-height-3">
								If you're interested on Playbuilt, hit on "Go to the web site"
							</p>
				
							<Button onClick={loginWithRedirect} label="Login" type="button" className="mr-3 p-button-raised"></Button>
							<Button onClick={()=>{window.location = "https://playbuilt.com"}} label="Go to web site" type="button" className="p-button-outlined"></Button>
						</section>
					</div>
					<div className="col-12 md:col-6 overflow-hidden">
						<img alt="-" src="assets/demo/images/demo-login-background.png" className="md:ml-auto block" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
					</div>
				</div>;
	}
}

export default withRouter(AppWrapper);
