import React, { useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { PlaybookPlayForm } from './PlaybookPlayForm';

export const PlaysManagementButton = (props) => {
    const playsMenu = useRef(null);
    const [showPlayForm, setShowPlayForm] = useState(false);
    const [playToEdit, setPlayToEdit] = useState(null);

    function getPlaysMenuOptions(){
        const options = [];
        props.playbook.plays.forEach(play=>{
            options.push({
                label: play.label,
                play: play,
                command: () => { handleOnSwitchPlay(play) }
            });
        })
        if(props.allowEdit){
            options.push({separator: true});
            options.push(
                {
                    label: 'New play',
                    icon: 'pi pi-fw pi-plus',
                    items: [
                        {
                            label: 'Copy current',
                            icon: 'pi pi-fw pi-copy',
                            command: () => { handleOnNewPlayClicked(true); }
                        },
                        {
                            label: 'Blank',
                            icon: 'pi pi-fw pi-file',
                            command: () => { handleOnNewPlayClicked(false); }
                        }
                    ]
                }
            );
        }
        return options;
    }

    function toggleKanbanMenu(event){
        playsMenu.current.toggle(event);
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnSwitchPlay(play){
        if(props.onSelectedPlayChange){
            props.onSelectedPlayChange(play);
        }
    }

    function handleOnNewPlayClicked(copyCurrentPlay){
        let newPlay = {
            outcomes: props.play.outcomes,
            roles: props.playbook.roles.map( r => {
                return { roleId: r.id }
            })
        };
        if(copyCurrentPlay){
            newPlay = {...props.play};
            newPlay.id = null;
            newPlay.isDefault = false;
        }
        setPlayToEdit(newPlay);
        setShowPlayForm(true);
    }

    function handleOnPlaySaved(play){
        if(props.onPlaySaved){
            props.onPlaySaved(play);
        }
        setShowPlayForm(false);
    }

    function handleOnPlayRemoved(play){
        if(props.onPlayRemoved){
            props.onPlayRemoved(play);
        }
        setShowPlayForm(false);
    }

    function handleOnEditCurrentPlayClicked(){
        setPlayToEdit(props.play);
        setShowPlayForm(true);
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////



    return  <>
                <PlaybookPlayForm visible={showPlayForm} play={playToEdit} onClose={()=>{setShowPlayForm(false)}} onSave={handleOnPlaySaved} onRemove={handleOnPlayRemoved} />
                <Menu ref={playsMenu} model={getPlaysMenuOptions()} popup />
                    {
                        props.allowEdit && 
                        <span className="p-buttonset" style={props.style}>
                            <Button className="p-button-outlined" onClick={toggleKanbanMenu} label={props.play.label} style={{"width": "80%", color: '#1A73E8'}}></Button>
                            <Button className="p-button-outlined" icon="pi pi-pencil" onClick={handleOnEditCurrentPlayClicked} style={{color: '#1A73E8'}} />
                        </span>
                    }
                    {
                        !props.allowEdit &&
                        <Button className="p-button-outlined" onClick={toggleKanbanMenu} label={props.play.label} style={{"width": "80%"}}></Button>
                    }
            </>
}