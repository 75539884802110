import React, {useRef, useState} from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { PlaybookTemplateForm } from './PlaybookTemplateForm';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';


export const PlaybookTemplateItem = (props) => {
    const optionsMenu = useRef(null);
    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    function handleOnSaved(template){
        if(props.onSave){
            props.onSave(template);
        }
        setShowEdit(false);
    }

    function handleOnRemoved(){
        if(props.onRemove){
            props.onRemove(props.template);
        }
    }

    function handleOnAddTemplateToAction(action){
        if(props.onAddTemplateToAction){
            props.onAddTemplateToAction(props.template, action);
        }
    }

    function getOptions(){
        const actionOptions = props.playbook.actions.map(action => {
            return {
                label: action.label,
                icon: ( action.templateId === props.template.id ) ? 'pi pi-fw pi-check' : '',
                disabled: action.templateId === props.template.id,
                command: ()=> { 
                    if(action.templateId != null && action.templateId !== ""){
                        const confirm = () => {
                            confirmDialog({
                                message: `The action "${action.label}" already has a template. Do you want to override it?`,
                                header: 'Confirmation',
                                icon: 'pi pi-exclamation-triangle',
                                accept: () => {handleOnAddTemplateToAction(action)}
                            });
                        }
                        confirm();
                    }
                    else{
                        handleOnAddTemplateToAction(action); 
                    }
                }
            }
        })
        return  [
                    { 
                        label: 'Add to action', icon: 'pi pi-fw pi-plus',
                        items: actionOptions
                    },
                    {separator: true},
                    { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: ()=>{ setShowEdit(true); optionsMenu.current.toggle() } },
                    { label: 'Remove', icon: 'pi pi-fw pi-trash', command: ()=>{ setShowDeleteConfirm(true); optionsMenu.current.toggle() } }, 
                ]
    }

    return <div className={classNames("card")}>
                <PlaybookTemplateForm template={props.template} visible={showEdit} onCancel={()=>{setShowEdit(false)}} onSave={handleOnSaved} />
                <ConfirmDialog visible={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)} message="Are you sure you want to remove the template from the Playbook and all the actions using it?" header="Delete Template" icon="pi pi-exclamation-triangle" accept={handleOnRemoved} />
                <div className="card-header p-mb-0">
                    <label>{props.template.label}</label>
                    <div>
                        <TieredMenu ref={optionsMenu} popup={true} model={getOptions()}></TieredMenu>
                        <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => optionsMenu.current.toggle(event)}></Button>
                    </div>
                </div>
            </div>

}