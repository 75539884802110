import axios from 'axios';

const ACTIONS_KEY = "actions";

export class ActionService {

    getActions(opportunityId=null) {

        if(localStorage.getItem(ACTIONS_KEY) == null){
            return axios.get('assets/demo/data/actions.json').then(res => {
                this.saveActions(res.data.data);
                return res.data;
            });
        }
        else{
            return new Promise((resolution)=>{
                const actions = JSON.parse(localStorage.getItem(ACTIONS_KEY));
                const result = {
                    "total": 20,
                    "page_number": 1,
                    "page_size": 100,
                    "total_hs_scheduled": 25,
                    "total_hs_tracked": 10,
                    "total_hs_in_opps": 15,
                    "total_not_scheduled_actions": 5,
                    "total_planned_time_to_schedule": 20,
                    "data": actions
                }
                resolution( result );
            })
        }
    }

    saveActions(actions){
        localStorage.setItem(ACTIONS_KEY, JSON.stringify(actions));
    }

    saveAction(action){
        return new Promise((resolution)=>{
            this.getActions().then(res => {
                let actions = res.data;
                if(action != null){

                    if(action.id){
                        actions = actions.filter(a => a.id !== action.id);
                    }
                    else{
                        action.id = "action-" + action.opportunityId + "-" + (new Date()).getTime();
                    }
                    
                    actions.push(action);
                    this.saveActions(actions);
                    
                    resolution(action);
                }
            });
        });
    }
}