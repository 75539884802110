import axios from 'axios';
import { OpportunityUtils } from '../Utils/OpportunityUtils';

const DATASOURCE_KEY_PREFIX = "DATASOURCE_";

export class DataSourceServiceFactory {

    static getDataSourceLocalStorageKey(playbook){
        return `${DATASOURCE_KEY_PREFIX}${playbook.id}_${playbook.dataPipeline.type}`;
    }

    static getData(playbook) {
        switch(playbook.dataPipeline.dataSourceType){
            case "demo":
                return this.getDemoData(playbook);
            default:
                throw new Error("NOT IMPLEMENTED DATAPIPELINE");
        }
    }

    static getDemoData(playbook){
        if(localStorage.getItem(this.getDataSourceLocalStorageKey(playbook)) == null){
            const jsonFile = playbook.dataPipeline.connectionMetadata.jsonfile;
            return axios.get(jsonFile).then(res => {
                this.saveDemoDataToLocalStorage(playbook, res.data.data);
                return res.data;
            });
        }
        else{
            return new Promise((resolution)=>{
                const dataList = JSON.parse(localStorage.getItem(this.getDataSourceLocalStorageKey(playbook)));
                dataList.forEach(dataItem => {
                    if(dataItem.PRESCALE__C_kanban_position === undefined){
                        dataItem.PRESCALE__C_kanban_position = {};
                    }
                    if(dataItem.PRESCALE__C_health_percentage === undefined){
                        dataItem.PRESCALE__C_health_percentage = 0;
                    }
                    if(dataItem.PRESCALE__C_health === undefined){
                        dataItem.PRESCALE__C_health = OpportunityUtils.calculateHealth([dataItem]);
                    }
                });
                const result = {
                    "total": dataList.length,
                    "page_number": 1,
                    "page_size": 100,
                    "health": OpportunityUtils.calculateHealth(dataList),
                    "tech_sales_stage_count": 1953,
                    "evaluation_plan_count": 130,
                    "verifiers_touched": 1867,
                    "total_acv": OpportunityUtils.calculateACV(dataList),
                    "total_effort": 43288.56,
                    "data": dataList
                }
                resolution( result );
            })
        }
    }

    static updateEntitiesByKanban(playbook, kanban, board){
        const newList = [];
        //UPDATE ATTRIBUTES
        kanban.forEach(column=>{
            let newOrder = 1;
            column.data.forEach(item => {
                item[board.criteriaField] = column.config.criteriaValue;
                item.PRESCALE__C_kanban_position[board.id] = newOrder;
                newOrder ++;
                newList.push(item);
            });
        });
        this.saveDemoDataToLocalStorage(playbook, newList);
        //UPDATE SORTING
    }

    static updateSingleEntity(playbook, entity){
        return new Promise((resolution)=>{
            this.getData(playbook).then(entities => {
                const indexOfEntity = entities.data.findIndex( e=> e.id === entity.id);
                const newList = entities.data.filter(e => e.id !== entity.id);
                newList.splice(indexOfEntity, 0, entity);
                this.saveDemoDataToLocalStorage(playbook, newList);
                entities.data = newList;
                entities.health = OpportunityUtils.calculateHealth(entities.data);
                resolution(entities);
            });
        });
    }

    static updateEntityOutcome(playbook, entity, outcome, answer){
        return new Promise((resolution)=>{
            this.getData(playbook).then(entities => {
                entities.data.forEach(entityToUpdate => {
                    if(entityToUpdate.id === entity.id){
                        if( entityToUpdate.PRESCALE__C_playbook_outcome_answers == null ){
                            entityToUpdate.PRESCALE__C_playbook_outcome_answers = [];
                        }
        
                        let outcomeToUpdate = entityToUpdate.PRESCALE__C_playbook_outcome_answers.find( o=>o.outcomeId === outcome.id );
                        if(outcomeToUpdate == null){
                            outcomeToUpdate = {
                                outcomeId: outcome.id
                            }
                            entityToUpdate.PRESCALE__C_playbook_outcome_answers.push(outcomeToUpdate)
                        }
        
                        outcomeToUpdate.value = answer.value;
                        outcomeToUpdate.description = answer.description;
        
                        return;
                    }
                });
                this.saveDemoDataToLocalStorage(playbook, entities.data);
                resolution(entities);
            });
        });
    }

    static saveDemoDataToLocalStorage(playbook, data){
        localStorage.setItem(this.getDataSourceLocalStorageKey(playbook), JSON.stringify(data));
    }

}