import React from 'react';
import { Tag } from 'primereact/tag'

export const TagsField = (props) => {
    
    let tags = [];
    if(props.tags && props.tags.length > 0){
        props.tags.forEach((tag, index) => {
            tags.push(
                <Tag className="p-mr-1" key={index} severity={tag.severity} value={tag.label}></Tag>
            )
        })
    }
    
    return( 
        <>
            {tags}
        </>
    );
}