const POP_SETTINGS_KEY = "pop_settings";

const DEFAULT_SETTINGS = {
    currentMode: "kanban",
    runner: {
        lastActivePlaybook: "",
        lastActivePlay: "",
    },
    kanbanSettings: {
        currentBoard: "sales-play"
    },
    calendarSettings: {},
    listSettings: {}
}

export class POPSettingsService {

    static loadSettings() {
        return new Promise((resolution)=>{
            let settings = DEFAULT_SETTINGS;
            if(localStorage.getItem(POP_SETTINGS_KEY) != null){
                settings = JSON.parse(localStorage.getItem(POP_SETTINGS_KEY));
            }
            this.saveSettings(settings);
            resolution( settings );
            
        })
    }

    static saveSettings(settings) {
        localStorage.setItem(POP_SETTINGS_KEY, JSON.stringify(settings));
    }

}