import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { PlaybookUtils } from '../../../Utils/PlaybookUtils';
import classNames from 'classnames';

export const SequenceForm = (props) => {

    const [activeTagIndex, setActiveTagIndex] = useState(null);
    const [editingSequence, setEditingSequence] = useState(null);
    
    useEffect(()=>{
        prepareEditingSequence(props.sequence);
    }, [props.sequence]);

    useEffect(()=>{
        raiseSequenceEdited();
    }, [editingSequence]);

    //////////////////////////////////////////////
    //// METHODS
    //////////////////////////////////////////////

    function isNew(seq){
        return seq === null || seq.id === undefined;
    }

    function prepareEditingSequence(seq){
        let sequence = null;
        if(seq == null){
            sequence = {
                name: "",
                description: "",
                criterias: [],
                playbookActions: [],
                playbookOutcomes: []
            };
        }
        else{
            sequence = {...seq};
        }

        sequence.playbookActions = buildActions(seq);
        sequence.playbookOutcomes = buildOutcomes(seq);

        setEditingSequence(sequence);
    }
    
    function raiseSequenceEdited(){
        if(props.onEditingSequenceChanged){
            props.onEditingSequenceChanged(editingSequence);
        }
    }

    function buildActions(seq){
        let actions = [];
        if(!isNew(seq)){
            actions = [...seq.playbookActions];
        }
        else{
            props.play.stages.forEach(stage => {
                stage.actionRoles.forEach(ar=>{
                    actions.push({actionId: ar.id, template: ar.template});
                });
            });
        }
        return actions;
    }

    function buildOutcomes(seq){
        let outcomes = [];
        if(!isNew(seq)){
            outcomes = [...props.sequence.playbookOutcomes];
        }
        else{
            props.play.stages.forEach(stage => {
                stage.outcomes.forEach(outcome=>{
                    outcomes.push(outcome.id);
                });
            });
        }

        return outcomes;
    }

    function resetForm(){
        setEditingSequence(null);
    }

    function isFormValid(){
        let valid = true;
        if(editingSequence?.name == null || editingSequence?.name === ''){
            valid = false;
        }
        if(editingSequence?.playbookActions.length === 0){
            valid = false;
        }
        if(editingSequence?.playbookOutcomes.length === 0){
            valid = false;
        }
        return valid;
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnNameChange(e){
        const newSeq = {...editingSequence}
        newSeq.name = e.target.value;
        setEditingSequence(newSeq);
    }

    function handleOnCancelClick(){
        resetForm()
        if(props.onCancel){
            props.onCancel();
        }
    }

    function handleOnSaveClick(){
        if(props.onSave){
            props.onSave(editingSequence);
        }
        resetForm();
    }

    function handleOnDeleteClick(){
        if(props.onDelete){
            props.onDelete(editingSequence);
        }
        resetForm();
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderActionsPicker(){
        const list = [];

        let lastStage = null;
        editingSequence?.playbookActions.forEach(sqa => {
            const stage = PlaybookUtils.getStageByActionRoleId(props.play, sqa.actionId);
            if(stage){
                if(stage.id !== lastStage?.id){
                    list.push(<li key={`sequence-form-stage-${stage.id}`} className="stage">{stage.label}</li>);    
                }
                const actionRole = PlaybookUtils.getActionRoleById(props.play, sqa.actionId);
                const action = PlaybookUtils.getActionById(props.play, actionRole.actionId);
                const role = PlaybookUtils.getRoleById(props.play, actionRole.roleId);

                list.push(<li key={`sequence-form-action-${sqa.actionId}`} className="action">{`${role.label} - ${action.label}`}</li>);
                lastStage = stage;
            }
        });

        return <ul className="action-picker">{list}</ul>
    }

    function renderOutcomesPicker(){
        const list = [];

        let lastStage = null;
        editingSequence?.playbookOutcomes.forEach(outcomeId => {
            const stage = PlaybookUtils.getStageByOutcomeId(props.play, outcomeId);
            if(stage){
                if(stage.id !== lastStage?.id){
                    list.push(<li key={`sequence-form-stage-${stage.id}`} className="stage">{stage.label}</li>);    
                }
                const outcome = PlaybookUtils.getOutcomeById(props.play, outcomeId);

                list.push(<li key={`sequence-form-outcome-${outcome.id}`} className="action">{`${outcome.label}`}</li>);
                lastStage = stage;
            }
        });

        return <ul className="action-picker">{list}</ul>
    }

    return  <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <InputText value={editingSequence?.name} onChange={handleOnNameChange} id="sequence-name" placeholder="Play name" type="text" className="p-inputtext-sm p-d-block"/>
                </div>
                <div className="p-field p-col-12 fs-small">
                    <Accordion activeIndex={activeTagIndex} onTabChange={(e) => setActiveTagIndex(e.index)} className="fs-small">
                        <AccordionTab header={`Actions (${editingSequence?.playbookActions.length})`}>
                            {renderActionsPicker()}
                        </AccordionTab>
                        <AccordionTab header={`Outcomes (${editingSequence?.playbookOutcomes.length})`}>
                            {renderOutcomesPicker()}
                        </AccordionTab>
                        <AccordionTab header="Criterias (0)">
                            
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className={classNames("p-field", (isNew(editingSequence)?"p-col-6": "p-col-4"))}>
                    <Button label="Cancel" className="p-button-outlined p-button-sm" icon="pi pi-times" onClick={handleOnCancelClick} />
                </div>
                {
                    (!isNew(editingSequence)) && 
                    <div className="p-field p-col-4">
                        <Button label="Delete" className="p-button-outlined p-button-sm" icon="pi pi-trash" onClick={handleOnDeleteClick} />
                    </div>
                }
                <div className={classNames("p-field", (isNew(editingSequence)?"p-col-6": "p-col-4"))}>
                    <Button label={"Save"} icon="pi pi-check" className="p-button-sm" disabled={!isFormValid()} onClick={handleOnSaveClick} />
                </div>
            </div>
}