import React, { Fragment, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { OpportunityUtils } from '../../../Utils/OpportunityUtils';
import { InputMask } from 'primereact/inputmask';
import { ActionUtils } from '../../../Utils/ActionUtils';
import { Divider } from 'primereact/divider';
import { Checkbox } from 'primereact/checkbox';
import { ActionService } from '../../../services/ActionService';
import { PlaybookUtils } from '../../../Utils/PlaybookUtils';

export const ActivitySchedulePopup = (props) => {

    const [title, setTitle] = useState("");
    const [titleManuallyEdited, setTitleManuallyEdited] = useState(false);
    const [action, setAction] = useState(null);
    const [type, setType] = useState(null);
    const [typeManuallyEdited, setTypeManuallyEdited] = useState(false);
    const [date, setDate] = useState(new Date());
    const [from, setFrom] = useState(new Date());
    const [duration, setDuration] = useState("");
    const [durationManuallyEdited, setDurationManuallyEdited] = useState(false);
    const [description, setDescription] = useState("");
    const [calendarized, setCalendarized] = useState(true);
    const [showCalendarized, setShowCalendarized] = useState(true);
    const [actions, setActions] = useState([]);
    const [overlapingEvents, setOverlapingEvents] = useState([]);
    const [currentOverlapingEvent, setCurrentOverlapingEvent] = useState(null);
    const [mergingOverlappingEvent, setMergingWithOverlapingEvent] = useState(false);
    const [isAllDay, setIsAllDay] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(()=>{
        loadAvailableActions();
    }, [props.playbook, props.opportunity]);

    useEffect(()=>{
        if(actions != null){
            const selectedAction = PlaybookUtils.getActionById(props.playbook, action);
            let newType = type;
            if(( !typeManuallyEdited || type == null)){
                newType = selectedAction?.defaultType
                setType(newType);
            }
            if((!durationManuallyEdited || duration === "")){
                setDuration(ActionUtils.convertHsToDurationString(selectedAction?.defaultDurationHs));
            }
            setAutomaticTitle(action, newType);

        }
    }, [action]);

    useEffect(()=>{

        setAutomaticTitle(action, type);

    }, [type]);

    useEffect(()=>{

        setAutomaticTitle(action, type);

    }, [props.opportunity]);

    useEffect(()=>{
        setAction(props.action);
    }, [props.action]);

    useEffect(()=>{
        if(props.isAllDay){
            setIsAllDay(props.isAllDay);
        }
    }, [props.isAllDay]);

    useEffect(()=>{
        if(props.startDate){
            setDate(props.startDate);
            setFrom(props.startDate);
        }
    }, [props.startDate]);

    useEffect(()=>{
        if(props.endDate){
            let hs = Math.abs(date - props.endDate) / 36e5;
            setDuration(ActionUtils.convertHsToDurationString(hs));
        }
    },[props.endDate]);

    useEffect(() => {
        if(props.fromCalendar){
            setCalendarized(true);
            setShowCalendarized(false);
        }
    }, [props.fromCalendar]);

    useEffect(() => {
        if(props.overlapingEvents && Array.isArray(props.overlapingEvents) && props.overlapingEvents.length > 0){
            setMergingWithOverlapingEvent(true);
            setOverlapingEvents(props.overlapingEvents);
            setCurrentOverlapingEvent(props.overlapingEvents[0]);
        }
    }, [props.overlapingEvents]);

    useEffect(()=>{
        if(currentOverlapingEvent){
            mergeWithOverlapingEvent(currentOverlapingEvent);
        }
    }, [currentOverlapingEvent]);

    useEffect(()=>{
        if(mergingOverlappingEvent){
            if(!currentOverlapingEvent && props.overlapingEvents && Array.isArray(props.overlapingEvents) && props.overlapingEvents.length > 0){
                setCurrentOverlapingEvent(props.overlapingEvents[0]);
            }
        }
        else{
            setTitleManuallyEdited(false);
            setAutomaticTitle(action, type);
            setDate(new Date(props.startDate));
            setIsAllDay(props.isAllDay);
            setDescription("");
            const selectedAction = PlaybookUtils.getActionById(props.playbook, action);
            setDuration(selectedAction?.defaultDurationHs);
            setDurationManuallyEdited(false);
        }
    },[mergingOverlappingEvent]);
    
    //////////////////////////////////////////////
    //// PRIVATE METHODS
    //////////////////////////////////////////////

    function setAutomaticTitle(action, type){
        const selectedAction = PlaybookUtils.getActionById(props.playbook, action);
        if((!titleManuallyEdited || title === "") && props.opportunity){
            let newTitle = OpportunityUtils.getCardTitle(props.playbook, props.opportunity);
            if(selectedAction){
                newTitle += ": " + selectedAction.label;
                if(type && type !== ""){
                    newTitle += " " + type;
                }
            }
            setTitle(newTitle);
        }
    }
    
    function isFormValid(){
        return title !== "" && title != null && date != null && (isAllDay || (duration !== "" && duration != null));
    }

    function loadAvailableActions(){
        const allActions = [];
        if(props.playbook != null && props.opportunity != null){
            const availableActions = OpportunityUtils.getOpportunityAvailableActions(props.playbook, props.opportunity);
            const suggestedActions = [];
            const otherActions = [];
            availableActions.forEach(action=>{
                if(action.isSuggested){
                    suggestedActions.push(action);
                }
                else{
                    otherActions.push(action);
                }
            });

            
            if(suggestedActions.length > 0){
                allActions.push(
                    { 
                        label: 'Suggested next actions', id: 'next',
                        items: suggestedActions
                    }
                );
            }

            if(otherActions.length > 0){
                allActions.push(
                    {
                        label: 'Other actions', id: 'others',
                        items: otherActions
                    }
                )
            }
        }
        setActions(allActions)
    }

    function resetState(){
        setMergingWithOverlapingEvent(false);
        setOverlapingEvents([]);
        setTitle("");
        setTitleManuallyEdited(false);
        setAction(null);
        setType(null);
        setTypeManuallyEdited(false);
        setDate(new Date());
        setFrom(new Date());
        setDuration("");
        setDurationManuallyEdited(false);
        setDescription("");
        setCalendarized(true);
        setShowCalendarized(true);
        setIsAllDay(false);
        setIsSaving(false);
    }

    function mergeWithOverlapingEvent(event){
        setTitle(event.title);
        setTitleManuallyEdited(true);
        setDate(new Date(event.start));
        setIsAllDay(event.isAllDay);
        setDescription(event.description);
        setDuration(ActionUtils.convertHsToDurationString(Math.abs((new Date(event.start)) - (new Date(event.end))) / 36e5));
        setDurationManuallyEdited(true);
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleSaveClick(){
        if(isFormValid()){
            const fromDate = new Date(date);
            fromDate.setHours(from.getHours(), from.getMinutes(), 0);

            const toDate = new Date(fromDate);
            const convertedDuration = ActionUtils.convertDurationToHsObject(duration);
            toDate.setHours(fromDate.getHours() + convertedDuration.hours, fromDate.getMinutes() + convertedDuration.minutes, 0);
            const newAction = ActionUtils.buildAction(((mergingOverlappingEvent && currentOverlapingEvent)?currentOverlapingEvent.id:null), props.opportunity?.id, action, title, fromDate, toDate, description, calendarized, isAllDay);
            
            const actionService = new ActionService();
            setIsSaving(true);
            actionService.saveAction(newAction).then(res=>{
                setIsSaving(false);
                onActivityScheduled(newAction);
                onHide();
            });
        }
    }

    function onActivityScheduled(newAction){
        if(props.onActivityScheduled){
            props.onActivityScheduled(newAction);
        }
    }

    function onHide(){
        resetState();
        if(props.onHide){
            props.onHide();
        }
    }

    function handleTitleChanged(e){
        setTitle(e.target.value); 
        setTitleManuallyEdited(true);
    }

    function handleMergingOptionChanged(e){
        setMergingWithOverlapingEvent(e.value);
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////
    
    function renderFooter(){
        return  <div>
                    <Button label="Cancel" className="p-button-outlined" icon="pi pi-times" onClick={onHide} />
                    <Button label={isSaving?"Saving...":"Save"} loading={isSaving} disabled={!isFormValid()} icon="pi pi-check" onClick={handleSaveClick} />
                </div>;
    }

    function renderOverrideEventSelect(){
        if(overlapingEvents.length > 0){
            if(overlapingEvents.length === 1){
                return  <div className="p-field p-col-12 p-md-8" style={{paddingTop: "5px"}}>
                                <InputSwitch id="calendarized" checked={mergingOverlappingEvent} onChange={handleMergingOptionChanged} />&nbsp;&nbsp;
                                Merge with meeting "{overlapingEvents[0].title}"?
                        </div>
            }
        }
        return null;
    }

    function renderForm(){
        return <div className="p-fluid p-formgrid p-grid">
                    {renderOverrideEventSelect()}
                    {
                    actions.length > 0 &&  
                    <>
                        <div className="p-field p-col-12 p-md-8" style={{marginBottom: "0px"}}>
                            <label htmlFor="state">Action</label>
                            <Dropdown id="state" value={action} onChange={(e) => setAction(e.value)} options={actions} placeholder="Select One" optionValue="id" optionGroupLabel="label" optionGroupChildren="items"></Dropdown>
                        </div>
                        <div className="p-field p-col-12 p-md-4" style={{marginBottom: "0px"}}>
                            <label htmlFor="state">Type</label>
                            <Dropdown id="state" value={type} onChange={(e) => {setType(e.value); setTypeManuallyEdited(true)}} options={ActionUtils.getActionTypesOptions()} placeholder="Select a type"></Dropdown>
                        </div>
                        <Divider />
                    </>
                    }
                    <div className="p-field p-col-12">
                        <label htmlFor="activity-title">Title</label>
                        <InputText id="activity-title" value={title} onChange={handleTitleChanged} placeholder="Add a title for your activity" type="text" className="p-inputtext-lg p-d-block"/>
                    </div>
                    {
                        showCalendarized && 
                        <div className="p-field p-col-12">
                            <label htmlFor="calendarized">Add to calendar</label>&nbsp;&nbsp;
                            <InputSwitch id="calendarized" checked={calendarized} onChange={(e) => setCalendarized(e.value)} />&nbsp;&nbsp;{ calendarized ? "Planned" : "Next action" }
                        </div>
                    }
                    
                    {
                        calendarized && 
                        <Fragment>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="activity-date">Date</label>
                                <Calendar value={date} onChange={(e) => {setDate(e.value)}} id="activity-date" showIcon />
                            </div>
                            <div className="p-field p-col-12 p-md-1">
                                <label htmlFor="activity-date">All day</label>
                                <div style={{marginTop: "10px"}}>
                                <Checkbox onChange={e => setIsAllDay(e.checked)} checked={isAllDay}></Checkbox>
                                </div>
                            </div>
                            {
                                !isAllDay && <Fragment>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="activity-from">From</label>
                                        <Calendar disabled={isAllDay} value={from} onChange={(e) => setFrom(e.value)} id="activity-from" timeOnly hourFormat="12" />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="duration">Duration</label>
                                        <InputMask disabled={isAllDay} id="duration" slotChar="__:__ hs" mask="99:99 hs" autoClear="false" maxlength="4" value={duration} onChange={(e) => {setDuration(e.value); setDurationManuallyEdited(true)}}></InputMask>
                                    </div>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {
                        !calendarized && 
                        <Fragment>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="due-date">Due/Estimated Date</label>
                                <Calendar minDate={new Date()} value={date} onChange={(e) => setDate(e.value)} id="due-date" showIcon />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="activity-from">Estimated time</label>
                                <InputMask id="estimatedDuration" slotChar="__:__ hs" mask="99:99 hs" autoClear="false" maxlength="4" value={duration} onChange={(e) => setDuration(e.value)}></InputMask>
                            </div>
                        </Fragment>
                    }
                    <div className="p-field p-col-12">
                        <label htmlFor="activity-description">Description</label>
                        <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} id="activity-description" rows="3" />
                    </div>
                </div>;
    }
    
    return (
        <Dialog className="schedule-activitiy-popup" onHide={onHide} header="Add new activity" visible={props.visible} footer={renderFooter()} style={{ width: '50vw' }}>
            {renderForm()}
        </Dialog>
    );
}