import classNames from 'classnames';
import { Button } from 'primereact/button';
import React from 'react';
import { MotionBoard } from './MotionBoard';

export const POPMetrics = (props) => {
    // let currentBoard = useRef(props.currentBoard);

    return (
            <>
            <div>
                <MotionBoard metricsOnly={true} playbook={props.playbook} currentBoard={props.currentBoard} dataSourceList={props.dataSourceList} />
            </div>
            <div className="p-grid dashboard">
                <div className="p-col-12">
                    Outcome
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-check"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Closed Opps</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge pink-color">4/58</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-dollar"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Bookings</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge orange-color">1.5M</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-percentage"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Win rate</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge pink-color">6.8%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-dollar"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >ASP</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge green-color">$375K</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-check"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Active / Attached</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge">58/72</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-dollar"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Forecast</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge">$2.9M</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-heart"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Health</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge pink-color">Low</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-clock"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Age</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge pink-color">89 days</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-bolt"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Attach Velocity</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge green-color">1 day</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-clock"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Action Hours</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge">300hs</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-clock"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Avg Hours</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge pink-color">75hs</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">
                            <i className="pi pi-dollar"></i>
                            <h6 className={classNames('p-m-0', 'p-pl-2')} >Won per hour</h6>
                            <div className={classNames('p-ml-auto')}>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                            </div>
                        </div>
                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-mb-1 fs-xlarge">$5k</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
    )

}