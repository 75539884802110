import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { PlaybookUtils } from '../../../Utils/PlaybookUtils';
import { InputText } from 'primereact/inputtext';

export const DataPipelineConfig = (props) => {

    function getTypes(){
        return PlaybookUtils.getDataPipelinesConfig();
    }

    function getDataSources(typeId){
        return PlaybookUtils.getDataPipelinesConfig().find(type => type.id === typeId)?.dataSources;
    }

    function getDataSourceFields(typeId, dataSourceTypeId){
        return PlaybookUtils.getDataPipelinesConfig().find(type => type.id === typeId)?.dataSources.find( type => type.id === dataSourceTypeId )?.metadataFields;
    }

    function handleOnTypeChanged(e){
        const clonedConfig = {...props.playbook.dataPipeline};
        clonedConfig.type = e.value;
        if(props.onPlaybookDataSourceChange){
            props.onPlaybookDataSourceChange(clonedConfig);
        }
    }

    function handleOnDataSourceChanged(e){
        const clonedConfig = {...props.playbook.dataPipeline};
        clonedConfig.dataSourceType = e.value;
        getDataSourceFields(clonedConfig.type, clonedConfig.dataSourceType).forEach(field => {
            if(!clonedConfig.connectionMetadata[field.fieldName]){
                clonedConfig.connectionMetadata[field.fieldName] = field.defaultValue;
            }
        });

        if(props.onPlaybookDataSourceChange){
            props.onPlaybookDataSourceChange(clonedConfig);
        }
    }

    function handleOnConnectionMetadataChanged(fieldName, value){
        const clonedConfig = {...props.playbook.dataPipeline};
        clonedConfig.connectionMetadata[fieldName] = value;
        if(props.onPlaybookDataSourceChange){
            props.onPlaybookDataSourceChange(clonedConfig);
        }
    }

    function handleOnStageMappingFieldChanged(e){
        const clonedConfig = {...props.playbook.dataPipeline};
        clonedConfig.stageField = e.target.value;
        if(props.onPlaybookDataSourceChange){
            props.onPlaybookDataSourceChange(clonedConfig);
        }
    }

    function renderDataSourceConfig(){
        if(!props.playbook.dataPipeline.dataSourceType){
            return <div className="p-field p-col-12 p-md-12" ><InputText id="config" placeholder="Select a Data Source first" disabled={true} type="text"/></div>;
        }
        else{
            const configForm = [];
            getDataSourceFields(props.playbook.dataPipeline.type, props.playbook.dataPipeline.dataSourceType)?.forEach(field => {
                let value = props.playbook.dataPipeline.connectionMetadata[field.fieldName];
                configForm.push(
                    <div key={`container-${field.fieldName}`} className="p-field p-col-12 p-md-12" >
                        <label key={`label-${field.fieldName}`} htmlFor={field.fieldName}>{field.label}</label>
                        <InputText key={field.fieldName} value={value} id={field.fieldName} placeholder={field.placeholder} type={field.type} onChange={(e)=>{ handleOnConnectionMetadataChanged(field.fieldName, e.target.value) }}/>
                    </div>
                )
            });
            return configForm;
        }
    }

    return  <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12" >
                    <label htmlFor="type">Playbook Type</label>
                    <Dropdown id="type" value={props.playbook.dataPipeline.type} options={getTypes()} placeholder="Select a playbook type" optionValue="id" optionLabel='label' onChange={handleOnTypeChanged}></Dropdown>
                </div>
                <div className="p-field p-col-12 p-md-12" >
                    <label htmlFor="datasource">Data Source Type</label>
                    <Dropdown id="datasource" value={props.playbook.dataPipeline.dataSourceType} options={getDataSources(props.playbook.dataPipeline.type)} placeholder="Select a data source" optionValue="id" optionLabel='label'  onChange={handleOnDataSourceChanged}></Dropdown>
                </div>
                <div className="p-field p-col-12 p-md-12" >
                    <b>Data Source Config</b>
                </div>
                {renderDataSourceConfig()}
                <div className="p-field p-col-12 p-md-12" >
                    <b>Runner Config</b>
                </div>
                <div className="p-field p-col-12 p-md-12" >
                    <label htmlFor="stagefield">Stage Mapping Field</label>
                    <InputText id="stagefield" value={props.playbook.dataPipeline.stageField} placeholder="Type the field name" type="text" onChange={handleOnStageMappingFieldChanged}/>
                </div>
            </div>;
}