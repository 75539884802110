import React from 'react';
import { TagsField } from './TagsField';
import { AccountNameField } from './AccountNameField';
import { ACVField } from './ACVField';
import { HealthField } from './HealthField';
import { OwnerField } from './OwnerField';
import { LinkedInField } from './LinkedInField';
import { LinkField } from './LinkField';
import { EmailField } from './EmailField';
import { PhoneField } from './PhoneField';

export const OpportunityField = (props) => {
    let field = null;
    switch(props.attribute){
        case "acv":
            field = <ACVField value={props.opportunity[props.attribute]}/>;
            break;
        case "tags":
            field = <TagsField tags={props.opportunity[props.attribute]} />;
            break;
        case "account_name":
            field = <AccountNameField value={props.opportunity[props.attribute]} />;
            break;
        case "health":
            field = <HealthField value={props.opportunity.PRESCALE__C_health_percentage} />;
            break;
        case "linkedin":
            field = <LinkedInField value={props.opportunity[props.attribute]} />;
            break;
        case "cv":
            field = <LinkField label={props.attribute} value={props.opportunity[props.attribute]} />;
            break;
        case "email":
            field = <EmailField value={props.opportunity[props.attribute]} />;
            break;
        case "phone":
            field = <PhoneField value={props.opportunity[props.attribute]} />;
            break;
        case "account_owner_full_name":
        case "owner_full_name":
            field = <OwnerField value={props.opportunity[props.attribute]} />
            break;
        default:
            field = <span>{props.opportunity[props.attribute]}</span>;
            break;
    }

    return field;
}