import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

export const PlaybookStageHeader = (props) => {

    const menu = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(props.stage.label);

    const confirmRemove = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => raiseOnRemoveStage()
        });
    }

    const options = [
        {
            label: 'Rename',
            icon: 'pi pi-pencil',
            command: ()=>{handleOnEdit()}
        },
        {
            label: 'Remove',
            icon: 'pi pi-trash',
            command: confirmRemove 
        },
        {
            label: 'Add new left',
            icon: 'pi pi-angle-left',
            command: ()=>{raiseOnAddNewStage("left")}
        },
        {
            label: 'Add new right',
            icon: 'pi pi-angle-right',
            command: ()=>{raiseOnAddNewStage("right")}
        }
    ];

    function raiseOnTitleUpdated(){
        if(props.onTitleChange){
            props.onTitleChange(props.stage, newTitle);
        }
    }

    function raiseOnAddNewStage(direction){
        toggleMenu();
        if(props.onAddStage){
            props.onAddStage(direction, props.stage);
        }
    }

    function raiseOnRemoveStage(){
        toggleMenu();
        if(props.onRemove){
            props.onRemove(props.stage);
        }
    }

    function cancelEdit(){
        setNewTitle(props.stage.label);
        setIsEditing(false);
    }

    const toggleMenu = (event) => {
        menu.current.toggle(event);
    };

    function handleOnEdit(){
        setIsEditing(true);
        toggleMenu()
    }

    function handleOnBlur(){
        setIsEditing(false);
        raiseOnTitleUpdated();
    }

    function handleOnKeyDown(e){
        if(e.key === 'Escape'){
            cancelEdit();
        }
        if(e.key === 'Enter'){
            setIsEditing(false);
            raiseOnTitleUpdated();
        }
    }

    function renderTitle(){
        if(isEditing){
            return <InputText key={props.stage.id} onBlur={handleOnBlur} onChange={(e)=>setNewTitle(e.target.value)} onKeyDown={handleOnKeyDown} autoFocus value={newTitle} />
        }
        return props.stage.label;
    }
    
    return <th className="editable-cell" width={props.width} key={`header-${props.stage.id}`}>
                <div className="header">
                    <label onDoubleClick={()=>{if(props.allowEdit)setIsEditing(true)}} className={ classNames("title", (isEditing?"editing":""))}>
                        {
                            renderTitle()
                        }
                    </label>
                    {
                        props.allowEdit && props.play.isDefault && !isEditing && 
                        <div className="menu">
                            <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={toggleMenu}></Button>
                            <TieredMenu ref={menu} model={options} popup />
                        </div>
                    }
                </div>
            </th>

}