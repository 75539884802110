import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom'

import AppTopbar from './AppTopbar';
// import AppBreadcrumb from "./AppBreadcrumb";
import AppInlineMenu from './AppInlineMenu';
// import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
// import AppConfig from './AppConfig';
// import AppRightMenu from './AppRightMenu';

import { InboxPage } from './pages/Inbox/InboxPage';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { PopPage } from './pages/Pop/PopPage';
import { PlaybookPage } from './pages/Playbook/PlaybookPage';
import { DashboardPage } from './pages/Dashboard/DashboardPage';
import { SettingsPage } from './pages/Settings/SettingsPage';


export const RTLContext = React.createContext();

const App = () => {

    const menuMode = 'slim';
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [searchActive, setSearchActive] = useState(false)
    let currentInlineMenuKey = useRef(null);

    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const menu = [
        { label: 'DASHBOARD', icon: 'pi pi-fw pi-home', to: '/' },
        // { label: 'INBOX', icon: 'pi pi-fw pi-inbox', to: '/inbox', badge: '4', badgeClassName: 'p-badge-info'},
        {
            label: 'RUNNER', icon: 'pi pi-fw pi-dollar', to: '/run',
            // items: [
            //     { label: 'Motion', icon: 'pi pi-fw pi-step-forward', to: '/pop/motion' },
            //     { label: 'Hitlist', icon: 'pi pi-fw pi-list', to: '/pop/hitlist' },
            //     { label: 'Pipeline', icon: 'pi pi-fw pi-sliders-h', to: '/pop/list' },
            //     { label: 'Outcomes', icon: 'pi pi-fw pi-dollar', to: '/pop/outcomes' }
            // ]
        },
        { label: 'BUILDER', icon: 'pi pi-fw pi-play', to: '/playbook' },
        {
            label: 'AIM', icon: 'pi pi-fw pi-briefcase',
            items: [
                { label: 'Metrics', icon: 'pi pi-fw pi-chart-line', to: '/aim/metrics' },
                { label: 'Verifiers', icon: 'pi pi-fw pi-check-square', to: '/aim/calendar' },
                { label: 'Debriefs', icon: 'pi pi-fw pi-paperclip', to: '/aim/timeline' },
                { label: 'Fields', icon: 'pi pi-fw pi-cog', to: '/aim/timeline' },
            ]
        },
        {
            label: 'SET',  icon: 'pi pi-fw pi-user',
            items: [
                { label: 'Portfolio', icon: 'pi pi-fw pi-briefcase', to: '/set/portfolio' },
                { label: 'Credentials', icon: 'pi pi-fw pi-id-card', to: '/set/credentials' },
                { label: 'Team', icon: 'pi pi-fw pi-sitemap', to: '/set/team' },
                { label: 'Network', icon: 'pi pi-fw pi-globe', to: '/set/network' }
            ]
        },
        { label: 'SETTINGS', icon: 'pi pi-fw pi-cog', to: '/settings' },
    ];

    // const routes = [
    //     { parent: '', label: '' },
    //     { parent: 'Favorites', label: 'Dashboard Analytics' },
    //     { parent: 'UI Kit', label: 'Form Layout' },
    //     { parent: 'UI Kit', label: 'Input' },
    //     { parent: 'UI Kit', label: 'Float Label' },
    //     { parent: 'UI Kit', label: 'Invalid State' },
    //     { parent: 'UI Kit', label: 'Button' },
    //     { parent: 'UI Kit', label: 'Table' },
    //     { parent: 'UI Kit', label: 'List' },
    //     { parent: 'UI Kit', label: 'Panel' },
    //     { parent: 'UI Kit', label: 'Tree' },
    //     { parent: 'UI Kit', label: 'Overlay' },
    //     { parent: 'UI Kit', label: 'Menu' },
    //     { parent: 'UI Kit', label: 'Message' },
    //     { parent: 'UI Kit', label: 'File' },
    //     { parent: 'UI Kit', label: 'Chart' },
    //     { parent: 'UI Kit', label: 'Misc' },
    //     { parent: 'Utilities', label: 'Display' },
    //     { parent: 'Utilities', label: 'Elevation' },
    //     { parent: 'Utilities', label: 'Flexbox' },
    //     { parent: 'Utilities', label: 'Icons' },
    //     { parent: 'Utilities', label: 'Widgets' },
    //     { parent: 'Utilities', label: 'Grid' },
    //     { parent: 'Utilities', label: 'Spacing' },
    //     { parent: 'Utilities', label: 'Typography' },
    //     { parent: 'Utilities', label: 'Text' },
    //     { parent: 'Pages', label: 'Crud' },
    //     { parent: 'Pages', label: 'Calendar' },
    //     { parent: 'Pages', label: 'Timeline' },
    //     { parent: 'Pages', label: 'Invoice' },
    //     { parent: 'Pages', label: 'Login' },
    //     { parent: 'Pages', label: 'Help' },
    //     { parent: 'Pages', label: 'Empty' },
    //     { parent: 'Pages', label: 'Access' },
    //     { parent: 'Start', label: 'Documentation' }
    // ]

    useEffect(() => {
        if(menuMode === 'static') {
            setDesktopMenuActive(true)
        }
    }, [menuMode]);

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo');
        appLogoLink.src = './logo.png';
    });

    /**
     * PRIVATE METHODS
     */

    const hideOverlayMenu = () => {
        setMobileMenuActive(false)
        setDesktopMenuActive(false)
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    /**
     * HANDLERS
     */

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop())
            setDesktopMenuActive((prevState) => !prevState);
        else
            setMobileMenuActive((prevState) => !prevState)

        event.preventDefault();

    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else {
            setActiveTopbarItem(event.item)
        }

        event.originalEvent.preventDefault();
    }

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && !isDesktop()) {
            hideOverlayMenu();
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    }

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState)
    }

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && !isDesktop()) {
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    }

    const onInlineMenuClick = (e, key) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            menuKeys[currentInlineMenuKey.current] = false;
        }

        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    }

    /**
     * LAYOUT
    */
    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-prescale layout-topbar-prescale', 'layout-menu-slim', 'p-input-filled',  
        {
            'layout-menu-active': desktopMenuActive,
            'layout-menu-mobile-active': mobileMenuActive,
            'layout-topbar-mobile-active': mobileTopbarActive,
            'layout-rightmenu-active': rightMenuActive
        }
    );

    return (
        <RTLContext.Provider value="">
            <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <AppTopbar horizontal={false}
                    activeTopbarItem={activeTopbarItem}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarItemClick={onTopbarItemClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    onMobileTopbarButtonClick={onMobileTopbarButtonClick} mobileTopbarActive={mobileTopbarActive}
                    searchActive={searchActive} onSearch={onSearch} />

                {/* <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={false} menuMode={menuMode} />
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick}
                            menuMode={menuMode} active={menuActive} />
                    </div>
                </div> */}

                <div className="layout-main">

                    {/*     <AppBreadcrumb routes={routes} /> */}

                    <div className="layout-content">
                        <Switch>
                            <Route path="/" exact component={DashboardPage} />
                            <Route path="/inbox" component={InboxPage} />
                            <Route path="/run/:view" component={PopPage} />
                            <Route path="/run" component={PopPage} />
                            <Route path="/playbook/:id" component={PlaybookPage} />
                            <Route path="/playbook" component={PlaybookPage} />
                            <Route path="/settings" component={SettingsPage} />
                        </Switch>
                    </div>

                    {/* <AppFooter colorMode={colorMode} /> */}

                </div>

                {/* <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuButtonClick={onRightMenuButtonClick} /> */}

                {mobileMenuActive && <div className="layout-mask modal-in"></div>}
            </div>
        </RTLContext.Provider>
    );

}

export default App;
