import React from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

export const SharePlaybook = (props) => {

    function handleOnCancelClick(){
        if(props.onClose){
            props.onClose()
        }
        navigator.clipboard.writeText(playbookURL)
    }

    function renderFooter(){
        return  <div>
                    <Button label={"Copy Link"} icon="pi pi-copy" onClick={handleOnCancelClick}/>
                </div>;
    }

    const items = [
        {
            label:'playbuilt.com',
        },
    ];

    const playbookURL = window.location.href

    return <Dialog visible={props.visible} header={"Share Playbook"} footer={renderFooter()} onHide={handleOnCancelClick} style={{ width: '500px' }}>
    <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-12">
            <p>
                Share your playbook in Viewer Mode with your team!
            </p>
        </div>
        <div>
            <Card style={{className:"p-field p-col-12 p-md-12", color:'#626262', width: '30rem', background:'#F5F5F5', borderRadius:'5px', marginLeft:'8px'}}>
                {playbookURL}
            </Card>
        </div>
        {/* <PanelMenu model={items} style={{width:'487px'}} className="p-field p-col-12 p-md-12"/> */}
    </div>
</Dialog>

}