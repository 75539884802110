import React, {useEffect, useRef, useState} from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { SelectButton } from 'primereact/selectbutton';

export const POPHeader = (props) => {
    const playbookMenu = useRef(null);
    const playMenu = useRef(null);
    const [playbookMenuOptions, setPlaybookMenuOptions] = useState([]);
    const [playMenuOptions, setPlayMenuOptions] = useState([]);
    
    useEffect(()=>{
        const playbookMenuItems = [];
        props.playbooks.forEach(playbook => {
            playbookMenuItems.push({
                value: playbook,
                label: playbook.name,
                command: (event)=>{props.onPlaybookChange(event.item.value)}
            })
        });
        setPlaybookMenuOptions(playbookMenuItems);

        const playMenuItems = [];
        props.playbook.plays.forEach(play => {
            playMenuItems.push({
                value: play,
                label: play.label,
                command: (event)=>{props.onPlayChange(event.item.value)}
            })
        });
        setPlayMenuOptions(playMenuItems);
    }, [props]);

    function handleModeClick(mode){
        props.onModeChange(mode);
    }

    const togglePlaybookMenu = (event) => {
        playbookMenu.current.toggle(event);
    }
    const togglePlayMenu = (event) => {
        playMenu.current.toggle(event);
    }

    const getModes = () => {
        const modes = [];
        modes.push({value: 'kanban', label: <span className="pi prescale-icon-kanban"></span>});
        modes.push({value: 'hitlist', label: <span className="pi pi-calendar"></span>});
        modes.push({value: 'list', label: <span className="pi pi-list"></span>});
        modes.push({value: 'metrics', label: <span className="pi pi-chart-line"></span>});
        return modes;
    }

    return (
        <div className="p-toolbar p-component" role="toolbar">
            <div className="p-toolbar-group-left">
                <Menu ref={playbookMenu} model={playbookMenuOptions} popup />
                <Button className="p-button" onClick={togglePlaybookMenu} label={<span>{props.playbook != null?props.playbook.name:""}&nbsp;&nbsp;<span className="pi pi-angle-down"/></span>}></Button>
                &nbsp; &nbsp;
                <SelectButton value={props.mode} options={getModes()} onChange={(e) => handleModeClick(e.value)}></SelectButton>
            </div>
            <div className="p-toolbar-group-right">
                {props.contextualMenu}
            </div>
        </div>
    );
}