import React from 'react';

export const LinkField = (props) => {

    const icon = (props.icon)?props.icon:<span className="pi pi-link"></span>;

    return( 
        <span className={props.className}>
            {icon} <a rel="noreferrer" href={props.value} target="_blank">{props.label ?? "Link"}</a>
        </span>
    );
}