import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import React from 'react';
import { OpportunityService } from '../../services/OpportunityService';
import { PlaybookService } from '../../services/PlaybookService';

export const SettingsPage = () => {

    function resetDemoData(){
        OpportunityService.deleteData();
        PlaybookService.deleteData();
    }

    function handleOnResetClicked(){
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => resetDemoData()
        });
    }

    return  <div>
                <Button label='Reset demo data' icon="pi pi-trash" onClick={handleOnResetClicked}/>
            </div>;
}