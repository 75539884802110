import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';

export const PlaybookPlayForm = (props) => {

    const [label, setLabel] = useState("");
    const [description, setDescription] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isNew, setIsNew] = useState(true);

    useEffect(()=>{
        if(props.play != null){
            setIsNew(props.play.id == null || props.play.id == "");
            setLabel(props.play.label);
            setDescription(props.play.description);
        }
        else{
            resetForm();
        }
    }, [props.play]);

    function isFormValid(){
        return label != null && label !== "";
    }

    function resetForm(){
        setIsNew(true);
        setLabel("");
        setDescription("");
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnCancelClick(){
        if(props.onClose){
            props.onClose()
        }
    }

    function handleOnRemoveClick(){
        confirmDialog({
            message: 'Are you sure you want to remove this play?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { if(props.onRemove){props.onRemove(props.play)} }
        });
    }

    function handleSaveClick(){
        setIsSaving(true);
        let playToSave = null;
        if(isNew){
            playToSave = {
                id: `PL-${(new Date()).getTime()}`,
                sequenceEnabled: false,
                isDefault: false,
                actions: props.play?.actions ? props.play.actions : [],
                outcomes: props.play?.outcomes ? props.play.outcomes : [],
                roles: props.play?.roles ? props.play.roles : []
            };
        }
        else{
            playToSave = {...props.play};
        }
        playToSave.label = label;
        playToSave.description = description;
        if(props.onSave){
            props.onSave(playToSave);
        }
        setIsSaving(false);
    }
    
    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderFooter(){
        return  <div>
                    { !isNew && !props.play.isDefault && 
                        <Button label="Remove" className="p-button-secondary p-button-text" icon="pi pi-trash" onClick={handleOnRemoveClick} />
                    }
                    <Button label="Cancel" className="p-button-outlined" icon="pi pi-times" onClick={handleOnCancelClick} />
                    <Button label={isSaving?"Creating...":"Create"} loading={isSaving} disabled={!isFormValid()} icon="pi pi-check" onClick={handleSaveClick} />
                </div>;
    }

    return <Dialog visible={props.visible} header={isNew?"New play":"Edit Play"} footer={renderFooter()} onHide={handleOnCancelClick} style={{ width: '30vw' }}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <p>
                            You're about to create a new Play for the current playbook.
                        </p>
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="label">Name</label>
                        <InputText autoFocus={true} id="label" placeholder="Set the play name" value={label} onChange={(e)=>{setLabel(e.target.value)}} />
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="description">Description</label>
                        <InputTextarea value={description} onChange={(e)=>{setDescription(e.target.value)}} id="description" rows="3" />
                    </div>
                </div>
            </Dialog>

}