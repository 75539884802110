import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { OpportunityField } from '../../../Components/Fields/OpportunityField';
import { TieredMenu } from 'primereact/tieredmenu';
import { OutcomesPopupEditor } from './OutcomesPopupEditor';
import { DataSourceServiceFactory } from '../../../services/DataSourceServiceFactory';
import { ActivitySchedulePopup } from './ActivitySchedulePopup';
import { OpportunityUtils } from '../../../Utils/OpportunityUtils';
import { confirmDialog } from 'primereact/confirmdialog';
import { PlayTag } from './PlayTag';

export const OpportunityCard = (props) => {
    
    const menu = useRef(null);
    const [showOutcomesForm, setShowOutcomesForm] = useState(false);
    const [showActivitySchedule, setShowActivitySchedule] = useState(false);
    const [currentAction, setCurrentAction] = useState(false);

    function handleOnOutcomeSaved(opportunity){
        if(opportunity != null){
            setShowOutcomesForm(false);
            DataSourceServiceFactory.updateSingleEntity(props.playbook, opportunity);
        }
    }

    function handleActivityScheduled(activity){
        if(props.onActivityScheduled){
            props.onActivityScheduled(activity);
        }
    }

    function handleCallAnAudibleClicked(play){
        confirmDialog({
            message: `Are you sure you want to assign this card to the play "${play.label}"?`,
            header: 'Calling an Audible',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {handleOnCallAnAudibleAccepted(play);}
        });
    }

    function handleOnCallAnAudibleAccepted(play){
        if(props.onCallAnAudible){
            props.onCallAnAudible(props.opportunity, play);
        }
    }

    function getMenuOptions(){
        const actions = getOpportunityActivities();
        const templates = getOpportunityTemplates();
        const menuItems = [];

        menuItems.push(
            {
                label: 'Edit opportunity',
                icon: 'pi pi-pencil'
            }
        );
        menuItems.push(
            {
                label: 'Track time',
                icon: 'pi pi-clock'
            }
        );
        menuItems.push(
            {
                id: "activities",
                label: 'Schedule activity',
                icon: 'pi pi-calendar-plus',
                items: actions,
                command: () => {  menu.current.toggle(); setCurrentAction(null); setShowActivitySchedule(true) }
            }
        );
        menuItems.push(
            {
                label: 'Update outcomes',
                icon: 'pi pi-check-circle',
                command: () => {  menu.current.toggle(); setShowOutcomesForm(true) }
            }
        );
        menuItems.push(
            {
                label: 'Create Slack Channel',
                icon: 'pi pi-slack'
            }
        );
        if(templates.length > 0){
            menuItems.push(
                {
                    separator: true
                }
            );
            menuItems.push(
                {
                    label: 'Available Templates',
                    disabled: true
                }
            );
            templates.forEach( template => {
                menuItems.push(template)
            });
        }
        menuItems.push(
            {
                separator: true
            }
        );
        if(props.playbook.plays.length > 1){
            const assignedPlayId = props.opportunity.PRESCALE__C_assigned_play ? props.opportunity.PRESCALE__C_assigned_play[props.playbook.id] : null;
            const audiblePlays = props.playbook.plays
            .filter(p => p.id !== assignedPlayId)
            .map(play => {
                return {
                    label: play.label,
                    command: () => { handleCallAnAudibleClicked(play) }
                };
            });
            menuItems.push(
                {
                    label: 'Call an Audible',
                    icon: 'pi pi-reply',
                    items: audiblePlays
                }
            );
        }
        menuItems.push(
            {
                label: 'Config card',
                icon: 'pi pi-cog'
            }
        );
        menuItems.push(
            {
                label: 'Sync',
                icon: 'pi pi-refresh'
            }
        );
        menuItems.push(
            {
                label: 'Archive',
                icon: 'pi pi-trash'
            }
        );
        return menuItems;
    }

    const getOpportunityTemplates = ()=>{
        const templates = [];
        if(props.playbook){
            OpportunityUtils.getOpportunityAvailableTemplates(props.playbook, props.opportunity).forEach(template => {
                templates.push(
                    {
                        label: template.label,
                        command: () => { window.open(template.value, '_blank'); }
                    }
                );
            });
        }
        return templates;
    }

    const getOpportunityActivities = ()=>{
        if(props.playbook != null){
            let actions = [];
            
            OpportunityUtils.getOpportunityAvailableActions(props.playbook, props.opportunity).filter(action => action.isSuggested).forEach(action => {
                const insert = {...action};
                insert.command = () => {  menu.current.toggle(); setCurrentAction(action.id); setShowActivitySchedule(true) }
                actions.push(insert);
            });

            if(actions.length > 0){
                actions.push({
                    separator: true
                });
            }
            actions.push({
                label: "Other",
                command: () => {  menu.current.toggle(); setCurrentAction(null); setShowActivitySchedule(true) }
            });
            actions.push({
                label: "Settings",
                icon: "pi pi-cog"
            });
            return actions;
        }
    }

    const renderOutcomes = () => {
        return !showOutcomesForm ? null : <OutcomesPopupEditor playbook={props.playbook} dataItem={props.opportunity} onSave={handleOnOutcomeSaved} onHide={()=>{setShowOutcomesForm(false)}} />;
    }

    const renderFields = () =>{
        let fields = [];
        if(props.showPlayTag){
            fields.push(<li key="play-tag"><PlayTag play={OpportunityUtils.getMatchingPlay(props.playbook, props.opportunity)} /></li>)
        }
        if(props.fields != null ){
            props.fields.forEach(field => {
                fields.push( <li key={field}><OpportunityField attribute={field} opportunity={props.opportunity} /></li> );
            });
        }
        if(props.children != null){
            fields.push(props.children);
        }
        return fields;
    }

    return (
        <div key={props.opportunity.id} className={classNames("card", props.className)}>
            {renderOutcomes()}
            <ActivitySchedulePopup visible={showActivitySchedule} action={currentAction} playbook={props.playbook} opportunity={props.opportunity} onActivityScheduled={handleActivityScheduled} onHide={()=>setShowActivitySchedule(false)} />
            <TieredMenu ref={menu} model={getMenuOptions()} popup />
            <div className="title">
                {OpportunityUtils.getCardTitle(props.playbook, props.opportunity)}
                <div className="config" onClick={(e)=>{menu.current.toggle(e);}}><span className="p-col-1 pi-ellipsis-v pi"></span></div>
            </div>
            <div className="content">
                <ul>
                    {renderFields()}
                </ul>
            </div>
        </div>
    );
}