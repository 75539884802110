import React, {useState} from 'react';
import { Button } from 'primereact/button';
import { PlaybookService } from '../../../services/PlaybookService';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { PlaybookUtils } from '../../../Utils/PlaybookUtils';


export const PublishTemplateForm = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState(props.playbook?.name);
    const [description, setDescription] = useState(props.playbook?.description);
    const [tags, setTags] = useState([]);

    //////////////////////////////////////////////
    //// METHODS
    //////////////////////////////////////////////

    function isFormValid(){
        return name != null && name !== '' && props.playbook != null;
    }

    function resetForm(){
        setName(props.playbook?.name);
        setDescription(props.playbook?.description);
        setTags(null);
    }

    function createTemplate(){
        setIsSaving(true);
        const service = new PlaybookService();
        const template = PlaybookUtils.createPlaybookTemplate(props.playbook, name, description, tags);
        service.addPlaybookTemplate(template).then(templates => {
            setIsSaving(false);
            resetForm();
            raiseOnTemplateAdded(templates);
        });
    }

    function raiseOnHide(){
        if(props.onHide){
            props.onHide();
        }
    }

    function raiseOnTemplateAdded(templates){
        if(props.onTemplateAdded){
            props.onTemplateAdded(templates);
        }
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleSaveClick(){
       createTemplate();
    }
    
    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderFooter(){
        return  <div>
                    <Button label="Cancel" className="p-button-outlined" icon="pi pi-times" onClick={raiseOnHide} />
                    <Button label={isSaving?"Saving...":"Save"} loading={isSaving} disabled={!isFormValid()} icon="pi pi-check" onClick={handleSaveClick} />
                </div>;
    }

    return  <Dialog visible={props.visible} style={{ width: '50vw' }} header="Publish as Template" footer={renderFooter()} onHide={() => { raiseOnHide() }}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="template-name">Template Name</label>
                        <InputText id="template-title" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Add a template name" type="text" className="p-inputtext-lg p-d-block"/>
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="template-tags">Tags</label>
                        <Chips id="template-tags" value={tags} onChange={(e) => setTags(e.value)}></Chips>
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="template-description">Description</label>
                        <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} id="template-description" rows="3" />
                    </div>
                </div>
            </Dialog>;
}