import axios from 'axios';
import { OpportunityUtils } from '../Utils/OpportunityUtils';

const OPPORTUNITIES_KEY = "oportunities";

export class OpportunityService {

    getOpportunities() {
        if(localStorage.getItem(OPPORTUNITIES_KEY) == null){
            return axios.get('assets/demo/data/opportunities.json').then(res => {
                this.saveOpps(res.data.data);
                return res.data;
            });
        }
        else{
            return new Promise((resolution)=>{
                const opps = JSON.parse(localStorage.getItem(OPPORTUNITIES_KEY));
                const result = {
                    "total": opps.length,
                    "page_number": 1,
                    "page_size": 100,
                    "health": OpportunityUtils.calculateHealth(opps),
                    "tech_sales_stage_count": 1953,
                    "evaluation_plan_count": 130,
                    "verifiers_touched": 1867,
                    "total_acv": OpportunityUtils.calculateACV(opps),
                    "total_effort": 43288.56,
                    "data": opps
                }
                resolution( result );
            })
        }
    }

    updateOpportunitiesByKanban(kanban, board){
        const newOppList = [];
        //UPDATE ATTRIBUTES
        kanban.forEach(column=>{
            let newOrder = 1;
            column.opps.forEach(opp => {
                opp[board.criteriaField] = column.config.criteriaValue;
                opp.PRESCALE__C_kanban_position[board.id] = newOrder;
                newOrder ++;
                newOppList.push(opp);
            });
        });
        this.saveOpps(newOppList);
        //UPDATE SORTING
    }

    updateOpportunity(opportunity){
        return new Promise((resolution)=>{
            this.getOpportunities().then(opptys => {
                const newList = opptys.data.filter(opp => opp.id !== opportunity.id );
                newList.push(opportunity);
                this.saveOpps(newList);
                opptys.data = newList;
                opptys.health = OpportunityUtils.calculateHealth(opptys.data);
                resolution(opptys);
            });
        });
    }

    updateOpportunityOutcome(opportunity, outcome, answer){
        return new Promise((resolution)=>{
            this.getOpportunities().then(opptys => {
                opptys.data.forEach(oppToUpdate => {
                    if(oppToUpdate.id === opportunity.id){
                        if( oppToUpdate.PRESCALE__C_playbook_outcome_answers == null ){
                            oppToUpdate.PRESCALE__C_playbook_outcome_answers = [];
                        }
        
                        let outcomeToUpdate = oppToUpdate.PRESCALE__C_playbook_outcome_answers.find( o=>o.outcomeId === outcome.id );
                        if(outcomeToUpdate == null){
                            outcomeToUpdate = {
                                outcomeId: outcome.id
                            }
                            oppToUpdate.PRESCALE__C_playbook_outcome_answers.push(outcomeToUpdate)
                        }
        
                        outcomeToUpdate.value = answer.value;
                        outcomeToUpdate.description = answer.description;
        
                        return;
                    }
                });
                this.saveOpps(opptys.data);
                resolution(opptys);
            });
        });
    }

    saveOpps(opps){
        localStorage.setItem(OPPORTUNITIES_KEY, JSON.stringify(opps));
    }

    static deleteData(){
        localStorage.removeItem(OPPORTUNITIES_KEY);
    }

}