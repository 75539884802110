import React, {useRef} from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import classNames from 'classnames';

export const PlayStageOutcome = (props) => {

    const optionsMenu = useRef(null);

    //////////////////////////////////////////////
    //// METHODS
    //////////////////////////////////////////////

    function getActionOptions(outcome){
        return  [
                    { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => { handleOnEditOutcome(outcome)} },
                    { label: 'Remove', icon: 'pi pi-fw pi-trash', command: () => {handleOnRemoveOutcome(outcome) } }, 
                ]
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnEditOutcome(outcome){
        if(props.allowEdit && props.onEditOutcome){
            props.onEditOutcome(outcome);
        }
    }

    function handleOutcomeClick(outcome){
        if(props.onOutcomeClick){
            props.onOutcomeClick(outcome);
        }
    }

    function handleOnRemoveOutcome(outcome){
        if(props.onRemoveOutcome){
            props.onRemoveOutcome(outcome);
        }
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    return <div key={`stage-outcome-${props.outcome.id}`} className={classNames("card", "outcome", {"not-in-sequence": !props.isInSequence}, {"in-sequence": props.isInSequence})} onClick={()=>{handleOutcomeClick(props.outcome)}} onDoubleClick={()=>{handleOnEditOutcome(props.outcome)}}>
                <div className="card-header p-mb-0">
                    <label>
                        {props.outcome.label}
                    </label>
                    {
                        props.allowEdit &&
                        <div>
                            <Menu ref={optionsMenu} popup={true} model={getActionOptions(props.outcome)}></Menu>
                            <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => optionsMenu.current.toggle(event)}></Button>
                        </div>
                    }
                </div>
            </div>;
}