import React, { useRef, useState, Fragment } from 'react';
import { OpportunityCard } from './OpportunityCard';
import { OpportunityUtils } from '../../../Utils/OpportunityUtils';
import { OverlayPanel } from 'primereact/overlaypanel';

export const HitListOpportunities = (props) => {
    const cardColorOptions = OpportunityUtils.getCalendarColors();
    const opportunities = props.opps.data.sort((a,b)=> OpportunityUtils.sort(a, b, props.groupBy));
    const [groupColorMapping, setGroupColorMapping] = useState(null);
    const colorPickerPanel = useRef(null);
    const [currentGroupKey, setCurrentGroupKey] = useState("");
    const [currentObjectKey, setCurrentObjectKey] = useState("");

    function getColorMappings(){
        if(!groupColorMapping){
            const mapping = OpportunityUtils.getColorMappings();
            setGroupColorMapping(mapping);
            return mapping;
        }
        return groupColorMapping;
    }

    function updateColorMapping(mapping){
        OpportunityUtils.saveColorMappings(mapping);
        setGroupColorMapping(mapping);
        if(props.onColorMappingChanged){
            props.onColorMappingChanged();
        }
    }

    function assignColor(groupKey, objectKey, colorOption){
        let mapping = {...getColorMappings()};
        if(!mapping[groupKey]){
            mapping[groupKey]={};
        }
        mapping[groupKey][objectKey] = colorOption;
        updateColorMapping(mapping);
    }
    
    function assignRandomColor(groupKey, objectKey){
        const mapping = getColorMappings();
        const usedColors = (mapping && mapping[groupKey]) ? Object.values(mapping[groupKey]) : [];
        
        const availableColors = [];
        cardColorOptions.forEach( (colorOption) => {
            if(!usedColors.find(item=>colorOption.background === item.background)){
                availableColors.push(colorOption);
            }
        });
        let assignedColor = {background: "Transparent"};
        if(availableColors.length > 0){
            assignedColor = availableColors[Math.floor(Math.random() * availableColors.length)];
            assignColor(groupKey, objectKey, assignedColor);
        }
        return assignedColor;
    }
    
    function getGroupingColor(groupKey , objectKey){
        const mapping = getColorMappings();
        let assignedColor = (mapping && mapping[groupKey]) ? mapping[groupKey][objectKey] : null;
        if(!assignedColor){
            assignedColor = assignRandomColor(groupKey, objectKey);
        }
        return assignedColor;
    }

    function handleToggleColorMenu(event, groupKey, objectKey){
        setCurrentGroupKey(groupKey);
        setCurrentObjectKey(objectKey);
        colorPickerPanel.current.toggle(event);
    }

    function handleColorChanged(colorOption){
        assignColor(currentGroupKey, currentObjectKey, colorOption);
    }

    function handleActivityScheduled(activity){
        if(props.onActivityScheduled){
            props.onActivityScheduled(activity);
        }
    }

    const colorMenu =   <OverlayPanel ref={colorPickerPanel} appendTo={document.body} showCloseIcon>
                            {cardColorOptions.map(colorOption => {
                                return <div key={colorOption.background} onClick={(e)=>{handleColorChanged(colorOption)}} style={{display: "inline-block", margin: "3px", width: "10px", height: "10px", backgroundColor: colorOption.background}}></div>
                            })}
                        </OverlayPanel>;

    let currentGroup = null;
    return (
            <>
            {colorMenu}
            {opportunities.map((opp, index)=>{
                let groupHeader = null;
                let colorOption = {background: "", font: ""};
                if(opp[props.groupBy] !== currentGroup){
                    currentGroup = opp[props.groupBy];
                    const objectKey = currentGroup;
                    colorOption = getGroupingColor(props.groupBy, currentGroup);
                    groupHeader =   <div className="card" style={{backgroundColor: colorOption.background, color: colorOption.font}}>
                                        <div className="title">
                                            {currentGroup}
                                            <div className="config" onClick={(e) => {handleToggleColorMenu(e, props.groupBy, objectKey)}}><span className="p-col-1 pi-ellipsis-v pi"></span></div>
                                        </div>
                                    </div>;
                }
                return  <Fragment key={`${index}-${opp.id}`}>
                        {groupHeader}
                        <div 
                            className="fc-event"
                            title={`${opp.name}`}
                            data-id={`${opp.id}`}
                            data-duration="1"
                            data-color={colorOption.background}
                            data-custom="custom stuff"
                            style={{cursor: "grab"}}
                        >
                            <OpportunityCard fields={["account_name", "tags"]} playbook={props.playbook} opportunity={opp} verifiers={props.verifiers} onActivityScheduled={handleActivityScheduled}/>
                        </div>
                        </Fragment>;
            })}
            </>
    );
}