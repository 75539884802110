import React from 'react';
import { OpportunityUtils } from '../../Utils/OpportunityUtils';

export const ACVField = (props) => {

    const icon = (props.icon)?props.icon:<span className="pi pi-dollar"></span>;

    return( 
        <span className={props.className}>
            {icon}{OpportunityUtils.formatACV(props.value)}
        </span>
    );
}