import { OpportunityUtils } from "./OpportunityUtils";

const PLAYS_COLOR_MAPPING_KEY = "plays_color_mapping";
const BUILDER_LAST_VIEW_MODE = "builder_last_view_mode";

export class PlaybookUtils{

    static getDataPipelinesConfig(){
        return [
                {
                    "id": "opportunities",
                    "label": "Pre-Sales",
                    "description": "",
                    "defaultCardFields": ["tags", "account_name", "health"],
                    "defaultCardTitleExpression": "card.name",
                    "dataSources": [
                        {
                            "id": "demo",
                            "label": "Demo",
                            "description": "",
                            "metadataFields": [
                                {
                                    "fieldName": "jsonfile",
                                    "label": "Json URL",
                                    "placeholder": "Place your data source URL",
                                    "defaultValue": "assets/demo/data/opportunities.json",
                                    "type": "text"
                                }
                            ]
                        },
                        {
                            "id": "salesforce",
                            "label": "Salesforce",
                            "description": "",
                            "metadataFields": [
                                {
                                    "fieldName": "company",
                                    "label": "Company",
                                    "placeholder": "Company name",
                                    "defaultValue": "",
                                    "type": "text"
                                },
                                {
                                    "fieldName": "username",
                                    "label": "Email",
                                    "placeholder": "Login email",
                                    "defaultValue": "",
                                    "type": "email"
                                },
                                {
                                    "fieldName": "password",
                                    "label": "Password",
                                    "placeholder": "",
                                    "defaultValue": "",
                                    "type": "password"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "employees",
                    "label": "Enablement",
                    "description": "",
                    "defaultCardTitleExpression": "`${card.first_name} ${card.last_name}`",
                    "defaultCardFields": [],
                    "dataSources": [
                        {
                            "id": "demo",
                            "label": "Demo",
                            "description": "",
                            "metadataFields": [
                                {
                                    "fieldName": "jsonfile",
                                    "label": "Json URL",
                                    "placeholder": "Place your data source URL",
                                    "defaultValue": "assets/demo/data/emplyees.json",
                                    "type": "text"
                                }
                            ]
                        },
                        {
                            "id": "workday",
                            "label": "Workday",
                            "description": "",
                            "metadataFields": [
                                {
                                    "fieldName": "jsonfile",
                                    "label": "Json URL",
                                    "placeholder": "Place your data source URL",
                                    "defaultValue": "assets/demo/data/emplyees.json",
                                    "type": "text"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "candidates",
                    "label": "Recruiting",
                    "description": "",
                    "defaultCardTitleExpression": "`${card.first_name} ${card.last_name}`",
                    "defaultCardFields": ["email", "phone", "linkedin", "cv"],
                    "dataSources": [
                        {
                            "id": "demo",
                            "label": "Demo",
                            "description": "",
                            "metadataFields": [
                                {
                                    "fieldName": "jsonfile",
                                    "label": "Json URL",
                                    "placeholder": "Place your data source URL",
                                    "defaultValue": "assets/demo/data/candidates.json",
                                    "type": "text"
                                }
                            ]
                        },
                        {
                            "id": "workday",
                            "label": "Workday",
                            "description": "",
                            "metadataFields": [
                                {
                                    "fieldName": "jsonfile",
                                    "label": "Json URL",
                                    "placeholder": "Place your data source URL",
                                    "defaultValue": "assets/demo/data/emplyees.json",
                                    "type": "text"
                                }
                            ]
                        }
                    ]
                }
            ]
    }

    static getDataPipelinesConfigById(id){
        return this.getDataPipelinesConfig().find(item => item.id === id);
    }

    static generateNewPlaybook(from=null){
        let newPlaybook = null;
        const newID = `PBK-${(new Date()).getTime()}`;
        if(from){
            newPlaybook = {...from};
            newPlaybook.id = newID;
            newPlaybook.name = `Copy of ${newPlaybook.name}`;
            newPlaybook.published = false;
        }
        else{
            const defaultRole = {
                                    id: `ROLE-${(new Date()).getTime()}`,
                                    label: "New Role"
                                };
            newPlaybook = {
                id: newID,
                name: "Untitled Playbook",
                description: "",
                dataPipeline: {
                    type: null,
                    dataSourceType: null,
                    connectionMetadata: {}
                },
                phasesEnabled: false,
                tags: [],
                phases: [],
                stages: [
                    {
                        id: `STG-${(new Date()).getTime()}`,
                        label: "Stage 1",
                        description: ""
                    }
                ],
                actions: [],
                outcomes: [],
                roles: [
                    defaultRole
                ],
                plays: [
                    {
                        id: `PLAY-${(new Date()).getTime()}`,
                        label: "Default Play",
                        description: "",
                        sequenceEnabled: false,
                        isDefault: true,
                        actions: [],
                        outcomes: [],
                        roles: [ { roleId: defaultRole.id } ]
                    }
                ],
                templates: []
            }
        }
        return newPlaybook;
    }

    static calculateNewActionIndex( playbook, play, stageId, roleId, indexOfNewActionInCell ){
        var BreakException = {};
        let newActionIndex = indexOfNewActionInCell;
        const indexOfStage = playbook.stages.findIndex(s=>s.id === stageId);
        const indexOfRole = playbook.roles.findIndex(r=>r.id === roleId);
        try{
            play.actions.forEach(pa=>{
                const indexOfPAStage = playbook.stages.findIndex(s=>s.id === pa.stageId);
                const indexOfPARole = playbook.roles.findIndex(r=>r.id === pa.roleId);

                if(indexOfStage <= indexOfPAStage && indexOfRole <= indexOfPARole){
                    throw BreakException;
                }
                newActionIndex ++;
            })
        }
        catch(e){
            if(e !== BreakException) throw e;
        }
        return newActionIndex;
    }

    static mustUpdateOutcomesAfterMovingStage(playbook, opportunity, stageFrom, stageTo){
        if(opportunity != null && stageFrom !== stageTo){
            const indexFrom = playbook.stages.findIndex(stage=> stage.label === stageFrom);
            const indexTo = playbook.stages.findIndex(stage=> stage.label === stageTo);
            if(indexFrom === -1 && indexTo === 0){
                return false;
            }
            const currentPlay = OpportunityUtils.getMatchingPlay(playbook, opportunity);
            let outcomesToCompleteInStage = [];
            for(var i = indexFrom; i < indexTo; i ++){
                const stage = playbook.stages[i];
                outcomesToCompleteInStage = outcomesToCompleteInStage.concat(currentPlay.outcomes.filter(po=>po.stageId === stage.id));
            }
            return indexFrom < indexTo && outcomesToCompleteInStage.length > 0;
        }
        return false;
    }
    
    /**DEPRECATE*/
    static getOutcomesToComplete(playbook, play, opportunity, stageFrom, stageTo){
        const outcomes = [];
        
        if(opportunity != null && stageFrom !== stageTo){
            const indexFrom = playbook.stages.findIndex(stage=> stage.id === stageFrom);
            const indexTo = playbook.stages.findIndex(stage=> stage.id === stageTo);
            if(indexFrom < indexTo){
                playbook.stages.forEach((stage, stageIndex)=>{
                    play.outcomes.find(po=>po.stageid === stage.id).forEach(po=>{
                        const outcome = playbook.outcomes.find(o=>o.id === po.outcomeId);
                        const outcomeResult = opportunity.PRESCALE__C_playbook_outcome_answers != null ? opportunity.PRESCALE__C_playbook_outcome_answers.find(outcomeAnswer=> outcomeAnswer.outcomeId === outcome.id) : null;
                        const outcomeCompleted = outcomeResult != null && outcomeResult.value !== "";
                        const isRequired = stageIndex < indexTo && !outcomeCompleted;
                        const isCurrentOutcomeToComplete = stageIndex === indexFrom;
                        outcomes.push(
                            {
                                outcome: outcome,
                                opportunity: opportunity,
                                answer: outcomeResult,
                                isCompleted: outcomeCompleted,
                                isCurrent: isCurrentOutcomeToComplete,
                                isRequired: isRequired
                            }
                        );
                    });
                });
            }
        }

        return outcomes;
    }

    static getStageById(playbook, stageId){
        return playbook?.stages.find(stage => stage.id === stageId);
    }

    static getPlatById(playbook, playId){
        return playbook?.plays.find(play => play.id === playId);
    }

    static getRoleById(playbook, roleId){
        return playbook?.roles.find(role => role.id === roleId);
    }
    
    static getActionById(playbook, actionId){
        return playbook?.actions.find(action => action.id === actionId);
    }

    static getOutcomeById(playbook, outcomeId){
        return playbook?.outcomes.find(outcome => outcome.id === outcomeId);
    }

    static getSequenceById(play, sequenceId){
        return play?.sequences?.find(sequence => sequence.id === sequenceId);
    }

    static getStageByActionRoleId(play, actionRoleId){
        let found = null;
        var BreakException = {};
        try{
            play?.stages.forEach(stage => {
                const actionRle = stage.actionRoles.find(ar=> ar.id === actionRoleId);
                if(actionRle){
                    found = stage;
                    throw BreakException;
                }
            });
        }
        catch(e){
            if(e !== BreakException) throw e;
        }
        return found;
    }

    static removeActionRoleFromStageByActionId(playbook, play, stageId, actionId, roleId){
        const clonedPlaybook = {...playbook};
        const currentPlay = playbook.plays.find(p=>p.id === play.id);
        const playActionToDelete = currentPlay.actions.find(pa => pa.stageId === stageId && pa.actionId === actionId && pa.roleId === roleId);
        const newActions = currentPlay.actions.filter(playAction=> playAction.id !== playActionToDelete.id);
        currentPlay.actions = newActions;
        return clonedPlaybook;
    }

    static removeOutcomeFromStage(playbook, play, stage, outcome){
        const clonedPlaybook = {...playbook};
        const clonedPlay = clonedPlaybook.plays.find(p=>p.id === play.id);
        clonedPlay.outcomes = clonedPlay.outcomes.filter(po => po.stageId !== stage.id || (po.stageId === stage.id && po.outcomeId !== outcome.id));
        
        let shouldRemoveOutcomeFromPlaybook = true;
        
        var BreakException = {};
        try{
            clonedPlaybook.plays.forEach(p=>{
                if(p.outcomes.find(po=>po.outcomeId === outcome.id)){
                    shouldRemoveOutcomeFromPlaybook = false;
                }
            });
        }
        catch(e){
            if(e !== BreakException) throw e;
        }

        if(shouldRemoveOutcomeFromPlaybook){
            clonedPlaybook.outcomes = clonedPlaybook.outcomes.filter(o => o.id !== outcome.id);
        }
        
        return clonedPlaybook;
    }

    static removeTemplateFromPlaybook(playbook, template){
        const clonedPlaybook = {...playbook};
        clonedPlaybook.templates = clonedPlaybook.templates.filter(t => t.id !== template.id);
        clonedPlaybook.actions.filter( a => a.templateId === template.id ).forEach( action => {
            action.templateId = null;
        });
        clonedPlaybook.plays.forEach(pa => {
            pa.actions.filter( a => a.templateId === template.id ).forEach( action => {
                action.templateId = null;
            });
        });
        return clonedPlaybook;
    }

    static removePlayFromPlaybook(playbook, play){
        const clonedPlaybook = {...playbook};
        clonedPlaybook.plays = clonedPlaybook.plays.filter( p => p.id !== play.id);
        return clonedPlaybook;
    }

    static getStageByOutcomeId(play, outcomeId){
        let found = null;
        var BreakException = {};
        try{
            play?.stages.forEach(stage => {
                const outcome = stage.outcomes.find(o=> o.id === outcomeId);
                if(outcome){
                    found = stage;
                    throw BreakException;
                }
            });
        }
        catch(e){
            if(e !== BreakException) throw e;
        }
        return found;
    }

    static getActionRoleById(play, actionRoleId){
        let found = null;
        var BreakException = {};
        try{
            play?.stages.forEach(stage => {
                found = stage.actionRoles.find(ar=> ar.id === actionRoleId);
                if(found){
                    throw BreakException;
                }
            });
        }
        catch(e){
            if(e !== BreakException) throw e;
        }
        return found;
    }

    static getActionOrderBySequence(sequence, actionId){
        const index = sequence?.playbookActions.findIndex(a => a.actionId === actionId);
        if(index >= 0){
            return index + 1;
        }
        return null;
    }

    static createPlaybookTemplate(playbook, name, description, tags){
        return {
            id: "playbook-template-" + (new Date()).getTime(),
            name: name,
            description: description,
            tags: tags,
            rating: 0,
            owner: "Amy Elsner",
            added_date: (new Date()).toDateString(),
            playbook: playbook
        }
    }

    static isPlaybookValidToPublish(playbook){
        let isValid = playbook && playbook.dataPipeline;
        if(isValid){
            isValid = playbook.dataPipeline.type && playbook.dataPipeline.dataSourceType && playbook.dataPipeline.stageField;
            if(isValid){
                const metadataFields = this.getDataPipelinesConfig().find(c => c.id === playbook.dataPipeline.type)?.dataSources.find(ds => ds.id === playbook.dataPipeline.dataSourceType)?.metadataFields;
                if(metadataFields){
                    metadataFields.forEach((field) => {
                        let fieldValue = playbook.dataPipeline.connectionMetadata[field.fieldName];
                        isValid = isValid && fieldValue != null && fieldValue !== "";
                    });
                }
                else{
                    isValid = false;
                }
            }
        }
        return isValid;
    }

    static getPlayColors(){
        return [
            { background: "#AD1457", font: "#FFFFFF"},
            { background: "#F4511E", font: "#FFFFFF"},
            { background: "#E4C441", font: "#FFFFFF"},
            { background: "#0B8043", font: "#FFFFFF"},
            { background: "#3F51B5", font: "#FFFFFF"},
            { background: "#8E24AA", font: "#FFFFFF"},
            { background: "#D81B60", font: "#FFFFFF"},
            { background: "#EF6C00", font: "#212121"},
            { background: "#C0CA33", font: "#212121"},
            { background: "#009688", font: "#212121"},
            { background: "#7986CB", font: "#212121"},
            { background: "#795548", font: "#FFFFFF"},
            { background: "#D50000", font: "#FFFFFF"},
            { background: "#F09300", font: "#212121"},
            { background: "#7CB342", font: "#212121"},
            { background: "#039BE5", font: "#212121"},
            { background: "#B39DDB", font: "#212121"},
            { background: "#616161", font: "#FFFFFF"},
            { background: "#E67C73", font: "#212121"},
            { background: "#F6BF26", font: "#212121"},
            { background: "#33B679", font: "#212121"},
            { background: "#4285F4", font: "#212121"},
            { background: "#9E69AF", font: "#FFFFFF"},
            { background: "#A79B8E", font: "#212121"}
        ]
    }

    static getPlaysColorMapping(){
        return JSON.parse(localStorage.getItem(PLAYS_COLOR_MAPPING_KEY));
    }

    static savePlaysColorMapping(mapping){
        localStorage.setItem(PLAYS_COLOR_MAPPING_KEY, JSON.stringify(mapping));
    }

    static getLastViewMode(){
        return JSON.parse(localStorage.getItem(BUILDER_LAST_VIEW_MODE));
    }

    static saveLastViewMode(editting){
        localStorage.setItem(BUILDER_LAST_VIEW_MODE, JSON.stringify(editting));
    }

}