import React, {useEffect, useRef, useState} from 'react';
import { ACVField } from '../../../Components/Fields/ACVField';
import { HealthField } from '../../../Components/Fields/HealthField';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';


export const OpportunitiesContextualMenu = (props) => {
    const filtersMenu = useRef(null);
    const kanbanMenu = useRef(null);
    const [kanbanMenuOptions, setKanbanMenuOptions] = useState([]);

    useEffect(()=>{
        let options=[];
        props.boards.filter(b => b.playbookId === props.playbook?.id || b.playbookId == null ).forEach(board=>{
            options.push({
                value: board,
                label: board.label,
                command: (event)=>{ props.onBoardChange(event.item.value) }
            })
        });
        options.push({separator: true});
        options.push(
            {
                label: 'New board',
                icon: 'pi pi-plus'
            }
        );
        setKanbanMenuOptions(options);
    }, [props]);

    const getCountOpps = ()=>{
        return props.opps.total;
    }
    const sumACV = () => {
        return props.opps.total_acv;
    }
    const sumHealth = () => { return props.opps.health; }

    const filterOptions = [
        {
            label: 'All Opportunities'
        },
        {
            label: 'Open Opportunities',
        },
        {
            label: 'Closing this Q',
        },
        {
            separator: true
        },
        {
            label: 'New filter',
            icon: 'pi pi-plus'
        }
    ];

    const toggleFiltersMenu = (event) => {
        filtersMenu.current.toggle(event);
    };

    const toggleKanbanMenu = (event) => {
        kanbanMenu.current.toggle(event);
    }

    if(props.opps == null){
        return <i className="pi pi-spin pi-spinner" style={{'fontSize': '1em'}}></i>
    }

    const summaryComponents = [
        <span key="summary-total">&nbsp;•&nbsp;Total: {getCountOpps()} </span>,
        <span key="summary-health">&nbsp;•&nbsp;<HealthField value={sumHealth()} /></span>,
    ];
    switch(props.playbook?.dataPipeline.type){
        case "opportunities":
            summaryComponents.push(<span key="summary-acv">&nbsp;•&nbsp;<ACVField value={sumACV()} /></span>);
            break;
        case "candidates":
            break;
        case "employees":
            break;
    }

    return  <>
                {summaryComponents}
                &nbsp;
                <span className="p-buttonset">
                    <Menu ref={kanbanMenu} model={kanbanMenuOptions} popup />
                    <Button className="p-button-outlined" onClick={toggleKanbanMenu} label={<span>{props.currentBoard != null?props.currentBoard.label:""}&nbsp;&nbsp;<span className="pi pi-angle-down"/></span>} icon="pi prescale-icon-kanban"></Button>
                    <Button className="p-button-outlined" icon="pi pi-pencil" />
                </span>
                &nbsp; &nbsp;
                <Menu ref={filtersMenu} model={filterOptions} popup />
                <Button className="p-button-outlined" type="button" label="All Opportunities" icon="pi pi-filter" onClick={toggleFiltersMenu} style={{ width: 'auto' }} />
            </>


}