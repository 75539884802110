import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import AppWrapper from './AppWrapper';
import 'prismjs/themes/prism-coy.css';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <Auth0Provider
  domain={process.env.REACT_APP_AUTH0_DOMAIN}
  clientId={process.env.REACT_APP_AUTH0_CLIENTID}
  redirectUri={window.location.origin}
  >
    <HashRouter>
      <AppWrapper />
    </HashRouter>
  </Auth0Provider>
  ,
  document.getElementById('root')
);