import React from 'react';

export const HealthField = (props) => {
    let healthText = props.value;
    if(props.value < 50){
        healthText = "Low";
    }
    else if(props.value <= 75){
        healthText = "Medium";
    }
    else if(props.value > 75){
        healthText = "High";
    }
    return( 
        <span className={props.className}>
            <span className="pi pi-heart"></span>&nbsp;{healthText}
        </span>
    );
}