export class ActionUtils{

    static getActionTypesOptions(){
        return [
            {label: "Preparation", value: "preparation"},
            {label: "Delivery", value: "delivery"}
        ];
    }

    static getTemplateTypesOptions(){
        return [
            {label: "Link", value: "link"}
        ];
    }

    static convertDurationToHsObject(duration){
        let converted = {hours: 0, minutes: 0};
        if(duration !== ""){
            const split = duration.replace(" hs", "").split(":");
            converted.hours = (split[0]*1);
            converted.minutes = (split[1]*1);
        }
        return converted;
    }

    static convertDurationToHsNumber(duration){
        const hsObject = this.convertDurationToHsObject(duration);
        const converted = hsObject.hours + (hsObject.minutes / 60);
        return converted;
    }

    static convertHsToDurationString(hs){
        if(!hs){
            return "";
        }
        let hsString = "00";
        let minsString = "00";
        if(hs > 0){
            const hsNumber = Math.floor(hs);
            hsString = `${hsNumber < 10 ? "0": ""}${hsNumber}`;
            const minsNumber = ( hs - hsNumber ) * 60;
            minsString = `${minsNumber < 10 ? "0": ""}${minsNumber}`;
        }
        return `${hsString}:${minsString} hs`;
    }

    static buildAction( id=null, opportunityId, actionId, title, from, to, description="", calendarized=true, isAllDay=false  ){
        const newAction = {
            "id": id,
            "opportunityId": opportunityId,
            "actionId": actionId,
            "title": title,
            "calendarized": calendarized,
            "meeting_data": {},
            "start": from,
            "end": to,
            "description": description,
            "allDay": isAllDay
        };
        return newAction;
    }

}