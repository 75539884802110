import { PlaybookUtils } from "./PlaybookUtils";

const COLOR_MAPPING_KEY = "color_mappings";
export class OpportunityUtils {

    static sort(opp1, opp2, attrbibute) {
        if(opp1[attrbibute] > opp2[attrbibute]){
            return 1;
        }
        else if(opp1[attrbibute] < opp2[attrbibute]){
            return -1;
        }
        return 0;
    }

    static calculateACV(opps){
        let acv = 0;
        opps.forEach(opp=>{
            acv += opp.acv;
        })
        return acv;
    }

    static formatACV(acv){
        return (acv).toLocaleString('en-US');
    }

    static calculateHealth(opps){
        let totalHealth = 0;
        opps.forEach(opp=>{
            totalHealth += opp.PRESCALE__C_health_percentage;
        });
        return totalHealth / opps.length;
    }

    static getCalendarColors(){
        return [
            { background: "#AD1457", font: "#FFFFFF"},
            { background: "#F4511E", font: "#212121"},
            { background: "#E4C441", font: "#212121"},
            { background: "#0B8043", font: "#FFFFFF"},
            { background: "#3F51B5", font: "#FFFFFF"},
            { background: "#8E24AA", font: "#FFFFFF"},
            { background: "#D81B60", font: "#FFFFFF"},
            { background: "#EF6C00", font: "#212121"},
            { background: "#C0CA33", font: "#212121"},
            { background: "#009688", font: "#212121"},
            { background: "#7986CB", font: "#212121"},
            { background: "#795548", font: "#FFFFFF"},
            { background: "#D50000", font: "#FFFFFF"},
            { background: "#F09300", font: "#212121"},
            { background: "#7CB342", font: "#212121"},
            { background: "#039BE5", font: "#212121"},
            { background: "#B39DDB", font: "#212121"},
            { background: "#616161", font: "#FFFFFF"},
            { background: "#E67C73", font: "#212121"},
            { background: "#F6BF26", font: "#212121"},
            { background: "#33B679", font: "#212121"},
            { background: "#4285F4", font: "#212121"},
            { background: "#9E69AF", font: "#FFFFFF"},
            { background: "#A79B8E", font: "#212121"}
        ]
    }

    static saveColorMappings(mapping){
        localStorage.setItem(COLOR_MAPPING_KEY, JSON.stringify(mapping));
    }

    static getColorMappings(){
        return JSON.parse(localStorage.getItem(COLOR_MAPPING_KEY));
    }

    static updateOpportunityOutcome(opportunity, outcome, answer){
        const oppToSave = {...opportunity};
        if(oppToSave != null){
            if(oppToSave.PRESCALE__C_playbook_outcome_answers == null){
                oppToSave.PRESCALE__C_playbook_outcome_answers = [];
            }

            let outcomeToUpdate = oppToSave.PRESCALE__C_playbook_outcome_answers.find( o=>o.outcomeId === outcome.id );
            if(outcomeToUpdate == null){
                outcomeToUpdate = {
                    outcomeId: outcome.id
                }
                oppToSave.PRESCALE__C_playbook_outcome_answers.push(outcomeToUpdate);
            }

            outcomeToUpdate.value = answer.value;
            outcomeToUpdate.percentage = answer.percentage;
            outcomeToUpdate.description = answer.description;

            //CALCULATE HEALTH

        }
        return oppToSave;
    }

    static getOutcomesToComplete(playbook, opportunity){
        
        const outcomes = [];
        
        if(playbook != null && opportunity != null){
            const play = this.getMatchingPlay(playbook, opportunity);
            const currentOpportunityStageIndex = playbook.stages.findIndex(stage=> stage.label === opportunity[playbook.dataPipeline.stageField]);
            playbook.stages.forEach((stage, stageIndex)=>{
                play.outcomes.filter(po=>po.stageId === stage.id).forEach(po=>{
                    const outcome = playbook.outcomes.find(o=>o.id === po.outcomeId);
                    const outcomeResult = opportunity.PRESCALE__C_playbook_outcome_answers != null ? opportunity.PRESCALE__C_playbook_outcome_answers.find(outcomeAnswer=> outcomeAnswer.outcomeId === outcome.id) : null;
                    const outcomeCompleted = outcomeResult != null && outcomeResult.value !== "";
                    const isRequired = stageIndex < currentOpportunityStageIndex && !outcomeCompleted;
                    const isCurrentOutcomeToComplete = stageIndex === (currentOpportunityStageIndex > 0 ? currentOpportunityStageIndex -1 : currentOpportunityStageIndex);
                    outcomes.push(
                        {
                            outcome: outcome,
                            opportunity: opportunity,
                            answer: outcomeResult,
                            isCompleted: outcomeCompleted,
                            isCurrent: isCurrentOutcomeToComplete,
                            isRequired: isRequired
                        }
                    );
                });
            });
        }
        return outcomes;
    }

    static getMatchingPlay(playbook, opportunity){
        let assignedPlayId = null;
        if(opportunity.PRESCALE__C_assigned_play){
            assignedPlayId = opportunity.PRESCALE__C_assigned_play[playbook.id];
        }
        if(!assignedPlayId){
            return playbook.plays.find(play=> play.isDefault);
        }
        else{
            return playbook.plays.find(play=> play.id === assignedPlayId);
        }
    }

    static getMatchingSequence(playbook, opportunity){
        let foundSequence = null;
        // playbook.sequences.forEach(sequence => {
        //     let matchesCriterias = true;
        //     sequence.criterias.forEach(criteria => {
        //         matchesCriterias = matchesCriterias && this.opportunityMatchesCriteria(criteria, opportunity);
        //     });
        //     if(matchesCriterias){
        //         foundSequence = sequence;
        //     }
        // });
        return foundSequence;
    }

    static opportunityMatchesCriteria(criteria, opportunity){
        switch(criteria.criteriaKey){
            default:
                return opportunity[criteria.field] === criteria.value;
        }
    }

    static getOpportunityCurrentStage(playbook, opportunity){
        let currentStage = null;
        const stageField = playbook.dataPipeline?.stageField ?? "PRESCALE__C_stage";

        if(opportunity != null && opportunity[stageField]){
            currentStage = playbook.stages.find(stage=>stage.label === opportunity[stageField]);
        }
        return currentStage;
    }

    static getOpportunityAvailableTemplates(playbook, opportunity){
        const templates = [];
        const currentStage = this.getOpportunityCurrentStage(playbook, opportunity);
        const play = this.getMatchingPlay(playbook, opportunity);
        if(currentStage){
            play.actions.filter(pa => pa.stageId === currentStage.id).forEach(pa => {
                let templateId = pa.templateId;
                if(!templateId){
                    templateId = playbook.actions.find(a=>a.id === pa.actionId)?.templateId;
                }
                if(templateId){
                    const template = playbook.templates.find( t => t.id === templateId);
                    if(template){
                        templates.push(template);
                    }
                }
            });
        }
        return templates;
    }

    static getOpportunityAvailableActions(playbook, opportunity){
        const actions = [];
        const currentStage = this.getOpportunityCurrentStage(playbook, opportunity);
        const play = this.getMatchingPlay(playbook, opportunity);
        
        playbook.actions.forEach(action => {
            let isSuggestedAction = false;
            if(currentStage){
                isSuggestedAction = play.actions.findIndex(pa => pa.actionId === action.id && pa.stageId === currentStage.id) > -1;
            }
            actions.push({
                id: action.id,
                label: action.label,
                defaultDurationHs: action.defaultDurationHs,
                isSuggested: isSuggestedAction
            });
        });

        return actions;
    }

    static getCardTitle(playbook, card){
        const defaultCardTitleExpression = PlaybookUtils.getDataPipelinesConfigById(playbook.dataPipeline.type)?.defaultCardTitleExpression;
        if(defaultCardTitleExpression){
            return eval(defaultCardTitleExpression);
        }
        else{
            return card.name ? card.name : ( card.label ? card.label : ( card.title ? card.title : "No title found" ) );
        }
    }
}