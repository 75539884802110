import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PlayStageOutcome } from './PlayStageOutcome';

const NEW_OUTCOME = {label: "", description: ""};

export const PlayStageOutcomes = (props) => {
    
    
    const [editingOutcome, setEditingOutcome] = useState(NEW_OUTCOME);
    const [showForm, setShowForm] = useState(false);
    const [formCrlKeyPressed, setFormCrlKeyPressed] = useState(false);
    const optionsMenu = useRef(null);
    
    //////////////////////////////////////////////
    //// METHODS
    //////////////////////////////////////////////

    function resetForm(){
        setEditingOutcome(NEW_OUTCOME);
        setFormCrlKeyPressed(false);
    }

    function closeNewForm(){
        resetForm();
        setShowForm(false);
    }

    function isOutcomeValid(){
        return editingOutcome.label !== "";
    }

    function saveOutcome(){
        if(isOutcomeValid()){
            if(props.onOutcomeSave){
                props.onOutcomeSave(editingOutcome, props.stage);
            }
            closeNewForm();
        }
    }

    function removeOutcome(outcome){
        if(props.onRemoveOutcome){
            props.onRemoveOutcome(outcome, props.stage);
        }
    }

    function setEditingOutcomeLabel(label){
        const outcome = {...editingOutcome}
        outcome.label = label;
        setEditingOutcome(outcome);
    }

    function setEditingOutcomeDescription(description){
        const outcome = {...editingOutcome}
        outcome.description = description;
        setEditingOutcome(outcome);
    }

    function getActionOptions(outcome){
        return  [
                    { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => { handleOnEditOutcome(outcome)} },
                    { label: 'Remove', icon: 'pi pi-fw pi-trash', command: () => {handleOnRemoveOutcome(outcome) } }, 
                ]
    }

    function isOutcomeInSequence(outcome){
        let inSequence = false;
        if(props.sequence){
            const found = props.sequence.playbookOutcomes.find(o => o === outcome.id);
            inSequence = found != null;
        }
        return inSequence;
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////
    
    function handleNewOutcomeClick(){
        setShowForm(true);
    }

    function handleCloseNewForm(){
        closeNewForm();
    }

    function handleSaveOutcomeClick(){
        saveOutcome();
    }

    function handleOnInputKeyDown(e){
        if(e.key === 'Enter' && formCrlKeyPressed){
            saveOutcome();
        }

        ///THIS SHOULD BE LAST
        if(e.key === 'Meta' || e.key === 'Control'){
            setFormCrlKeyPressed(true);
        }
        else{
            setFormCrlKeyPressed(false);
        }
    }

    function handleOnRemoveOutcome(outcome){
        removeOutcome(outcome);
    }

    function handleOnEditOutcome(outcome){
        if(props.allowEdit){
            setEditingOutcome(outcome);
            setShowForm(true);
        }
    }

    function handleOutcomeClick(outcome){
        if(props.onOutcomeClick){
            props.onOutcomeClick(outcome);
        }
    }
    
    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////
    function renderForm(){
        const formFooter = (
            <div>
                <Button label="Cancel" className="p-button-outlined" icon="pi pi-times" onClick={handleCloseNewForm} />
                <Button label="Save" disabled={!isOutcomeValid()} icon="pi pi-check" onClick={handleSaveOutcomeClick} />
            </div>
        );
        return  <Dialog header={`New outcome`} visible={showForm} style={{ width: '40vw' }} footer={formFooter} onHide={handleCloseNewForm}>
                    <div className="p-field">
                        <InputText value={editingOutcome.label} onChange={(e) => setEditingOutcomeLabel(e.target.value)} onKeyDown={handleOnInputKeyDown} autoFocus={true} placeholder="Outcome label" style={{ width: '100%' }}/>
                    </div>
                    <div className="p-field">
                        <InputTextarea value={editingOutcome.description} onChange={(e) => setEditingOutcomeDescription(e.target.value)}  onKeyDown={handleOnInputKeyDown} placeholder="Outcome description" rows={5} style={{ width: '100%' }} />
                    </div>
                </Dialog>
    }

    function renderOutcome(outcome){
        const isInSequence = isOutcomeInSequence(outcome);
        return <PlayStageOutcome key={outcome.id} isInSequence={isInSequence} outcome={outcome} allowEdit={props.allowEdit} onEditOutcome={handleOnEditOutcome} onOutcomeClick={handleOutcomeClick} onRemoveOutcome={handleOnRemoveOutcome} />;
    }

    return  <td className={classNames("outcome-cell", props.outcomes.length === 0?"empty":"")} >
                {renderForm()}
                {
                    props.outcomes.map(outcome=>{
                        return renderOutcome(outcome);
                    })
                }
                { 
                    props.allowEdit && 
                    <div className="new-outcome" onClick={handleNewOutcomeClick}>
                        <span className="pi pi-plus-circle" style={{'fontSize': '2em'}}/>
                    </div>
                }
            </td>;
}