import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ActionUtils } from '../../../Utils/ActionUtils';
import { InputMask } from 'primereact/inputmask';
import { PlaybookTemplateForm } from './PlaybookTemplateForm';

export const PlaybookActionSettingsForm = (props) => {

    const [label, setLabel] = useState(props.action?.label);
    const [description, setDescription] = useState(props.action?.description);
    const [defaultType, setDefaultType] = useState(props.action?.defaultType);
    const [defaultDurationHs, setDefaultDurationHs] = useState(props.action?.defaultDurationHs);
    const [templateId, setTemplateId] = useState(props.action?.templateId);
    const [isSaving, setIsSaving] = useState(false);
    const [addingNewTemplate, setAddingNewTemplate] = useState(false);

    useEffect(()=>{
        setLabel(getLabel());
        setDescription(getDescription());
        setDefaultType(getDefaultType());
        setDefaultDurationHs(getDefaultDurationHs());
        setTemplateId(getTemplateId());
        setAddingNewTemplate(false);
    }, [props.action, props.playAction]);

    function getLabel(){
        if(props.playAction && props.playAction.label && props.playAction.label != null){
            return props.playAction.label;
        }
        return props.action?.label;
    }

    function getDescription(){
        if(props.playAction && props.playAction.description && props.playAction.description != null){
            return props.playAction.description;
        }
        return props.action?.description;
    }

    function getDefaultType(){
        if(props.playAction && props.playAction.defaultType && props.playAction.defaultType != null){
            return props.playAction.defaultType;
        }
        return props.action?.defaultType;
    }

    function getDefaultDurationHs(){
        if(props.playAction && props.playAction.defaultDurationHs && props.playAction.defaultDurationHs != null){
            return props.playAction.defaultDurationHs;
        }
        return props.action?.defaultDurationHs;
    }

    function getTemplateId(){
        if(props.playAction && props.playAction.templateId && props.playAction.templateId != null){
            return props.playAction.templateId;
        }
        return props.action?.templateId;
    }

    function getIsOverridingAction(){
        let overriding = false;
        if(props.playAction && props.play){
            overriding = !props.play.isDefault;
        }
        return overriding;
    }

    function resetState(){
        setIsSaving(false);
    }

    function isFormValid(){
        return label !== "" && label !== null;
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnLabelChanged(e){
        setLabel(e.target.value);
    }

    function handleOnDefaultTypeChanged(e){
        setDefaultType(e.value);
    }

    function handleOnDescriptionChanged(e){
        setDescription(e.target.value);
    }

    function handleOnTemplateChanged(e){
        setTemplateId(e.value);
    }

    function handleOnDefaultDurationChanged(e){
        setDefaultDurationHs(ActionUtils.convertDurationToHsNumber(e.value));
    }

    function handleSaveClick(){
        setIsSaving(true);
        let action = null;
        let playAction = null;
        if(getIsOverridingAction()){
            playAction = {...props.playAction}
            playAction.label = label !== props.action.label ? label : null;
            playAction.description = description !== props.action.description ? description : null;
            playAction.defaultDurationHs = defaultDurationHs !== props.action.defaultDurationHs ? defaultDurationHs : null;
            playAction.defaultType = defaultType !== props.action.defaultType ? defaultType : null;
            playAction.templateId = templateId !== props.action.templateId ? templateId : null;
        }
        else{
            action = {...props.action}
            action.label = label;
            action.description = description;
            action.defaultDurationHs = defaultDurationHs;
            action.defaultType = defaultType;
            action.templateId = templateId;
        }
        if(props.onActionChange){
            props.onActionChange(action, playAction);
        }
        resetState();
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
        resetState();
    }

    function handleOnNewTemplateSaved(template){
        if(props.onNewTemplate){
            props.onNewTemplate(template);
            setTemplateId(template.id);
        }
        setAddingNewTemplate(false);
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderFooter(){
        return  <div>
                    <Button label="Cancel" className="p-button-outlined" icon="pi pi-times" onClick={onCancel} />
                    <Button label={isSaving?"Saving...":"Save"} loading={isSaving} disabled={!isFormValid()} icon="pi pi-check" onClick={handleSaveClick} />
                </div>;
    }

    function renderLabel(){
        return  <>
                    <label htmlFor="label">Name</label>
                    <InputText disabled={getIsOverridingAction()} id="label" value={label} onChange={handleOnLabelChanged} />
                </>;
    }

    function renderDefaultType(){
        if(getIsOverridingAction()){
            const different = defaultType != props.action.defaultType;
            return  <>
                        <label htmlFor="type">Default Type</label>
                        <span className="p-float-label p-input-icon-right">
                            <Dropdown showClear={true} id="type" value={defaultType} onChange={handleOnDefaultTypeChanged} options={ActionUtils.getActionTypesOptions()}></Dropdown>
                            {different && <label htmlFor="type">{props.action.defaultType}</label>}
                        </span>
                    </>;
        }
        else{
            return  <>
                        <label htmlFor="type">Default Type</label>
                        <Dropdown showClear={true} id="type" value={defaultType} onChange={handleOnDefaultTypeChanged} options={ActionUtils.getActionTypesOptions()} placeholder="Select a default type"></Dropdown>
                    </>
        }
    }

    function renderDefaultDurationHs(){
        if(getIsOverridingAction()){
            const different = defaultDurationHs != props.action.defaultDurationHs;
            return  <>
                        <label htmlFor="duration">Default Duration</label>
                        <span className="p-float-label p-input-icon-right">
                            {different && <i className="pi pi-fw pi-undo" onClick={()=>{ setDefaultDurationHs(props.action.defaultDurationHs) }} />}
                            <InputMask id="duration" slotChar="__:__ hs" mask="99:99 hs" autoClear="false" maxlength="4" value={ ActionUtils.convertHsToDurationString(defaultDurationHs)} onChange={handleOnDefaultDurationChanged}></InputMask>
                            {different && <label htmlFor="duration">{ActionUtils.convertHsToDurationString(props.action.defaultDurationHs)}</label>}
                        </span>
                    </>
        }
        else{
            return  <>
                        <label htmlFor="duration">Default Duration</label>
                        <InputMask id="duration" slotChar="__:__ hs" mask="99:99 hs" autoClear="false" maxlength="4" value={ ActionUtils.convertHsToDurationString(defaultDurationHs)} onChange={handleOnDefaultDurationChanged}></InputMask>
                    </>
        }
    }

    function renderTemplate(){
        if(getIsOverridingAction()){
            const different = templateId != props.action.templateId;
            return  <>
                        <label htmlFor="template">Template</label>
                        <span className="p-float-label">
                            <Dropdown id="template" filter showClear={true} value={templateId} onChange={handleOnTemplateChanged} options={props.playbook.templates} optionValue="id" optionLabel="label"></Dropdown>
                            {different && <label htmlFor="template">{props.playbook.templates.find(t=>t.id === props.action.templateId).label}</label>}
                        </span>
                    </>
        }
        else{
            return  <>
                        <label htmlFor="template">Template</label>
                        <Dropdown id="template" filter showClear={true} value={templateId} onChange={handleOnTemplateChanged} options={props.playbook.templates} optionValue="id" optionLabel="label" placeholder="Select a template from the list"></Dropdown>
                    </>
        }
    }

    function renderDescription(){
        return  <>
                    <label htmlFor="activity-description">Description</label>
                    <InputTextarea disabled={getIsOverridingAction()} value={description} onChange={handleOnDescriptionChanged} id="activity-description" rows="3" />
                </>;
    }

    return  <Dialog className="action-settings-popup" onHide={onCancel} header="Action Settings" visible={props.visible} footer={renderFooter()} style={{ width: '40vw' }}>
                <PlaybookTemplateForm visible={addingNewTemplate} onSave={handleOnNewTemplateSaved} onCancel={()=>{setAddingNewTemplate(false)}} />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        {renderLabel()}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        {renderDefaultType()}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        {renderDefaultDurationHs()}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        {renderTemplate()}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="">&nbsp;</label>
                        <Button label={"New template"} className="p-button-secondary p-button-text" icon="pi pi-plus" onClick={()=>{setAddingNewTemplate(true)}} />
                    </div>
                    <div className="p-field p-col-12">
                        {renderDescription()}
                    </div>
                </div>
            </Dialog>
}