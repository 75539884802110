import React, { useState, useEffect } from 'react';
import { KanbanService } from '../../services/KanbanService';
import { DataSourceServiceFactory } from '../../services/DataSourceServiceFactory';
import { PlaybookService } from '../../services/PlaybookService';
import { POPSettingsService } from '../../services/POPSettingsService';
import { PlaybookUtils } from '../../Utils/PlaybookUtils';
import { HitListCalendar } from './Components/HitListCalendar';
import { MotionBoard } from './Components/MotionBoard';
import { OutcomesPopupEditor } from './Components/OutcomesPopupEditor';
import { POPHeader } from './Components/POPHeader';
import { POPMetrics } from './Components/POPMetrics';
import { OpportunitiesContextualMenu } from './Components/OpportunitiesContextualMenu';
import { Message } from 'primereact/message';
import { PlaysSelector } from './Components/PlaysSelector';
import { useParams } from 'react-router-dom';

export const PopPage = () => {
    
    /**
     * SETUP
     */
    const { view } = useParams();
    const [playbooks, setPlaybooks] = useState([]);
    const [currentPlaybook, setCurrentPlaybook] = useState(null);
    const [currentPlay, setCurrentPlay] = useState(null);
    const [popSettings, setPOPSettings] = useState(null);
    const [boards, setBoards] = useState(null);
    const [currentBoard, setCurrentBoard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataSourceList, setDataSourceList] = useState(null);
    const [currentMode, setCurrentMode] = useState( "kanban");
    const [showOutcomesForm, setShowOutcomesForm] = useState(false);
    const [dataItemToUpdateOutcomes, setDataItemToUpdateOutcomes] = useState(null);
    const [showNoPublishedPlaybooksMessage,setShowNoPublishedPlaybooksMessage] = useState(false);
    const [playsFiltered, setPlaysFiltered] = useState([]);

    useEffect(()=>{
        if(view){
            setCurrentBoard(view);
        }
    }, view);

    useEffect(() => {
        const kanbanService = new KanbanService();
        const playbookService = new PlaybookService();
        Promise.all([kanbanService.getBoards(), POPSettingsService.loadSettings(), playbookService.getPlaybooks()]).then(
            (result) => {
                const boardsResult = result[0].data;
                const settings = result[1];
                const playbooksResult = (result[2]).filter(playbook => playbook.published);
                if(playbooksResult && playbooksResult.length > 0){
                    setPOPSettings(settings);
                    if(view){
                        setCurrentMode(view);    
                    }
                    else{
                        setCurrentMode(settings.currentMode);
                    }
                    setBoards(boardsResult);
                    const currentBoard = boardsResult.find(board=> board.id === settings.kanbanSettings.currentBoard)
                    setCurrentBoard(currentBoard);
                    setPlaybooks(playbooksResult);
                    const lastActivePlaybook = playbooksResult.find(p => p.id === settings?.runner?.lastActivePlaybook);
                    let playbookToLoad = lastActivePlaybook;
                    if(lastActivePlaybook){
                        setCurrentPlaybook(lastActivePlaybook);
                        const lastActivePlay = lastActivePlaybook.plays.find(p => p.id === settings?.runner?.lastActivePlay);
                        setCurrentPlay( lastActivePlay ? lastActivePlay : lastActivePlaybook.plays.find(p => p.isDefault));
                        DataSourceServiceFactory.getData(lastActivePlaybook).then((data) => {
                            setDataSourceList(data);
                        });
                    }
                    else{
                        playbookToLoad = playbooksResult[0];
                        setCurrentPlaybook(playbooksResult[0]);
                        setCurrentPlay(playbooksResult[0].plays.find(p => p.isDefault));
                    }
                    DataSourceServiceFactory.getData(playbookToLoad).then((data) => {
                        setDataSourceList(data);
                    });
                }
                else{
                    setShowNoPublishedPlaybooksMessage(true);
                }
                setLoading(false);
            }
        );
    }, []);

    function startPlaybookStageChange(item, stageFrom, stageTo){
        setDataItemToUpdateOutcomes(item);
        setShowOutcomesForm(PlaybookUtils.mustUpdateOutcomesAfterMovingStage(currentPlaybook, item, stageFrom, stageTo));
    }

    function updateEntity(entity){
        DataSourceServiceFactory.updateSingleEntity(currentPlaybook, entity).then((entities)=>{
            setDataSourceList(entities);
        });
    }

    function togglePlayFilter(play){
        const foundIndex = playsFiltered.findIndex( p => p === play.id);
        const clonedFilters = [...playsFiltered];
        if(foundIndex > -1){
            clonedFilters.splice(foundIndex, 1);
        }
        else{
            clonedFilters.push(play.id);
        }
        setPlaysFiltered(clonedFilters);
    }

    function getFilteredDataList(){
        const filteredList = dataSourceList?.data?.
        filter(e => !e.PRESCALE__C_assigned_play || playsFiltered.findIndex( p=> p === e.PRESCALE__C_assigned_play[currentPlaybook.id]) === -1);

        const newdata = {...dataSourceList};
        newdata.data = filteredList;
        return newdata;
    }

    /**
     * HANDLERS
     */

    function handleBoardChanged(board){
        setCurrentBoard(board);
        let newSettgins = {...popSettings};
        newSettgins.kanbanSettings.currentBoard = board.id;
        setPOPSettings(newSettgins);
        POPSettingsService.saveSettings(newSettgins);
    }

    function handleModeChanged(mode){
        setCurrentMode(mode);
        let newSettgins = {...popSettings};
        newSettgins.currentMode = mode;
        setPOPSettings(newSettgins);
        POPSettingsService.saveSettings(newSettgins);
    }

    function handleKanbanUpdated(newKanban, board){
        DataSourceServiceFactory.updateEntitiesByKanban(currentPlaybook, newKanban, board);
    }

    function handleDataItemUpdated(item, fieldUpdates){
        if(item != null && fieldUpdates.length > 0){
            let fieldsUpdated = false;
            fieldUpdates.forEach(update => {
                if(update.field === currentPlaybook.dataPipeline.stageField){
                    startPlaybookStageChange(item, update.from, update.to);
                }
                else{
                    item[update.field] = update.to;
                    fieldsUpdated = true;
                }
            });
            if(fieldsUpdated){
                updateEntity(item);
            }
        }
    }

    function handleOnOutcomeSaved(entity){
        if(entity != null){
            setShowOutcomesForm(false);
            setDataItemToUpdateOutcomes(null);
            updateEntity(entity);
        }
    }

    function handleOnPlaybookChanged(playbook){
        setCurrentPlaybook(playbook);
        const defaultPlay = playbook.plays.find(play => play.isDefault);
        setCurrentPlay(defaultPlay);
        const newSettings = {...popSettings};
        newSettings.runner.lastActivePlaybook = playbook.id;
        newSettings.runner.lastActivePlay = defaultPlay.id;

        let newBoard = boards.find(b => b.id === playbook.id);
        setCurrentBoard(newBoard);
        let newSettgins = {...popSettings};
        newSettgins.kanbanSettings.currentBoard = newBoard.id;

        DataSourceServiceFactory.getData(playbook).then((data) => {
            setDataSourceList(data);
        });

        POPSettingsService.saveSettings(newSettings);
    }

    function handleOnPlayChanged(play){
        setCurrentPlay(play);
        const newSettings = {...popSettings};
        newSettings.runner.lastActivePlay = play.id;
        POPSettingsService.saveSettings(newSettings);
    }

    function handleOnPlaySelected(play){
        togglePlayFilter(play);
    }
    
    /**
     * RENDER
     */

    if(loading){
        return <div style={{"width": "100%", "textAlign": "center", "marginTop": "20%"}} ><i className="pi pi-spin pi-spinner" style={{'fontSize': '4em'}}></i> <h2>Loading Playbooks...</h2></div>;
    }

    if(showNoPublishedPlaybooksMessage){
        return <div style={{"width": "100%"}} ><Message severity="warn" text="No published playbooks found yet. You need to publish a Playbook to the runner to be able to execute a play." style={{"width": "100%", "textAlign": "left"}}></Message></div>
    }

    let content = <div style={{"width": "100%", "textAlign": "center", "marginTop": "20%"}} ><i className="pi pi-spin pi-spinner" style={{'fontSize': '4em'}}></i> <h2>Loading Data...</h2></div>;
    let contextualMenu = <OpportunitiesContextualMenu playbook={currentPlaybook} boards={boards} currentBoard={currentBoard} opps={dataSourceList} onBoardChange={handleBoardChanged}  />;
    if(dataSourceList != null){
        const filteredList = getFilteredDataList();
        switch(currentMode){
            case "kanban":
                content = <MotionBoard playbook={currentPlaybook} currentBoard={currentBoard} dataSourceList={filteredList} onDataItemUpdated={handleDataItemUpdated} onKanbanDataListUpdated={handleKanbanUpdated}/>;
                break;
            case "hitlist":
                content = <HitListCalendar playbook={currentPlaybook} dataSourceList={filteredList} />
                break;
            case "metrics":
                content = <POPMetrics playbook={currentPlaybook} dataSourceList={filteredList} currentBoard={currentBoard} />
                break;
            default:
                content = null;
                break;
        }
    }

    function renderOutcomes(){
        return !showOutcomesForm ? null : <OutcomesPopupEditor playbook={currentPlaybook} dataItem={dataItemToUpdateOutcomes} onSave={handleOnOutcomeSaved} onHide={()=>{setShowOutcomesForm(false)}} />;
    }
    
    return (
        <div className="pop">
            <POPHeader playbooks={playbooks} playbook={currentPlaybook} play={currentPlay} currentBoard={currentBoard} boards={boards} onPlaybookChange={handleOnPlaybookChanged} onPlayChange={handleOnPlayChanged} onModeChange={handleModeChanged} mode={currentMode} contextualMenu={contextualMenu} />
            <PlaysSelector filteredPlays={playsFiltered} onPlaySelected={handleOnPlaySelected} playbook={currentPlaybook} dataSourceList={dataSourceList} />
            {content}
            {renderOutcomes()}
        </div>
    );
}