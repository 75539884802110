import axios from 'axios';
import { PlaybookUtils } from '../Utils/PlaybookUtils';
import { PlaybookService } from './PlaybookService';

export class KanbanService {

    getBoards() {
        return new Promise((resolution)=>{
            axios.get('assets/demo/data/kanban-boards.json').then(res => {
                let boards = res.data;
                (new PlaybookService()).getPlaybooks().then(playbooks => {
                    if(playbooks != null){
                        playbooks.filter(p=>p.published).forEach(playbook=>{
                            const columns = [];
                            if(playbook != null){
                                playbook.stages.forEach(stage=>{
                                    columns.push(
                                        {
                                            label: stage.label,
                                            totalizerKey: playbook.dataPipeline?.type === "opportunities" ? "health_percentage" : "count",
                                            criteriaValue: stage.label
                                        }
                                    );
                                });
                                boards.data.push(
                                    {
                                        "id": playbook.id,
                                        "label": `${playbook.name.replace("Playbook", "").replace("playbook", "")} Board`,
                                        "playbookId": playbook.id,
                                        "editable": true,
                                        "showOrphanOpps": true,
                                        "defaultSortBy": "PRESCALE__C_health_percentage",
                                        "defaultSortDesc": true,
                                        "criteriaField": playbook.dataPipeline?.stageField ?? "PRESCALE__C_stage",
                                        "cardFields": PlaybookUtils.getDataPipelinesConfigById(playbook.dataPipeline.type).defaultCardFields,
                                        "columns": columns
                                    }
                                );

                            }
    
                        });
                    }
                    resolution(boards);
                });
            });
        })
    }

}