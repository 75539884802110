import React from 'react';

export const PhoneField = (props) => {

    const icon = (props.icon)?props.icon:<span className="pi pi-phone"></span>;

    return( 
        <span className={props.className}>
            {icon} {props.value}
        </span>
    );
}