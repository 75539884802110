import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { TieredMenu } from 'primereact/tieredmenu';
import { confirmDialog } from 'primereact/confirmdialog';

export const PlaybookRole = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newLabel, setNewLabel] = useState(props.role.label);
    const menu = useRef(null);

    const confirmRemove = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => raiseOnRemoveRole()
        });
    }

    //////////////////////////////////////////////
    //// METHODS
    //////////////////////////////////////////////

    function getMenuOptions(){
        const options = [
            {
                label: 'Rename',
                icon: 'pi pi-pencil',
                disabled: !props.play.isDefault,
                command: ()=>{handleOnEdit()}
            },
            {
                label: (props.play.isDefault)?'Remove':'Remove from play',
                icon: 'pi pi-trash',
                command: confirmRemove
            },
            {
                label: 'Add new above',
                icon: 'pi pi-angle-up',
                disabled: !props.play.isDefault,
                command: ()=>{raiseOnAddNewRole("above")}
            },
            {
                label: 'Add new below',
                icon: 'pi pi-angle-down',
                disabled: !props.play.isDefault,
                command: ()=>{raiseOnAddNewRole("below")}
            }
        ];
        return options;
    }

    function raiseOnLabelUpdated(){
        if(props.onLabelChange){
            props.onLabelChange(props.role, newLabel);
        }
    }

    function raiseOnRemoveRole(){
        toggleMenu();
        if(props.onRemove){
            props.onRemove(props.role);
        }
    }

    function raiseOnAddNewRole(direction){
        toggleMenu();
        if(props.onAddRole){
            props.onAddRole(direction, props.role);
        }
    }

    function cancelEdit(){
        setNewLabel(props.role.label);
        setIsEditing(false);
    }

    const toggleMenu = (event) => {
        menu.current.toggle(event);
    };

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnEdit(){
        setIsEditing(true);
        toggleMenu();
    }

    function handleOnBlur(){
        setIsEditing(false);
        raiseOnLabelUpdated();
    }

    function handleOnKeyDown(e){
        if(e.key === 'Escape'){
            cancelEdit();
        }
        if(e.key === 'Enter'){
            setIsEditing(false);
            raiseOnLabelUpdated();
        }
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderContent(){
        if(isEditing){
            return <InputText key={props.role.id} onBlur={handleOnBlur} onChange={(e)=>setNewLabel(e.target.value)} onKeyDown={handleOnKeyDown} autoFocus value={newLabel} />
        }
        return <div style={{fontSize: "16px", color: "white"}}>
                    <span className="pi pi-user" style={{fontSize: "20px"}}/> <br/>
                    {props.role.label}
                </div>;
    }

    return (
        <td className="role editable-cell" onDoubleClick={()=>{if(props.allowEdit)setIsEditing(true)}} key={`role-${props.role.id}`}>
            <TieredMenu ref={menu} model={getMenuOptions()} popup />
            {renderContent()}
            { props.allowEdit && <div className="conf" onClick={toggleMenu}><span className="pi pi-ellipsis-v"></span></div>}
        </td>
    );
}