import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { InboxService } from '../../services/InboxService'

export const InboxPage = () => {
    
    const [inbox, setInbox] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter1] = useState('');
    const [selectedItems, setSelectedItems] = useState(null);
    const contextMenu = useRef(null);

    useEffect(() => {
        const inboxService = new InboxService();
        inboxService.getInbox().then(data => {setInbox(data); setLoading(false)});
    }, []);

    const actionTemplate = () => <Button type="button" icon="pi pi-cog" className="p-button-secondary"></Button>;

    const titleTemplate = (data, props) => {
        let item = data[props.field];
        if(data.isNew){
            item = <b>{item}</b>
        }
        return (
            <>
                {item}
            </>
        );
    };

    const tableHeader = <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalFilter} onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Search" />
                        </span>;

    return (
        <div className="inbox p-grid">
            <div className="p-col-12">
                <div className="card">
                    <div className="card-header">
                        <h3><i className="pi pi-inbox"/> Inbox</h3>
                        <div>
                            {/* <Button type="button" icon="pi pi-cog" className="p-button-rounded p-button-text p-button-plain" ></Button>
                            <Button type="button" icon="pi pi-question" className="p-button-rounded p-button-text p-button-plain" ></Button> */}
                            <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => contextMenu.current.toggle(event)}></Button>
                            <Menu ref={contextMenu} popup model={[{ label: 'Settings', icon: 'pi pi-fw pi-cog' }, { label: 'Help', icon: 'pi pi-fw pi-question' }]}></Menu>
                        </div>
                    </div>
                    <DataTable value={inbox} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                        globalFilter={globalFilter} emptyMessage="Your inbox is empty" loading={loading}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                        <Column field="subject" header={tableHeader} body={titleTemplate} ></Column>
                        <Column field="date" headerStyle={{ width: '8rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'left', overflow: 'visible', justifyContent: 'center' }}></Column>
                        <Column headerStyle={{ width: '8rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }} body={actionTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );

}