import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ActionUtils } from '../../../Utils/ActionUtils';

export const PlaybookTemplateForm = (props)=>{

    const [label, setLabel] = useState();
    const [type, setType] = useState(ActionUtils.getTemplateTypesOptions()[0].value);
    const [link, setLink] = useState();
    const [isNew, setIsNew] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(()=>{
        if(props.template){
            setIsNew(props.template ? props.template.id != null : true);
            setLabel(props.template.label);
            setType(props.template.type);
            setLink(props.template.value);
        }
        else{
            setLabel("");
            setLink("");
        }
    }, [props.template]);

    function resetState(){
        setIsSaving(false);
    }

    function isFormValid(){
        return label !== "" && label != null && link !== "" && link != null;
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnLabelChanged(e){
        setLabel(e.target.value);
    }

    function handleOnTypeChanged(e){
        setType(e.value);
    }

    function handleOnLinkChanged(e){
        setLink(e.target.value);
    }

    function handleSaveClick(){
        setIsSaving(true);
        let template = {
            id: props.template?.id ?? `TMPL-${((new Date()).getTime())}`,
            label: label,
            type: type,
            value: link
        }
        if(props.onSave){
            props.onSave(template);
        }
        resetState();
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
        resetState();
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderFooter(){
        return  <div>
                    <Button label="Cancel" className="p-button-outlined" icon="pi pi-times" onClick={onCancel} />
                    <Button label={isSaving ? "Saving...":"Save" } loading={isSaving} disabled={!isFormValid()} icon="pi pi-check" onClick={handleSaveClick} />
                </div>;
    }

    return <Dialog visible={props.visible} header={isNew ? "New template" : "Edit template"} onHide={onCancel} footer={renderFooter()} style={{ width: '40vw' }}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="template-label">Name</label>
                        <InputText autoFocus={true} id="template-label" value={label} onChange={handleOnLabelChanged} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="template-type">Type</label>
                        <Dropdown id="type" value={type} onChange={handleOnTypeChanged} options={ActionUtils.getTemplateTypesOptions()} placeholder="Select a template type"></Dropdown>
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="template-link">Link</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="pi pi-globe"></i></span>
                            <InputText id="template-link" placeholder="Link" value={link} onChange={handleOnLinkChanged} />
                        </div>
                    </div>
                </div>
            </Dialog>
}