import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useParams } from 'react-router-dom'
import { PlaybookService } from '../../services/PlaybookService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlaybookAction } from './Components/PlaybookAction';
import { PlaybookStageHeader } from './Components/PlaybookStageHeader';
import { PlaybookRole } from './Components/PlaybookRole';
import { PlayStageOutcomes } from './Components/PlayStageOutcomes';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { Sidebar } from 'primereact/sidebar';
import { PlaybookUtils } from '../../Utils/PlaybookUtils';
import { PlaybookNewActionRoleInput } from './Components/PlaybookNewActionRoleInput';
import { SequencesMenu } from './Components/SequencesMenu';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { PlaybookTemplatesSelector } from './Components/PlaybookTemplatesSelector';
import { PublishTemplateForm } from './Components/PublishTemplateForm';
import { DataPipelineConfig } from './Components/DataPipelineConfig';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { PlaysManagementButton } from './Components/PlaysManagementButton';
import { PlaybookTemplateItem } from './Components/PlaybookTemplateItem';
import { PlaybookTemplateForm } from './Components/PlaybookTemplateForm';
import { Menu } from 'primereact/menu';
import { SharePlaybook } from './Components/SharePlaybook';

export const PlaybookPage = (props) => {
    const [playbooks, setPlaybooks] = useState([]);
    const [currentPlaybook, setCurrentPlaybookState] = useState(null);
    const [currentPlay, setCurrentPlayState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [configPanelVisible, setConfigPanelVisible] = useState(false);
    const [sidebarContent, setSidebarContent] = useState("");
    const [editingPlay, setEditingPlay] = useState(PlaybookUtils.getLastViewMode() ?? true);
    const [showingSequence, setShowingSequence] = useState(null);
    const [isEditingSequence, setIsEditingSequence] = useState(false);
    const [playbookTitle, setPlaybookTitle] = useState("");
    const [playbookPopupTemplatesVisible, setPlaybookPopupTemplatesVisible] = useState(false);
    const [publishTemplatePopupVisible, setPublishTemplatePopupVisible] = useState(false);
    const [playbookPublishDialogVisible, setPlaybookPublishDialogVisible] = useState(false);
    const [isPublishingPlaybook, setIsPublishingPlaybook] = useState(false);
    const [showNewTemplate, setShowNewTemplate] = useState(false);
    const [showSharePlaybook, setShowSharePlaybook] = useState(false);
    const viewModeMenu =  useRef(null);
    const { id } = useParams();
    
    useEffect(() => {
        if(currentPlaybook === null || currentPlaybook.id !== id){
            const playbookService = new PlaybookService();
            playbookService.getPlaybooks().then(playbookResult => {
                setPlaybooks(playbookResult);
                if(!id){
                    const lastId = playbookService.getLastPlaybookId();
                    const lastPlaybook = playbookResult.find(p=> p.id === lastId);
                    if(lastPlaybook){
                        openPlaybook(lastId);
                    }
                    else{
                        openPlaybook(playbookResult[0].id);
                        return;
                    }
                }
                else{
                    const playbook = playbookResult.find(p=> p.id === id);
                    if(!playbook){
                        props.history.push('/');
                    }
                    else{
                        playbookService.saveLastPlaybookId(id);
                        setCurrentPlayState(playbook.plays.find( ( p )=> p.isDefault ));
                        setCurrentPlaybookState(playbook); 
                        setLoading(false);
                    }
                }
            });
        }
    }, [id]);

    useEffect(()=>{
        setPlaybookTitle(currentPlaybook?.name);
        let playToSelect = currentPlaybook?.plays.find( p=> p.id === currentPlay?.id );
        if(!playToSelect){
            playToSelect = currentPlaybook?.plays.find( ( p )=> p.isDefault );
        }
        setCurrentPlayState(playToSelect);
    }, [currentPlaybook]);

    useEffect(()=>{
        PlaybookUtils.saveLastViewMode(editingPlay);
    }, [editingPlay]);

    function  getViewModeMenuOptions(){
        return [
            {
                label: 'Editing',
                icon: 'pi pi-fw pi-pencil',
                command: ()=> { setEditingPlay(true) }
            },
            {
                label: 'Viewing',
                icon: 'pi pi-fw pi-eye',
                command: ()=> { setEditingPlay(false) }
            },
            {
                separator: true
            },
            {
                label: 'Publish',
                icon: 'pi pi-fw pi-upload',
                disabled: isPublishingPlaybook,
                command: ()=>{ handleOnPublishPlaybookClicked(); }
            }
        ]
    }

    function onDragEnd(result) {
        
        if(result.destination == null){
            return;
        }

        let sourceIds = result.draggableId.split("|");
        const sourceFromActionsList = (sourceIds.length < 3);
        let sourceActionId = null;
        let sourceRoleId = null;
        let sourceStageId = null;
        
        //SET SOURCES
        if(!sourceFromActionsList){
            sourceRoleId = sourceIds[0];
            sourceStageId = sourceIds[1];
            sourceActionId = sourceIds[2];
        }
        else{
            sourceActionId = sourceIds[1];
        }
        //SET DESTINATIONS
        let destinationIds = result.destination.droppableId.split("|");
        const destinationToActionList = destinationIds.length < 2;
        let destinationRoleId = null;
        let destinationStageId = null;
        if(!destinationToActionList){
            destinationRoleId = destinationIds[0];
            destinationStageId = destinationIds[1];
        }
        
        //if SOURCE AND DESTINATION ARE THE SAME, DO NOTHING
        if((sourceFromActionsList && destinationToActionList) || (sourceRoleId===destinationRoleId && sourceStageId===destinationStageId)){
            reorderActionInPlay(sourceActionId, destinationStageId, destinationRoleId, result.destination.index);
            return;
        }

        if(!destinationToActionList){
            addActionToPlay(sourceActionId, destinationStageId, destinationRoleId, result.destination.index);
        }
        if(!sourceFromActionsList){
            removeActionFromPlay(sourceActionId, sourceStageId, sourceRoleId);
        }
    }

    function setPlaybook(updatedPlaybook){
        setCurrentPlaybookState(updatedPlaybook);
        savePlaybook(updatedPlaybook);
    }

    function savePlaybook(playbook=null){
        let playToSave = currentPlaybook;
        if(playbook != null){
            playToSave = playbook;
        }
        const playbookService = new PlaybookService();
        playbookService.getPlaybooks().then(playbookResult=>{
            const playIndex = playbookResult.findIndex(p => p.id === playToSave.id);
            if(playIndex >= 0){
                playbookResult[playIndex] = playToSave;
            }
            else{
                playbookResult.push(playToSave);
            }
            playbookService.savePlaybook(playbookResult);
            if(playToSave.id !== id){
                openPlaybook(playToSave.id);
            }
        });
    }

    function restorePlaybook(){
        
    }

    function createNewPlaybook(cloneCurrent=false, template=null){
        const from = cloneCurrent?currentPlaybook:template?.playbook;
        const newPlaybook = PlaybookUtils.generateNewPlaybook(from);
        setIsEditingSequence(false);
        setShowingSequence(null);
        setConfigPanelVisible(false);
        setSidebarContent("");
        setEditingPlay(true);
        savePlaybook(newPlaybook);
    }

    function openPlaybook(id){
        props.history.push(`/playbook/${id}`);
    }

    function removeCurrentPlaybook(){
        if(playbooks.length > 1){
            setLoading(true);
            const playbookService = new PlaybookService();
            playbookService.getPlaybooks().then(playbookResult=>{
                const newPlaybookList = playbookResult.filter(p => p.id !== currentPlaybook.id);
                playbookService.savePlaybook(newPlaybookList);
                openPlaybook(newPlaybookList[0].id);
            });
        }
    }

    function publishPlaybook(){
        setIsPublishingPlaybook(true);
        setTimeout(()=>{
            const clonedPlaybook = {...currentPlaybook};
            clonedPlaybook.published = true;
            setPlaybook(clonedPlaybook);
            savePlaybook(clonedPlaybook);
            setIsPublishingPlaybook(false);
        }, 1500);
    }

    //////////////////////////////////////////////
    //// SEQUENCE METHODS
    //////////////////////////////////////////////

    function saveSequence(sequence){
        let newPlay = {...currentPlaybook};
        const currentIndex = newPlay.sequences.findIndex(item => item.id === sequence.id);
        if(currentIndex >= 0){
            newPlay.sequences[currentIndex] = sequence;
        }
        else{
            sequence.id = "seq-" + (new Date()).getTime();
            newPlay.sequences.push(sequence);
        }
        setPlaybook(newPlay);
    }

    function deleteSequence(sequence){
        let newPlay = {...currentPlaybook};
        const currentIndex = newPlay.sequences.findIndex(item => item.id === sequence.id);
        if(currentIndex >= 0){
            newPlay.sequences.splice(currentIndex, 1);
        }
        setShowingSequence(null);
        setPlaybook(newPlay);
    }

    function sequenceActionSelected(stageAction){
        if(isEditingSequence && showingSequence){
            setShowingSequence(PlaybookUtils.toggleActionFromSequence(currentPlaybook, showingSequence, stageAction));
        }
    }

    function sequenceOutcomeSelected(outcome){
        if(isEditingSequence && showingSequence){
            setShowingSequence(PlaybookUtils.toggleOutcomeFromSequence(currentPlaybook, showingSequence, outcome));
        }
    }

    //////////////////////////////////////////////
    //// ROLE METHODS
    //////////////////////////////////////////////

    function updateRoleLabelTitle(roleToUpdate, newLabel){
        if(roleToUpdate && newLabel){
            const clonedPlaybook = {...currentPlaybook};
            clonedPlaybook.roles.forEach(role=>{
                if(role.id === roleToUpdate.id){
                    role.label = newLabel;
                    return;
                }
            });
            setPlaybook(clonedPlaybook);
        }
    }

    function removeRole(roleToRemove){
        if(roleToRemove){
            const clonedPlaybook = {...currentPlaybook};
            const play = clonedPlaybook.plays.find(play=>play.id === currentPlay.id);
            if(play.isDefault){
                //REMOVE THE ROLE FROM EVERYWHERE
                clonedPlaybook.roles = clonedPlaybook.roles.filter(role=> role.id !== roleToRemove.id);
                clonedPlaybook.plays.forEach( p => {
                    p.actions = p.actions.filter(pa=>pa.roleId !== roleToRemove.id);
                    p.roles = p.roles.filter( r => r.roleId !== roleToRemove.id );
                } );
            }
            else{
                play.actions = play.actions.filter(pa=>pa.roleId !== roleToRemove.id);
                play.roles = play.roles.filter( r => r.roleId !== roleToRemove.id );
            }
            setPlaybook(clonedPlaybook);
        }
    }
    
    function addNewRole(direction, refRole){
        const clonedPlaybook = {...currentPlaybook};
        let newIndex = clonedPlaybook.roles.length;
        const newRole = {
            "id": `ROLE-${new Date().getTime()}`,
            "label": "New Role"
        };
        if(refRole){
            const currentIndex = clonedPlaybook.roles.findIndex(item => item.id === refRole.id);
            if(direction === "below"){
                newIndex = currentIndex;
            }
            else{
                newIndex = currentIndex - 1;
            }
        }
        if(newIndex === clonedPlaybook.roles.length){
            clonedPlaybook.roles.push(newRole);
            const clonedPlay = clonedPlaybook.plays.find( p => p.id === currentPlay.id );
            clonedPlay.roles.push({roleId: newRole.id});
        }
        else{
            let newAdded = false;
            const newRoles = [];
            for(let i = 0; i < clonedPlaybook.roles.length; i++){
                if( i > newIndex && !newAdded){
                    newRoles.push(newRole);
                    newAdded = true;
                }
                newRoles.push(clonedPlaybook.roles[i]);
            }
            if(!newAdded){
                newRoles.push(newRole);
                newAdded = true;
            }
            clonedPlaybook.roles = newRoles;
            const clonedPlay = clonedPlaybook.plays.find( p => p.id === currentPlay.id );
            clonedPlay.roles = clonedPlaybook.roles.map( r => { return {roleId: r.id} } );
        }
        setPlaybook(clonedPlaybook);
    }

    //////////////////////////////////////////////
    //// OUTCOME METHODS
    //////////////////////////////////////////////

    function saveOutcomeToStage(toStage, outcomeToSave){
        const clonedPlaybook = {...currentPlaybook};
        const clonedPlay = clonedPlaybook.plays.find(play=> play.id === currentPlay.id);
        if(outcomeToSave.id === undefined){
            outcomeToSave.id = `OUT-${new Date().getTime()}`;
            clonedPlaybook.outcomes.push(outcomeToSave);
        }
        clonedPlay.outcomes.push({stageId: toStage.id, outcomeId: outcomeToSave.id});
        setPlaybook(clonedPlaybook);
    }

    function removeOutcomeFromStage(fromStage, outcomeToRemove){
        const newPlaybook = PlaybookUtils.removeOutcomeFromStage(currentPlaybook, currentPlay, fromStage, outcomeToRemove);
        setPlaybook(newPlaybook);
    }

    //////////////////////////////////////////////
    //// STAGE METHODS
    //////////////////////////////////////////////

    function updateStageTitle(stageToUpdate, newTitle){
        if(stageToUpdate && newTitle){
            let newPlay = {...currentPlaybook};
            newPlay.stages.forEach(stage=>{
                if(stage.id === stageToUpdate.id){
                    stage.label = newTitle;
                    return;
                }
            });
            setPlaybook(newPlay)
        }
    }

    function removeStage(stageToRemove){
        if(stageToRemove){
            let newPlay = {...currentPlaybook};
            newPlay.stages = newPlay.stages.filter(stage=> stage.id !== stageToRemove.id);
            setPlaybook(newPlay)
        }
    }

    function addNewStage(direction, refStage){
        let newPlay = {...currentPlaybook};
        let newIndex = newPlay.stages.length;
        const newStage = {
            "id": `stage${new Date().getTime()}`,
            "label": `Stage ${newIndex + 1}`,
            "outcomes": [],
            "actionRoles": []
        };
        
        if(refStage){
            const currentIndex = newPlay.stages.findIndex(item => item.id === refStage.id);
            if(direction === "right"){
                newIndex = currentIndex;
            }
            else{
                newIndex = currentIndex - 1;
            }
        }
        if(newIndex === newPlay.stages.length){
            newPlay.stages.push(newStage);
        }
        else{
            let newAdded = false;
            const newStages = [];
            for(let i = 0; i < newPlay.stages.length; i++){
                if( i > newIndex && !newAdded){
                    newStages.push(newStage);
                    newAdded = true;
                }
                newStages.push(newPlay.stages[i]);
            }
            if(!newAdded){
                newStages.push(newStage);
                newAdded = true;
            }
            newPlay.stages = newStages;
        }
        setPlaybook(newPlay);
    }

    //////////////////////////////////////////////
    //// TEMPLATE METHODS
    //////////////////////////////////////////////

    function saveTemplate(template){
        const clonedPlaybook = {...currentPlaybook};
        const existingTemplate = clonedPlaybook.templates.find(t => t.id === template.id);
        if(existingTemplate){
            existingTemplate.label = template.label;
            existingTemplate.type = template.type;
            existingTemplate.value = template.value;
        }
        else{
            clonedPlaybook.templates.push(template);
        }

        setPlaybook(clonedPlaybook);
    }

    function removeTemplate(template){
        setPlaybook(PlaybookUtils.removeTemplateFromPlaybook(currentPlaybook, template));
    }

    function addTemplateToAction(template, action){
        const clonedPlaybook = {...currentPlaybook};
        const found = clonedPlaybook.actions.find(a => a.id === action.id);
        if(found){
            found.templateId = template.id;
        }
        setPlaybook(clonedPlaybook);
    }

    //////////////////////////////////////////////
    //// PLAY METHODS
    //////////////////////////////////////////////

    function savePlay(play){
        const clonedPlaybook = {...currentPlaybook};
        let playToSave = clonedPlaybook.plays.find(p=>p.id ===play.id);
        if(playToSave){
            playToSave.label = play.label;
            playToSave.description = play.description;
            playToSave.isDefault = play.isDefault;
            playToSave.sequenceEnabled = play.sequenceEnabled;
            playToSave.roles = play.roles;
        }
        else{
            playToSave = play;
            clonedPlaybook.plays.push(playToSave);
        }
        setPlaybook(clonedPlaybook);
        setCurrentPlayState(playToSave);
    }

    function changeCurrentPlay(play){
        setCurrentPlayState(play);
    }

    function removePlay(play){
        setPlaybook(PlaybookUtils.removePlayFromPlaybook(currentPlaybook, play));
    }

    //////////////////////////////////////////////
    //// ACTION METHODS
    //////////////////////////////////////////////

    function addActionToPlay(actionId, stageId, roleId, indexOfNewAction){
        let clonedPlaybook = {...currentPlaybook};
        let play = clonedPlaybook.plays.find(p=>p.id === currentPlay.id);
        const stage = clonedPlaybook.stages.find( s => s.id === stageId );
        if(play && stage){
            const existingAction = play.actions.find(item=>item.stageId === stage.id && item.actionId===actionId && item.roleId === roleId);
            if(!existingAction){
                
                const index = PlaybookUtils.calculateNewActionIndex(clonedPlaybook, play, stageId, roleId, indexOfNewAction);
                const newAction = {
                    id: `PACTION-${new Date().getTime()}`,
                    stageId: stage.id,
                    roleId: roleId,
                    actionId: actionId,
                    templateId: null,
                    sequenceOrder: index+1
                };
                play.actions.splice(index, 0, newAction);
            }
        }
        setPlaybook(clonedPlaybook);
    }

    function removeActionFromPlay(actionId, stageId, roleId){
        const clone = PlaybookUtils.removeActionRoleFromStageByActionId(currentPlaybook, currentPlay, stageId, actionId, roleId);
        setPlaybook(clone);
    }

    function addNewActionToPlaybook(actionName){
        const clonedPlaybook = {...currentPlaybook};
        const newAction = { "id": `ACT-${(new Date()).getTime()}`, "label": actionName };
        clonedPlaybook.actions.push(newAction);
        setPlaybook(clonedPlaybook);
        return newAction;
    }

    function removeActionFromPlaybook(actionId){
        const clonedPlaybook = {...currentPlaybook};
        const play = clonedPlaybook.plays.find(play=>play.id === currentPlay.id);
        const newActions = clonedPlaybook.actions.filter(action => action.id !== actionId);
        const newPlayActions = play.actions.filter(pa=> pa.actionId !== actionId);
        clonedPlaybook.actions = newActions;
        play.actions = newPlayActions;
        setPlaybook(clonedPlaybook);
    }

    function getActionUsageCount(actionId){
        let count = currentPlay.actions.filter(playAction => playAction.actionId === actionId).length;
        return count;
    }

    function reorderActionInPlay(actionId, stageId, roleId, toIndex){
        const clonedPlaybook = JSON.parse(JSON.stringify(currentPlaybook));
        const play = clonedPlaybook.plays.find(play=>play.id === currentPlay.id);
        
        const fromIndex = play.actions.findIndex(pa => pa.actionId === actionId && pa.roleId === roleId && pa.stageId === stageId);

        const movedAction = play.actions.splice(fromIndex, 1)[0];

        const newIndex = PlaybookUtils.calculateNewActionIndex(clonedPlaybook, play, stageId, roleId, toIndex);

        play.actions.splice(newIndex, 0, movedAction);

        setPlaybook(clonedPlaybook);
    }

    function updateAction(action){
        const clonedPlaybook = JSON.parse(JSON.stringify(currentPlaybook));

        let actionInPlaybook = clonedPlaybook.actions.find(a => a.id === action.id);
        actionInPlaybook.label = action.label;
        actionInPlaybook.description = action.description;
        actionInPlaybook.defaultType = action.defaultType;
        actionInPlaybook.defaultDurationHs = action.defaultDurationHs;
        actionInPlaybook.templateId = action.templateId;

        setPlaybook(clonedPlaybook);
    }

    function updatePlayAction(playAction){
        const clonedPlaybook = {...currentPlaybook};
        let actionInPlay = clonedPlaybook.plays.find(p=>p.id === currentPlay.id)?.actions.find(pa=>pa.id === playAction.id);
        if(actionInPlay){
            actionInPlay.label = playAction.label;
            actionInPlay.description = playAction.description;
            actionInPlay.defaultDurationHs = playAction.defaultDurationHs;
            actionInPlay.defaultType = playAction.defaultType;
            actionInPlay.templateId = playAction.templateId;
        }
        setCurrentPlayState(clonedPlaybook.plays.find( p => p.id === currentPlay.id ));
        setPlaybook(clonedPlaybook);
    }

    //////////////////////////////////////////////
    //// DATAPIPELINE
    //////////////////////////////////////////////

    function setPlaybookDataPipeline(config){
        const clonedPlaybook = {...currentPlaybook};
        clonedPlaybook.dataPipeline = config;
        setPlaybook(clonedPlaybook);
    }

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////
    
    function handleOnAddAction(actionName){
        addNewActionToPlaybook(actionName);
    }

    function handleOnRemoveAction(actionId, stageId, roleId){
        if(stageId && roleId){
            removeActionFromPlay(actionId, stageId, roleId);
        }
        else{
            if(getActionUsageCount(actionId) > 0){
                if(window.confirm("This actions is in use. Do you want to remove the action?")){
                    removeActionFromPlaybook(actionId);
                }
            }
            else{
                removeActionFromPlaybook(actionId);
            }
        }
    }

    function handleOnActionChanged(action, playAction=null){
        if(action){
            updateAction(action);
        }
        if(playAction){
            updatePlayAction(playAction);
        }
    }

    function handleOnTemplateSaved(template){
        setShowNewTemplate(false);
        saveTemplate(template);
    }

    function handleOnTemplateRemoved(template){
        removeTemplate(template);
    }

    function handleOnAddTemplateToAction(template, action){
        addTemplateToAction(template, action);
    }

    function handleAddNewTemplateClicked(){
        setShowNewTemplate(true);
    }

    function handleOnStageTitleChange(stage, newTitle){
        updateStageTitle(stage, newTitle);
    }

    function handleOnRemoveStage(stage){
        removeStage(stage);
    }

    function handleOnAddNewStage(direction, stage){
        addNewStage(direction, stage);
    }

    function handleOnRoleLabelChange(role, newLabel){
        updateRoleLabelTitle(role, newLabel);
    }

    function handleOnRoleRemove(role){
        removeRole(role);
    }

    function handleOnRoleAdd(direction, role){
        addNewRole(direction, role);
    }

    function handleOnOutcomeSave(outcome, stage){
        saveOutcomeToStage(stage, outcome);
    }

    function handleOnRemoveOutcome(outcome, stage){
        removeOutcomeFromStage(stage, outcome);
    }

    function handleOnExistingActionAddedInStage(data){
        const index = currentPlay.actions.filter(pa=>pa.stageId === data.stageId && pa.roleId === data.roleId)?.length;
        addActionToPlay(data.action.id, data.stageId, data.roleId, index);
    }

    function handleOnNewActionAddedInStage(data){
        const newAction = addNewActionToPlaybook(data.label);
        const index = currentPlay.actions.filter(pa=>pa.stageId === data.stageId && pa.roleId === data.roleId)?.length;
        addActionToPlay(newAction.id, data.stageId, data.roleId, index);
    }

    function handleOnShowSequence(sequenceId){
        if(sequenceId === showingSequence?.id){
            setShowingSequence(null);
        }
        else{
            const sequence = PlaybookUtils.getSequenceById(currentPlaybook, sequenceId);
            setShowingSequence(sequence);
        }
    }

    function handleOnEditingSequenceChanged(sequence){
        setShowingSequence(sequence);
        if(sequence != null){
            setIsEditingSequence(true);
        }
    }

    function handleOnEditingSequenceCancelled(){
        setShowingSequence(null);
        setIsEditingSequence(false);
    }

    function handleOnSequenceSaved(sequence){
        saveSequence(sequence);
        setShowingSequence(sequence);
        setIsEditingSequence(false);
    }

    function handleStageActionClicked(stageAction){
        if(isEditingSequence){
            sequenceActionSelected(stageAction);
        }
    }

    function handleOutcomeClicked(outcome){
        if(isEditingSequence){
            sequenceOutcomeSelected(outcome);
        }
    }

    function handleOnDeleteSequence(sequence){
        deleteSequence(sequence);
    }

    function handleOnPlaybookTitleChange(e){
        setPlaybookTitle(e.target.value);
    }

    function handleOnPlaybookTileBlur(e){
        const newPlay = {...currentPlaybook};
        newPlay.name = playbookTitle;
        setPlaybook(newPlay);
        savePlaybook(newPlay);
    }

    function handleOnPlaybookTemplateAdded(templates){
        setPublishTemplatePopupVisible(false);
    }

    function handleOnTemplateSelected(template){
        createNewPlaybook(false, template);
        setPlaybookPopupTemplatesVisible(false);
    }

    function handleOnPlaybookDataSourceChanged(dataPipelineConfig){
        setPlaybookDataPipeline(dataPipelineConfig);
    }

    function handleOnPublishPlaybookClicked(){
        setPlaybookPublishDialogVisible(true);
    }

    function handleOnPlaySaved(play){
        savePlay(play);
    }

    function handleOnSelectedPlayChanged(play){
        changeCurrentPlay(play);
    }

    function handleOnShareButtonClicked(){
        setShowSharePlaybook(true);
    }

    function handleOnDownloadPdfClicked(){
        window.open("http://localhost:3000/assets/demo/data/randomPDF.pdf", '_blank', 'noopener,noreferrer');
    }

    function handleOnPlayRemoved(play){
        removePlay(play);
    }

    //////////////////////////////////////////////
    //// SIDEBAR METHODS
    //////////////////////////////////////////////

    function showSidebar(content){
        if(!content || (configPanelVisible && content === sidebarContent)){
            setSidebarContent("");
            setConfigPanelVisible(false);
        }else{
            setSidebarContent(content);
            setConfigPanelVisible(true);
        }
    }

    function renderSidebarStages(){
        return currentPlaybook.stages.map((stage, index)=>{
                    return <div className={classNames("card")}>
                                <div className="card-header p-mb-0">
                                    <label>{stage.label}</label>
                                    <div>
                                        <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain"></Button>
                                    </div>
                                </div>
                            </div>
                });
    }

    function renderSidebarOutcomes(){
        return currentPlaybook.outcomes.map((outcome, index)=>{
                    return <div className={classNames("card")}>
                                <div className="card-header p-mb-0">
                                    <label>{outcome.label}</label>
                                    <div>
                                        <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain"></Button>
                                    </div>
                                </div>
                            </div>
                });
    }

    function renderSidebarTemplates(){
        const items = currentPlaybook.templates.map((template, index)=>{
                    return <PlaybookTemplateItem key={`template-${template.id}`} playbook={currentPlaybook} template={template} onSave={handleOnTemplateSaved} onRemove={handleOnTemplateRemoved} onAddTemplateToAction={handleOnAddTemplateToAction} />
                });
        items.push(<Button key={`template-addnew`} label='Add new template' icon="pi pi-fw pi-plus" className="p-button-secondary p-button-text" style={{"width": "100%"}} onClick={handleAddNewTemplateClicked} />)
        return items;
    }
    
    function renderSidebarActions(){
        return <Droppable key={"actionsList"} droppableId={"actionsList"} isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {
                                currentPlaybook.actions.map((action, index)=>{
                                    return renderDraggableActionChip(action, "actionsList", index, false);
                                })
                            }
                            <PlaybookAction isNew={true} placeholder="Add new action..." onAddAction={handleOnAddAction} allowEdit={editingPlay}  allowRename={true} showClose={false}/>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>;
    }

    function renderSidebarSequence(){
        return <SequencesMenu play={currentPlaybook} showingSequenceId={showingSequence?.id} allowEdit={editingPlay} onSequenceSaved={handleOnSequenceSaved} onEditingSequenceChanged={handleOnEditingSequenceChanged} onDeleteSequence={handleOnDeleteSequence} onEditingSequenceCancelled={handleOnEditingSequenceCancelled} />;
    }

    function renderSidebarDataPipeline(){
        return <DataPipelineConfig playbook={currentPlaybook} allowEdit={editingPlay} onPlaybookDataSourceChange={handleOnPlaybookDataSourceChanged}/>;
    }

    function renderSideBar(){

        let title = "";
        let content = "";

        switch(sidebarContent){
            case "actions":
                title = "Actions";
                content = renderSidebarActions();
                break;
            case "stages":
                title = "Stages";
                content = renderSidebarStages();
                break;
            case "outcomes":
                title = "Outcomes";
                content = renderSidebarOutcomes();
                break;
            case "templates":
                title = "Templates";
                content = renderSidebarTemplates();
                break;
            case "sequences":
                title = "Plays";
                content = renderSidebarSequence();
                break;
            case "phases":
                title = "Phases";
                content = <span></span>;
                break;
            case "datapipeline":
                title = "Data Pipeline";
                content = renderSidebarDataPipeline();
                break;
            default:
                break;
        }

        return <Sidebar position="right" modal={false} visible={configPanelVisible} appendTo="self" onHide={() => showSidebar()}
                    icons={()=>(
                        <React.Fragment>
                            <div style={{"width": "100%"}}><h5 style={{"margin": "0px"}}>{title}</h5></div>
                        </React.Fragment>
                    )}
                >
                    {content}
                </Sidebar>
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    if(loading){
        return <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>;
    }

    function renderActioInStage(stage, playAction, index){
        const action = PlaybookUtils.getActionById(currentPlaybook, playAction.actionId);
        const containerId = `${playAction.roleId}|${stage.id}`;
        
        return <Draggable key={`${action.id}`} draggableId={`${containerId}|${action.id}`} index={index} isDragDisabled={!editingPlay || isEditingSequence} >
                {(provided) => (
                    <Fragment>
                        <div className="action_dragger" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                            <PlaybookAction allowEdit={editingPlay} onChange={handleOnActionChanged} onNewTemplate={handleOnTemplateSaved} action={action} playAction={playAction} play={currentPlay} playbook={currentPlaybook} showMenu={editingPlay} sequence={showingSequence} key={`action-chip-${containerId}|${action.id}`} onRemoveAction={(action)=>handleOnRemoveAction(action, stage.id, playAction.roleId)} allowRename={false} showCount={false} onClick={handleStageActionClicked} />
                        </div>
                    </Fragment>
                )}
                </Draggable>
    }

    function renderDraggableActionChip(action, container, index, isInStage, stageId, roleId, sequence){
        let count = getActionUsageCount(action.id);
        return <Draggable key={`${action.id}`} draggableId={`${container}|${action.id}`} index={index} isDragDisabled={!editingPlay} >
                {(provided, snapshot) => (
                    <Fragment>
                        <div className="action_dragger" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                            <PlaybookAction allowEdit={editingPlay}  showMenu={editingPlay} sequence={sequence} key={`action-chip-${container}|${action.id}`} onRemoveAction={(action)=>handleOnRemoveAction(action, stageId, roleId)} allowRename={!isInStage} showCount={!isInStage} action={action} actionCount={count} showClose={true} playbook={currentPlaybook} play={currentPlay} onChange={handleOnActionChanged} onNewTemplate={handleOnTemplateSaved} />
                        </div>
                    { 
                        snapshot.isDragging && !isInStage && (
                            <PlaybookAction key={`action-chip-${container}|${action.id}`} action={action} showCount={!isInStage} allowEdit={false} actionCount={count} showClose={true} showMenu={true} playbook={currentPlaybook} play={currentPlay}/>
                        )
                    }
                    </Fragment>
                )}
                </Draggable>
    }

    function renderActionsByRole(){
        const rows = [];
        //EACH ROLE IS A ROW.
        currentPlay.roles.forEach(pr => {
            const role = currentPlaybook.roles.find(r => r.id === pr.roleId);
            if(role){
                const columns = [];
                //CELL: PUSH THE ROLE NAME
                columns.push(
                    <PlaybookRole play={currentPlay} key={role.id} allowEdit={editingPlay} role={role} onLabelChange={handleOnRoleLabelChange} onRemove={handleOnRoleRemove} onAddRole={handleOnRoleAdd} />
                )
                //CELL: PUSH THE ROLE NAME
                currentPlaybook.stages.forEach(stage=>{
                    columns.push(
                        <Droppable key={`${role.id}|${stage.id}`} droppableId={`${role.id}|${stage.id}`}>
                        {(provided, snapshot) => (
                            <td className={classNames("stage", snapshot.isDraggingOver ? "drag-in":"")} key={`cell-${role.id}-${stage.id}`} id={`cell-${role.id}-${stage.id}`} ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                    currentPlay.actions
                                        .filter(playAction=> playAction.roleId === role.id && playAction.stageId === stage.id )
                                        .map((playAction, index)=>{
                                            return renderActioInStage(stage, playAction, index);
                                        })
                                }
                                {
                                    editingPlay && 
                                    <div className="new-action">
                                        <PlaybookNewActionRoleInput stageId={stage.id} roleId={role.id} playbook={currentPlaybook} onNewActionAdded={handleOnNewActionAddedInStage} onExistingActionSelected={handleOnExistingActionAddedInStage} />
                                    </div>
                                }
                                {provided.placeholder}
                            </td>
                        )}
                        </Droppable>
                    )
                });
                rows.push(
                    <tr key={`action-row-${rows.length}`}>
                        {columns}
                    </tr>
                )
            }
        });
        return rows;
    }

    function renderOutcomesCells(){
        const columns = []
        currentPlaybook.stages.forEach(stage=>{
            const outcomes = [];
            currentPlay.outcomes.filter(po=>po.stageId === stage.id).forEach(po=>{
                const outcome = currentPlaybook.outcomes.find(outcome=> outcome.id === po.outcomeId);
                if(outcome){
                    outcomes.push(outcome);
                }
            });
            columns.push(
                <PlayStageOutcomes key={`stage-outcomes-${stage.id}`} outcomes={outcomes} sequence={showingSequence} playbook={currentPlaybook} play={currentPlay} allowEdit={editingPlay} stage={stage} onOutcomeSave={handleOnOutcomeSave} onRemoveOutcome={handleOnRemoveOutcome} onOutcomeClick={handleOutcomeClicked} />
            )
        });
        return columns;
    }

    function renderPublishPlaybookDialog(){
        const allowPublish = PlaybookUtils.isPlaybookValidToPublish(currentPlaybook);
        if(allowPublish){
            return <ConfirmDialog visible={playbookPublishDialogVisible} onHide={() => setPlaybookPublishDialogVisible(false)} message={`Are you sure you want to publish '${currentPlaybook.name}' to Runner?`} header="Publish Playbook To Runner" icon="pi pi-upload" accept={()=>{publishPlaybook()}} reject={() => setPlaybookPublishDialogVisible(false)} />;
        }
        else{
            return <ConfirmDialog rejectLabel="Cancel" acceptLabel="Configure Data pipeline" acceptIcon="pi pi-fw pi-database" visible={playbookPublishDialogVisible} onHide={() => setPlaybookPublishDialogVisible(false)} message={`You can't publish until you complete the Data Pipeline configuration`} header="Publish Playbook To Runner" icon="pi pi-exclamation-triangle" accept={() => {showSidebar('datapipeline')}} reject={() => setPlaybookPublishDialogVisible(false)} />;
        }
    }

    // function renderPublishPlaybookButton(){
    //     const label = isPublishingPlaybook ? "Publishing..." : ( currentPlaybook.published?"Published":"Publish" );
    //     const icon = isPublishingPlaybook ? "" : ( currentPlaybook.published?"pi pi-check":"pi pi-upload" );
    //     return <Button loading={isPublishingPlaybook} className="playbook-title-button" label={label} icon={icon} disabled={currentPlaybook.published} onClick={handleOnPublishPlaybookClicked} style={{"width": "100%"}} />
    // }

    function getMenuOptions(){

        return [
            {
                label: 'Playbook',
                icon: `pi pi-fw pi-play`,
                items:[
                    {
                        label: 'New',
                        icon: 'pi pi-fw pi-plus',
                        items: [
                            {
                                label: 'Blank',
                                icon: 'pi pi-fw pi-file',
                                command: ()=> { createNewPlaybook() }
                            },
                            {
                                label: 'Template',
                                icon: 'pi pi-fw pi-folder-open',
                                command: ()=> { setPlaybookPopupTemplatesVisible(true); }
                            }
                        ]
                    },
                    {
                        label: 'Open',
                        icon: 'pi pi-fw pi-folder-open',
                        items: playbooks.map(p => { return { label: p.name, command: ()=>{ openPlaybook(p.id) } } })
                    },
                    {
                        label: 'Make a copy',
                        icon: 'pi pi-fw pi-copy',
                        command: ()=> { createNewPlaybook(true) }
                    },
                    {separator: true},
                    {
                        label: 'Publish as template',
                        icon: 'pi pi-fw pi-upload',
                        command: ()=>{setPublishTemplatePopupVisible(true)}
                    },
                    {
                        label: 'Share',
                        icon: 'pi pi-fw pi-user-plus'
                    },
                    {
                        label: 'Download',
                        icon: 'pi pi-fw pi-download',
                        items: [
                            {
                                label: 'PDF',
                                icon: 'pi pi-fw pi-file-pdf',
                                command: () => { handleOnDownloadPdfClicked() }
                            },
                            {
                                label: 'PPT',
                                icon: 'pi pi-fw pi-file',
                            },
                            {
                                label: 'Excel',
                                icon: 'pi pi-fw pi-file-excel',
                            },
                        ]
                    },
                    {separator: true},
                    {
                        label: 'Details',
                        icon: 'pi pi-fw pi-info-circle'
                    },
                    {
                        label: 'History',
                        icon: 'pi pi-fw pi-history'
                    },
                    {
                        label: 'Restore',
                        icon: 'pi pi-fw pi-sync',
                        disabled: (!editingPlay),
                        command: ()=>{
                            restorePlaybook();
                        }
                    },
                    {
                        label: 'Protect',
                        icon: 'pi pi-fw pi-shield'
                    },
                    {
                        label: 'Settings',
                        icon: 'pi pi-fw pi-cog'
                    },
                    {separator: true},
                    {
                        label: 'Move',
                        icon: 'pi pi-fw pi-folder'
                    },
                    {
                        label: 'Remove',
                        icon: 'pi pi-fw pi-trash',
                        command: () => { removeCurrentPlaybook() }
                    },
                ]
            },
            {
                label: 'Data Pipeline',
                icon: 'pi pi-fw pi-database',
                className: (sidebarContent === "datapipeline")?"active":"",
                command: () => { showSidebar('datapipeline') }
            },
            {
                label: 'Phases',
                icon: 'pi pi-fw pi-forward',
                className: (sidebarContent === "phases")?"active":"",
                command:()=>{ showSidebar("phases") }
            },
            {
                label: 'Stages',
                icon: 'pi pi-fw pi-step-forward',
                className: (sidebarContent === "stages")?"active":"",
                command:()=>{ showSidebar("stages") }
            },
            {
                label: 'Actions',
                icon: `pi pi-fw pi-step-forward`,
                className: (sidebarContent === "actions")?"active":"",
                command:()=>{ showSidebar("actions") }
            },
            {
                label: 'Outcomes',
                icon: 'pi pi-fw pi-check-circle',
                className: (sidebarContent === "outcomes")?"active":"",
                command:()=>{ showSidebar("outcomes") }
            },
            {
                label: 'Templates',
                icon: 'pi pi-fw pi-file',
                className: (sidebarContent === "templates")?"active":"",
                command:()=>{ showSidebar("templates") }
            },
            {
                label: 'Comments',
                icon: 'pi pi-fw pi-comments'
            },
            {
                
            }
        ];
    }

    return (
        <div className={classNames("grid", "with-sidebar", "playbook-container", {"edit-mode": editingPlay}, {"sequence-mode": showingSequence})}>
            <Dialog visible={playbookPopupTemplatesVisible} style={{ width: '70vw' }} header="Playbook Templates" onHide={() => setPlaybookPopupTemplatesVisible(false)}>
                <PlaybookTemplatesSelector onTemplateSelected={handleOnTemplateSelected} />
            </Dialog>
            <SharePlaybook visible={showSharePlaybook} onClose={()=>{setShowSharePlaybook(false)}}/>
            {renderPublishPlaybookDialog()}
            <PublishTemplateForm visible={publishTemplatePopupVisible} playbook={currentPlaybook} onHide={() => setPublishTemplatePopupVisible(false)} onTemplateAdded={handleOnPlaybookTemplateAdded} />
            <PlaybookTemplateForm visible={showNewTemplate} onSave={handleOnTemplateSaved} onCancel={()=>{setShowNewTemplate(false)}} />
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={classNames("playbook")} style={{"overflowX": "scroll"}}>
                    <div className='p-grid'>
                        <div className='p-col-8'>
                            <InputText disabled={!editingPlay} className="playbook-title-input" value={playbookTitle} onChange={handleOnPlaybookTitleChange} onBlur={handleOnPlaybookTileBlur} />
                        </div>
                        <div className='p-col-2 flex align-items-center justify-content-between'>
                            <PlaysManagementButton allowEdit={editingPlay} onPlaySaved={handleOnPlaySaved} onSelectedPlayChange={handleOnSelectedPlayChanged} style={{"whiteSpace": "nowrap", "width": "100%"}} playbook={currentPlaybook} play={currentPlay} onPlayRemoved={handleOnPlayRemoved} />
                        </div>
                        <div className='p-col-1 flex align-items-center'>
                            <Button onClick={(e)=>{viewModeMenu.current.toggle(e);}} className="p-button-outlined playbook-title-button" label={editingPlay ? "Editing" : "Viewing"} icon={`pi pi-fw pi-${editingPlay?"pencil":"eye"}`} style={{"width": "100%", color: '#1A73E8'}} />
                            <Menu ref={viewModeMenu} model={getViewModeMenuOptions()} popup />
                        </div>
                        <div className='p-col-1 flex align-items-center'>
                            <Button onClick={handleOnShareButtonClicked} className="p-button playbook-title-button" label={"Share"} icon={'pi pi-fw pi-user-plus'} style={{"width": "100%", backgroundColor:'#1A73E8'}} />
                        </div>
                        {/* <div className='p-col-1 flex align-items-center justify-content-between'>
                            {renderPublishPlaybookButton()}
                        </div> */}
                    </div>
                    {
                        editingPlay && 
                        <Menubar model={getMenuOptions()} className="tools-menu" />
                    }
                    <table className="playbook-table">
                        <thead>
                            <tr>
                                <th id="roles-header" className="none-editable" width="10%">
                                    <div className="header">
                                        <label>
                                            <span className="pi pi-user"/> Roles
                                        </label>
                                        { 
                                            editingPlay && 
                                            <div className="menu">
                                                <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain"></Button>
                                            </div>
                                        }
                                    </div>
                                </th>
                                {
                                    currentPlaybook.stages.map(stage=>{
                                        return  <PlaybookStageHeader play={currentPlay} allowEdit={editingPlay} key={stage.id} width={`${90/(currentPlaybook.stages.length)}%`} onAddStage={handleOnAddNewStage} onTitleChange={handleOnStageTitleChange} onRemove={handleOnRemoveStage} stage={stage}/>
                                    })
                                }
                                {
                                    editingPlay && currentPlay.isDefault && 
                                    <th className="add-action" width="1%" onClick={handleOnAddNewStage}>
                                        <span className="pi pi-plus-circle" style={{'fontSize': '2em'}}/>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {renderActionsByRole()}
                            {
                                editingPlay && currentPlay.isDefault && 
                                <tr className="add">
                                        <td className="add-action" onClick={handleOnRoleAdd}>
                                            <span className="pi pi-plus-circle" style={{'fontSize': '2em'}}/>
                                        </td>
                                    
                                    <td colSpan={currentPlaybook.stages.length}></td>
                                </tr>
                            }
                            <tr>
                                <td className="outcome-cell header">
                                    <span className="pi pi-check-circle"/> Outcomes
                                </td>
                                {renderOutcomesCells()}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {renderSideBar()}
            </DragDropContext>
        </div>
    );
}