import React from 'react';

export const EmailField = (props) => {

    const icon = (props.icon)?props.icon:<span className="pi pi-at"></span>;

    return( 
        <span className={props.className}>
            {icon} <a rel="noreferrer" href={`mailto:${props.value}`} target="_blank">{props.value}</a>
        </span>
    );
}