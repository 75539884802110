import React, {useState, useEffect} from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/dropdown';
import { PlaybookService } from '../../../services/PlaybookService';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';

export const PlaybookTemplatesSelector = (props) => {

    const [templates, setTemplates] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [listLayout, setListLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);

    useEffect(()=>{
        if(templates == null){
            setIsLoading(true);
            const playbookService = new PlaybookService();
            playbookService.getPlaybookTemplates().then(templates=>{
                setIsLoading(false);
                setTemplates(templates);
            });
        }
    }, [props]);

    //////////////////////////////////////////////
    //// HANDLERS
    //////////////////////////////////////////////

    function handleOnSortChange(event){
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    function handleOnTemplateSelected(template){
        if(template.playbook && template.playbook.id){
            if(props.onTemplateSelected){
                props.onTemplateSelected(template);
            }
        }
    }

    //////////////////////////////////////////////
    //// RENDERS
    //////////////////////////////////////////////

    function renderDataviewListItem(item){
        return (
            <div className="p-col-12">
                <div className="product-list-item">
                    <div className="product-list-detail mb-2">
                        <h4>{item.name}</h4>
                        <div className="mr-2" style={{"display": "inline-block"}}>
                            <Rating className="mb-2" value={item.rating} readonly cancel={false}></Rating>
                        </div>
                        <div className="mb-2" style={{"display": "inline-block"}}>
                            {
                                item.tags?.map(tag=>{
                                    return <Tag severity="info" className="mr-2" value={tag} />
                                })
                            }
                        </div>
                        <div className="mb-2 " style={{"display": "inline-block"}}>
                            <i className="pi pi-calendar mr-2"></i><span className="mr-2">{item.added_date }</span> <i className="pi pi-user mr-2"></i> <span className="mr-2">{ item.owner }</span>
                        </div>
                        {  item.description && item.description !== '' && 
                            <ScrollPanel className="mb-2" style={{width: '100%', height: '60px'}}>
                                {item.description}
                            </ScrollPanel>
                        }
                        
                    </div>
                    <div className="product-list-action mb-2">
                        <Button icon="pi pi-eye" className="p-button-secondary mr-2" label="Preview"></Button>
                        <Button icon="pi pi-check" label="Select" onClick={()=>{handleOnTemplateSelected(item)}}></Button>
                    </div>
                </div>
            </div>
        );
    }

    function renderDataviewGridItem(item){
        return (
            <div className="p-col-12 p-md-4">
                <div className="product-grid-item card m-2">
                    <div className="product-grid-item-content">
                        <h4>{item.name}</h4>
                        <Rating className="mb-2" value={item.rating} readonly cancel={false}></Rating>
                        <div className="mb-2">
                            {
                                item.tags?.map(tag=>{
                                    return <Tag severity="info" className="mr-2" value={tag} />
                                })
                            }
                        </div>
                        <div className="mb-2 ">
                            <i className="pi pi-calendar mr-2"></i> { item.added_date } <br />
                            <i className="pi pi-user mr-2"></i> { item.owner }
                        </div>
                        <ScrollPanel className="mb-2" style={{width: '100%', height: '100px'}}>
                            {item.description}
                        </ScrollPanel>
                    </div>
                    <div className="product-grid-item-bottom">
                        <Button icon="pi pi-eye" className="p-button-secondary mr-2" label="Preview"></Button>
                        <Button icon="pi pi-check" label="Select" onClick={()=>{handleOnTemplateSelected(item)}}></Button>
                    </div>
                </div>
            </div>
        );
    }

    function renderItem(item, layout){
        if (!item) {
            return;
        }
        if (layout === 'list') {
            return renderDataviewListItem(item);
        }
        else if (layout === 'grid') {
            return renderDataviewGridItem(item);
        }
    };

    function renderHeader() {

        const sortOptions = [
            { label: 'Price High to Low', value: '!price' },
            { label: 'Price Low to High', value: 'price' }
        ];

        return <div className="p-grid p-nogutter">
                    <div className="p-col-8" style={{ textAlign: 'left' }}>
                        <div className="p-input-icon-right" style={{ width: '100%' }}>
                            <InputText type="text" placeholder="Search" style={{ width: '100%' }} />
                            <i className="pi pi-search" />
                        </div>
                    </div>
                    <div className="p-col-2" style={{ textAlign: 'right' }}>
                        <Dropdown value={sortKey} options={sortOptions} optionLabel="label" placeholder="Sort By ... " onChange={handleOnSortChange} />
                    </div>
                    <div className="p-col-2" style={{ textAlign: 'right' }}>
                        <DataViewLayoutOptions layout={listLayout} onChange={(e) => setListLayout(e.value)} />
                    </div>
                </div>;
    };

    if(isLoading){
        return <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>;
    }

    return <DataView value={templates} layout={listLayout} paginator rows={9} sortOrder={sortOrder} sortField={sortField} itemTemplate={renderItem} header={renderHeader()}></DataView>;
}