import axios from 'axios';
const PLAYBOOK_KEY = "playbook";
const LAST_PLAYBOOK_KEY = "last_playbook";
const PLAYBOOK_TEMPLATES_KEY = "playbook_templates";
export class PlaybookService {

    getLastPlaybookId(){
        return localStorage.getItem(LAST_PLAYBOOK_KEY);
    }

    saveLastPlaybookId(id){
        localStorage.setItem(LAST_PLAYBOOK_KEY, id);
    }

    getPlaybooks() {
        if(localStorage.getItem(PLAYBOOK_KEY) == null){
            return axios.get('assets/demo/data/playbook.v2.json').then(res => {
                this.savePlaybook(res.data);
                return res.data;
            });
        }
        else{
            return new Promise((resolution)=>{
                resolution( JSON.parse(localStorage.getItem(PLAYBOOK_KEY)) );
            })
        }
    }

    getPlaybookTemplates(){
        if(localStorage.getItem(PLAYBOOK_TEMPLATES_KEY) == null){
            return axios.get('assets/demo/data/playbook-templates.json').then(res => {
                this.savePlaybookTemplates(res.data);
                return res.data;
            });
        }
        else{
            return new Promise((resolution)=>{
                resolution( JSON.parse(localStorage.getItem(PLAYBOOK_TEMPLATES_KEY)) );
            })
        }
    }

    savePlaybook(playbook) {
        localStorage.setItem(PLAYBOOK_KEY, JSON.stringify(playbook));
    }

    savePlaybookTemplates(templates) {
        localStorage.setItem(PLAYBOOK_TEMPLATES_KEY, JSON.stringify(templates));
    }

    addPlaybookTemplate(template) {
        return new Promise((resolution)=>{
            this.getPlaybookTemplates().then(templates => {
                templates.push(template);
                this.savePlaybookTemplates(templates);
                resolution(templates);
            });
        });
    }

    static deleteData(){
        localStorage.removeItem(PLAYBOOK_KEY);
        localStorage.removeItem(LAST_PLAYBOOK_KEY);
    }

}