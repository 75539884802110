import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { OpportunityCard } from './OpportunityCard';
import { MotionHeader } from './MotionHeader';
import classNames from 'classnames';
import { Button } from "primereact/button";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
  
/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const values = [
    {
        conversion: [
            { value: 65, ytd: "300/500", className: "" }, 
            { value: 24, ytd: "72/300", className: "pink-color" }, 
            { value: 80, ytd: "58/72", className: "" }, 
            { value: 65, ytd: "38/58", className: "" }, 
            { value: 53, ytd: "20/38", className: "" }, 
            { value: 19, ytd: "04/20", className: "pink-color" }
        ],
        duration: [
            { value: 3, min: 1, max: 20, className: "" }, 
            { value: 29, min: 5, max: 20, className: "" }, 
            { value: 7, min: 5, max: 20, className: "" }, 
            { value: 9, min: 5, max: 20, className: "" }, 
            { value: 15, min: 5, max: 20, className: "" }, 
            { value: 33, min: 5, max: 20, className: "" }
        ],
        hours: [ 
            { value: 25, min: 5, max: 20, className: "" }, 
            { value: 2, min: 1, max: 20, className: "" }, 
            { value: 3, min: 5, max: 20, className: "" }, 
            { value: 5, min: 5, max: 20, className: "" }, 
            { value: 61, min: 5, max: 20, className: "" }, 
            { value: 3, min: 5, max: 20, className: "" } 
        ]
    }
]

export const MotionBoard = (props) => {
    const [kanban, setKanban] = useState([]);
    const [dragEnabled, setDragEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    let currentBoard = useRef(props.currentBoard);
    let dataSourceList = useRef(props.dataSourceList);

    // const chart1 = useRef(null);

    const buildKanbanMatrix= () => {
        let kanbanColumns = [];
        if(currentBoard.current != null && dataSourceList?.current != null){
            if(currentBoard.current.showOrphanOpps){
                const orphanOpps = getOrphanItems();
                if(orphanOpps.length > 0){
                    kanbanColumns.push(
                        {
                            config: {
                                label: "Unassigned",
                                totalizerKey: "count",
                                criteriaValue: ""
                            },
                            data: orphanOpps
                        }
                    );
                }
            }
            currentBoard.current.columns.forEach((column)=>{
                kanbanColumns.push({
                    config: column,
                    data: getItemsForColumn(column)
                });
            });
            setKanban(kanbanColumns);
            setLoading(false);
        }
    }

    useEffect(()=>{
        if (dataSourceList !== props.dataSourceList) {
            dataSourceList.current = props.dataSourceList;
        }
        if(currentBoard !== props.currentBoard){
            currentBoard.current = props.currentBoard;
        }
        setDragEnabled(currentBoard.current.editable);
        buildKanbanMatrix();
    }, [props.currentBoard, props.dataSourceList]);

    function getOrphanItems(){
        return dataSourceList.current.data.filter((item)=> {
            let isItemOrphan = true;
            currentBoard.current.columns.forEach(column => {
                if(item[currentBoard.current.criteriaField] === column.criteriaValue){
                    isItemOrphan = false;
                    return;
                }
            })
            return isItemOrphan;
        })
    }

    function getItemsForColumn(column){
        return dataSourceList.current.data
                .filter((item)=> item[currentBoard.current.criteriaField] === column.criteriaValue)
                .sort(sortItems);
    }

    function sortItems(item1, item2){
        if(item1.PRESCALE__C_kanban_position[currentBoard.current.id] > item2.PRESCALE__C_kanban_position[currentBoard.current.id]){
            return 1;
        }
        else if(item1.PRESCALE__C_kanban_position[currentBoard.current.id] < item2.PRESCALE__C_kanban_position[currentBoard.current.id]){
            return -1;
        }
        else{
            if(item1[currentBoard.current.defaultSortBy] > item2[currentBoard.current.defaultSortBy]){
                return currentBoard.current.defaultSortDesc ? -1 : 1;
            }
            else if(item1[currentBoard.current.defaultSortBy] < item2[currentBoard.current.defaultSortBy]){
                return currentBoard.current.defaultSortDesc ? 1 : -1;
            }
            else{
                return 0;
            }
        }
    }

    function updateKanbanDataList(newKanban){
        if(props.onKanbanDataListUpdated){
            props.onKanbanDataListUpdated(newKanban, currentBoard.current);
        }
        setKanban(newKanban);
    }

    function updateDataItemAttribute(dataItem, field, oldValue, newValue){
        if(props.onDataItemUpdated){
            props.onDataItemUpdated(dataItem, [{field: field, from: oldValue, to: newValue}]);
        }
    }

    function handleOnCallAnAudible(dataItem, play){
        const assignedPlays = {...dataItem.PRESCALE__C_assigned_play};
        assignedPlays[props.playbook.id] = play.id;
        updateDataItemAttribute(dataItem, "PRESCALE__C_assigned_play", dataItem.PRESCALE__C_assigned_play, assignedPlays);
    }
    
    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sourceColumnIndex = +source.droppableId;
        const destinationColumnIndex = +destination.droppableId;
        if (sourceColumnIndex === destinationColumnIndex) {
            // SAME COLUMN
            const column = kanban[sourceColumnIndex];
            const items = reorder(column.data, source.index, destination.index);
            const newKanban = [...kanban];
            newKanban[sourceColumnIndex].data = items;
            updateKanbanDataList(newKanban);
        } else {
            // MOVE COLUMN
            const newKanban = [...kanban];
            const result = move(newKanban[sourceColumnIndex].data, newKanban[destinationColumnIndex].data, source, destination);
            newKanban[sourceColumnIndex].data = result[sourceColumnIndex];
            newKanban[destinationColumnIndex].data = result[destinationColumnIndex];

            const movedItem = newKanban[destinationColumnIndex].data.find(item=>item[currentBoard.current.criteriaField] !== newKanban[destinationColumnIndex].config.criteriaValue);
            updateDataItemAttribute(movedItem, currentBoard.current.criteriaField, movedItem[currentBoard.current.criteriaField], newKanban[destinationColumnIndex].config.criteriaValue);
            updateKanbanDataList(newKanban);
        }
    }

    function renderColumn(column, index){
        if(!props.metricsOnly){
            return <>
                {column.data.map((item, oppNumber) => (
                    <Draggable isDragDisabled={!dragEnabled} key={item.id} draggableId={item.id} index={oppNumber} >
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <OpportunityCard onCallAnAudible={handleOnCallAnAudible} showPlayTag={props.playbook.plays.length > 1} playbook={props.playbook} fields={currentBoard.current.cardFields} className={snapshot.isDragging?"dragging":""} opportunity={item} />
                            </div>
                        )}
                    </Draggable>
                ))} </>
        }
        else{
            const currentMetrics = values[0];
            const conversion = currentMetrics.conversion[index];
            const duration = currentMetrics.duration[index];
            const hours = currentMetrics.hours[index];
            return <>
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">
                        <i className="pi pi-check-square"></i>
                        <h6 className={classNames('p-m-0', 'p-pl-2')} >Conversion</h6>
                        <div className={classNames('p-ml-auto')}>
                            <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className={classNames("p-col-6 fs-xlarge", conversion.className)}>{conversion.value}%</div>
                        <div className="p-col-6 fs-large">{conversion.ytd} YTD</div>
                    </div>
                </div>
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">
                        <i className="pi pi-calendar"></i>
                        <h6 className={classNames('p-m-0', 'p-pl-2')} >Avg Duration (days)</h6>
                        <div className={classNames('p-ml-auto')}>
                            <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className={classNames("p-col-6 fs-xlarge", duration.className)}>{duration.value}</div>
                        {/* <div className="p-col-6 fs-normal">Min: {duration.min}<br/>Max: {duration.max}</div> */}
                    </div>
                </div>
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">
                        <i className="pi pi-clock"></i>
                        <h6 className={classNames('p-m-0', 'p-pl-2')} >Avg Hours</h6>
                        <div className={classNames('p-ml-auto')}>
                            <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain"></Button>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className={classNames("p-col-6 fs-xlarge", hours.className)}>{hours.value}</div>
                        {/* <div className="p-col-6 fs-normal">Min: {hours.min}<br/>Max: {hours.max}</div> */}
                    </div>
                </div>
            </>
        }
        
    }
    
    if(loading){
        return <div style={{"width": "100%", "textAlign": "center", "marginTop": "40%"}} ><i className="pi pi-spin pi-spinner" style={{'fontSize': '4em'}}></i> <h2>Loading Board...</h2></div>;
    }

    return (
        <div className="p-grid p-align-stretch vertical-container p-mt-3 pop-motion">
            <DragDropContext onDragEnd={onDragEnd}>
            {kanban.map((column, index) => (
                <Droppable key={column.config.label} droppableId={`${index}`}>
                    {(provided, snapshot) => (
                        <div className={classNames("p-col pop-motion-item", snapshot.isDraggingOver ? "drag-in":"")} ref={provided.innerRef} {...provided.droppableProps}>
                            <MotionHeader config={column.config} data={column.data} />
                            {renderColumn(column, index)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
            </DragDropContext>
        </div>
    );
}